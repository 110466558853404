<div class="container-fluid faq-content mb-5">
  <div class="col-md-8 offset-md-2" id="loginAsOrgTYpe">
    <!-- <img src="./assets/images/logo.png" style="width: 34%;" alt="Logo" /> -->
    <h1 class="application-name">
      {{ 'FAQ.descriptionHeader' | transloco }}
    </h1>
    <h2 class="application-name">
      {{ 'FAQ.howToGuide' | transloco }}
    </h2>
    <div>
      <p>
        {{'FAQ.howToGuideDesc' | transloco}}
      </p>
      <div class="d-flex">
        <div class="mb2">
          <!--<p>
    <span class="faq-link">
      <a target="_blank" href="{{healthDoc}}">{{'FAQ.healthSector'  | transloco}}</a>
    </span>
  </p>
  <p style="margin-bottom: 1.5rem !important;">
    <span class="faq-link">
      <a target="_blank" href="{{healthSupplyDoc}}">{{'FAQ.healthSupplyEntity' | transloco}}</a>
    </span>
  </p>-->
          <p>
            <span class="faq-link">
              <a target="_blank" href="{{nonHealthEnDoc}}">
                {{'FAQ.nonHealthSector' | transloco}}
                ({{'FAQ.english' | transloco}})
              </a>
            </span>
          </p>
          <p>
            <span class="faq-link">
              <a target="_blank"
                 href="{{nonHealthFrDoc}}">
                {{'FAQ.nonHealthSector' | transloco}}
                ({{'FAQ.french' | transloco}})
              </a>
            </span>
          </p>
          <p>
            <span class="faq-link">
              <a target="_blank" href="{{nonHealthSupplyEnDoc}}">
                {{'FAQ.nonHealthSupplyEntity' | transloco}}
                ({{'FAQ.english' | transloco}})
              </a>
            </span>
          </p>
          <p>
            <span class="faq-link">
              <a target="_blank" href="{{nonHealthRegDocEn}}">
                {{'FAQ.healthRegisterGuide' | transloco}} ({{'FAQ.english' | transloco}})
              </a>
            </span>
          </p>
          <p style="margin-bottom: 1.5rem !important;">
            <span class="faq-link">
              <a target="_blank" href="{{nonHealthRegDocFr}}">
                {{'FAQ.healthRegisterGuide' | transloco}} ({{'FAQ.french' | transloco}})
              </a>
            </span>
          </p>
        </div>
      </div>
      <!-- 2020/12/17: Business has requested to remove the Training Video section: 
      <div class="application-name pt20">
        {{ 'FAQ.TrainingVideo' | transloco }}
      </div>
      <p>{{'FAQ.TrainingMessage1' | transloco}} </p>
      <p>{{'FAQ.newUser' | transloco}} </p>
      <div class="VideoIframe">
        <video width="400" height="240" controls><source src="https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Recording/NonHealthNewUser.mp4" type="video/mp4">Your browser does not support the video tag.</video>
      </div>
      <p>{{'FAQ.existingUser' | transloco}} </p>
      <div class="VideoIframe">        
        <video width="400" height="240" controls><source src="https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Recording/NonHealthExistingUser.mp4" type="video/mp4">Your browser does not support the video tag.</video>        
      </div>
      <p>{{'FAQ.TrainingMessage2' | transloco}} </p>
      -->
      <div class="application-name pt20">
        {{ 'FAQ.contactUs' | transloco }}
      </div>
      <p>{{'FAQ.contactUsDesc' | transloco}} </p>

      <p style="margin-bottom:0 !important">
        <strong>{{'FAQ.contactPhone' | transloco}}</strong>{{'FAQ.contactNumber' | transloco}}
      </p>
      <p>
        <strong>{{'FAQ.contactEmail' | transloco}} </strong>{{'FAQ.emailDetails' | transloco}}
      </p>

      <p>{{'FAQ.emailDescription' | transloco}}</p>

      <div class="application-name">
        {{ 'FAQ.trainingSession' | transloco }}
      </div>
      <div class="d-flex">
        <div>
          <p>
            {{'FAQ.trainingSessionDesc' | transloco}}
          </p>
        </div>
      </div>
      <div style="margin-bottom:30px; font-size: 20px; font-weight: 500; line-height: 1.58;text-align: left;">
        <div class="org-grid trainingSession">
          <div class="row">
            <!-- <div class="col-6 p-0">
              <p-table #trainingTable [columns]="cols" [value]="trainingSessionValues" [scrollable]="true"
                       scrollHeight="calc(100vh - 250px)" styleClass="faqGrid">
                <ng-template pTemplate="caption">
                  {{'FAQ.healthSector' | transloco}}
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>

                    <th *ngFor="let col of columns" style="height:30px">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>

                    <td *ngFor="let col of columns;let i=index;">
                      <div *ngIf="i!==2">
                        {{rowData[col.field]}}
                      </div>
                      <div *ngIf="i==2">
                        <a [href]="rowData[col.field]" target="_blank"> {{'FAQ.ClickhereJoin' | transloco}}</a>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div> -->
            <div class="col-12">
              <p-table styleClass="faqGridNH" #trainingTable [columns]="cols" [value]="trainingNHSessionValuesOld"
                       [scrollable]="true" scrollHeight="calc((100vh - 260px) / 2)">
                <!--<ng-template pTemplate="caption">
                  {{'FAQ.NonHealthSector' | transloco}}
                </ng-template>-->
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns" style="height:30px">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-ri="rowIndex" let-columns="columns">
                  <!-- Text for 'Training for Previous/New Respondents of the Survey' is no longer required per Business rerquest 2020/12/29 -->
                  <!-- <tr>
                    <td colspan="3" *ngIf="ri ==0">
                      {{'FAQ.TrainingMsg' | transloco}}
                    </td>
                  </tr> -->
                  <tr>
                    <td *ngFor="let col of columns;let i=index;">
                      <div *ngIf="i!==2">
                        {{rowData[col.field]}}
                      </div>
                      <div *ngIf="i==2">
                        <a [href]="rowData[col.field]" target="_blank"> {{'FAQ.ClickhereJoin' | transloco}}</a>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <p-table #trainingTable [columns]="cols" [value]="trainingNHSessionValuesNew" [scrollable]="true"
                       scrollHeight="calc((100vh - 260px) / 2)">
                <ng-template pTemplate="body" let-ri="rowIndex" let-rowData let-columns="columns">
                  <!-- Text for 'Training for Previous/New Respondents of the Survey' is no longer required per Business rerquest 2020/12/29 -->
                  <!-- <tr>
                    <td colspan="3" *ngIf="ri ==0">
                      {{'FAQ.TrainingNewResp' | transloco}}
                    </td>
                  </tr> -->
                  <tr>
                    <td *ngFor="let col of columns;let i=index;">
                      <div *ngIf="i!==2">
                        {{rowData[col.field]}}
                      </div>
                      <div *ngIf="i==2">
                        <a [href]="rowData[col.field]" target="_blank"> {{'FAQ.ClickhereJoin' | transloco}}</a>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    <!--  <div class="d-flex">
        <p>
          <strong>{{'FAQ.checkBackTRaining' | transloco}}</strong>
        </p>
      </div>-->
    </div>
  </div>
</div>
