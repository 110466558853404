import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebAppService } from 'src/app/web-app.service';
import { Router } from '@angular/router';
import { MessageComponent } from '../message/message.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslocoService } from '@ngneat/transloco';
import { SharedService } from '../sharedservice/sharedservice';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: [ './forgot-password.component.css' ]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    Email: string;
    emptyEmailError: boolean;
    validEmailError: boolean;
    private ngSubject: Subject<any> = new Subject<any>();
  pathCheck: boolean = false;
  inValidEmailId: boolean;
  

    constructor(
        private webappService: WebAppService,
        private router: Router,
        private dialogService: DialogService,
        private translocoService: TranslocoService,
        public service: SharedService
    ) {
        if (this.router.url === '/set-new-password') {
            this.pathCheck = true;
        }
        this.webappService.getDownTime().pipe(takeUntil(this.ngSubject)).subscribe((status) => {
            if (!status) {
                this.router.navigate([ 'Login' ]);
            }
        });
    }

    ngOnInit(): void {
        this.Email = '';
        this.emptyEmailError = false;
      this.validEmailError = false;
      this.inValidEmailId = false;
    }
   
    /**
     * Passing the data to api to reset the password
     *
     * @param {*} Email
     * @memberof ForgotPasswordComponent
     */
    async ResetPasswordNew(Email) {
        await this.webappService.ForgetPassword(Email).then(async (resp) => {
            if (resp.includes('Provided Email ID is not available in the system')) {
                this.validEmailError = true;
            } else if (resp.includes('Your Access request is On-Hold')) {
                const popup = this.dialogService.open(MessageComponent, {
                    showHeader: false,
                    closable: false,
                    width: '30%',
                    data: {
                        header: this.translocoService.translate('HOME.information'),
                        message: this.translocoService.translate('HOME.forgotpwdError')
                    }
                });
                popup.onClose.subscribe((data) => {
                    this.router.navigate([ 'Login' ]);
                    //this.router.navigate([ 'entity-selected' ]);
                });
            } else if (resp.includes('We cannot process your request')) {
                const popup = this.dialogService.open(MessageComponent, {
                    showHeader: false,
                    closable: false,
                    width: '30%',
                    data: {
                        header: this.translocoService.translate('HOME.error'),
                      message: this.translocoService.translate('HOME.errorOccured')
                    }
                });
                popup.onClose.subscribe((data) => {
                    this.router.navigate([ 'Login' ]);
                    // this.router.navigate([ 'entity-selected' ]);
                });
            }
            
            else {
                if (this.pathCheck) {
                    this.service.saveData(Email, 3);
                    this.router.navigate([ 'reset-success' ]);
                } else {
                    this.service.saveData(Email, 1);
                    this.router.navigate([ 'reset-success' ]);
                }
            }
        });
    }
    /**
     * On click of cancel navigate to login
     *
     * @memberof ForgotPasswordComponent
     */
    BacktoLogin() {
        this.router.navigate([ 'Login' ]);
    }
    /**
     * Validating the email id
     *
     * @memberof ForgotPasswordComponent
     */
    
  ResetPassowrd() {
    this.emptyEmailError = false;
    this.validEmailError = false;
    this.inValidEmailId = false;
   
    if (this.Email === '' || this.Email === undefined || this.Email === null) {
      this.emptyEmailError = true;
    }
    else {
      if (this.Email.trim() === '') {
        this.emptyEmailError = true;
      }
      else {
        this.emptyEmailError = false;
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.Email)) {
          this.inValidEmailId = false;
          this.ResetPasswordNew(this.Email);
        }
        else {
          this.inValidEmailId = true;
        }

      }

    } 

  }
 
    ngOnDestroy() {
        this.ngSubject.next();
        this.ngSubject.complete();
    }

}
