import { Component, Injectable, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
export enum successDirective {
    newUserResetPassword = 1,
    inventorySubmitSuccess = 2,
    forgotPasswordReset = 3,
    healthEntitySuccess = 4,
    nonHealthEntitySuccess = 5,
    newEntitySuccess = 6,
    firstUserEntitySuccess = 7
}

export interface MyData {
    msgDetails: string;
    componentType: number;
}

@Injectable()
export class SharedService {
    orgDetails = [];
    idleTimeout: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    currentUser;
    isInventoryEdited: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    currentLanguage = false;
    currentPath: BehaviorSubject<string> = new BehaviorSubject<any>('');
    isDownTime: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    isAutoSave: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    sharingData: MyData = {
        msgDetails: '',
        componentType: 0
    };
    updateAutoSave(val: number) {
        this.isAutoSave.next(val);
    }
    updateIsDown(isDownTime) {
        this.isDownTime.next(isDownTime);
    }
    updateInventoryEdited(value: boolean) {
        this.isInventoryEdited.next(value);
    }
    updateIdleTimeout() {
        this.idleTimeout.next(true);
    }
    updateLoader(loader: boolean) {
        this.isLoader.next(loader);
    }
    getOrganisationDetails() {
        return this.orgDetails;
    }
    setOrganisationDetails(details) {
        this.orgDetails = details;
    }
    constructor() {
        // this.sharingData;
    }
    updatePath(path) {
        this.currentPath.next(path);
    }
    saveData(mDetails, compType) {
       // this.sharingData.msgDetails = mDetails;
      //  this.sharingData.componentType = compType;
        localStorage.setItem("msgDetails",mDetails);       
        localStorage.setItem("componentType",compType);
    }
    getData(storageTypeName:string) {
        return localStorage.getItem(storageTypeName);
      //  return this.sharingData;
    }
}
