import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.css']
})
export class SurveyFormComponent implements OnInit {
  oranizationTypes: any;
  LHINOptions: any;
  FirstNameErr: boolean;
  FirstName: any;
  LastName: any;
  LastNameErr: boolean;
  RespondentTitle: any;
  RespondentTitleErr: boolean;
  EmailId: any;
  EmailErr: boolean;
  OrgName: any;
  OrgNameErr: boolean;
  validationSucces: boolean;
  OrganizationAddress: any;
  OrganizationAddressErr: boolean;
  OrganizationCity: any;
  OrganizationCityErr: boolean;
  OrganizationPostal: any;
  OrganizationPostalErr: boolean;
  selectedLHIN: any;
  selectedLHINErr: boolean;
  selectedorganizationType: any;
  selectedorganizationTypeErr: boolean;
  blocked: boolean;
  healthTabsOpen: boolean;
  NonHealthTabsOpen: boolean;
  constructor() { }

  ngOnInit(): void {
    this.oranizationTypes = [{ name: "PWC", value: 1 }, { name: "Infosys", value: 2 }, { name: "Test", value: 3 }]
    this.LHINOptions = [{ label: "Central", value: 1 }, { label: "Central East", value: 2 }, { label: "Central West", value: 3 },
    { label: "Champlain", value: 4 }, { label: "Erie st. Clair", value: 5 }, { label: "Hamilton Niagara Haldimand Brant", value: 6 },
    { label: "Mississauga Halton", value: 7 }, { label: "North Simkoe", value: 8 }, { label: "North East", value: 9 },
    { label: "North West", value: 10 }, { label: "Toronto Central", value: 11 }, { label: "Not Applicable", value: 12 }]
  }
  saveUser() {
    this.validateUserDetails();
  }
  clearErr() {
    this.validationSucces = true;

    this.FirstNameErr = false;
    this.LastNameErr = false;
    this.RespondentTitleErr = false;
    this.EmailErr = false;
    this.OrgNameErr = false;
    this.OrganizationAddressErr = false;
    this.OrganizationCityErr = false;
    this.OrganizationPostalErr = false;
    this.selectedorganizationTypeErr = false;
  }

  openHealthTab() {
    this.healthTabsOpen = true;
    this.NonHealthTabsOpen = false;
  }
  openNonHealthTab() {
    this.NonHealthTabsOpen = true;
    this.healthTabsOpen = false;
  }
  validateUserDetails() {
    this.clearErr();
    let mandateErr = false;
    if (this.FirstName === undefined || this.FirstName === '') {
      this.FirstNameErr = true;
    }
    if (this.LastName === undefined || this.LastName === '') {
      this.LastNameErr = true;
    }
    if (this.RespondentTitle === undefined || this.RespondentTitle === '') {
      this.RespondentTitleErr = true;
    }
    if (this.EmailId === undefined || this.EmailId === '') {
      this.EmailErr = true;
    }
    if (this.OrgName === undefined || this.OrgName === '') {
      this.OrgNameErr = true;
    }
    if (this.OrganizationAddress === undefined || this.OrganizationAddress === '') {
      this.OrganizationAddressErr = true;
    }

    if (this.OrganizationCity === undefined || this.OrganizationCity === '') {
      this.OrganizationCityErr = true;
    }

    if (this.OrganizationPostal === undefined || this.OrganizationPostal === '') {
      this.OrganizationPostalErr = true;
    }

    if (this.selectedLHIN === undefined || this.selectedLHIN === '') {
      this.selectedLHINErr = true;
    }

    if (this.OrganizationPostal === undefined || this.OrganizationPostal === '') {
      this.OrganizationPostalErr = true;
    }

    if (this.selectedorganizationType === undefined || this.selectedorganizationType === '') {
      this.selectedorganizationTypeErr = true;
    }
  }
}


