<div class="login-wrapper">
    <div class="login-wrapper-in">
      <div class="forgot-password" id="reset-surveypassword">
        <div class="login-logo">
          <img src="./assets/images/logo.png" alt="Logo" />
        </div>
        <div class="application-name">
          <div>{{ 'EntityScreen.covid19Header1' | transloco }}</div>
        </div>
        <div class="process-name a-mb-20"><b>{{ 'HOME.resetPassMsg' | transloco }}</b></div>
        <!--<div class="application-text">
      <div class="application-notes">{{ 'HOME.passRecoveryDesc' | transloco }}</div>
  </div>-->
      <div class="application-notes a-mb-30 text-center">
        {{ 'HOME.storeResetPwd' | transloco }}
      </div>

        <div class="user-fields">
          <div class="a-d-flex a-flex-column a-textarea-box">
            <div class="form-group">
              <div class="a-form-label"
                   [ngClass]="{'error-label': passwordMismatchError || changepassworderror || newpassworderror || confirmpaswrderror || passwordLengthError}">
                {{ 'HOME.NewPassword' | transloco }}
              </div>
              <div class="ImageClass">
                <i class="fa fa-lock iconClass"></i>
                <input class="a-text-input" name="password" [(ngModel)]="newPassword" type="password"
                       (keyup.enter)="ChangePassword()" autofocus [ngClass]="{'error-box': passwordMismatchError || changepassworderror || newpassworderror || confirmpaswrderror || passwordLengthError}" />
              </div>
            </div>
            <div class="form-group">
              <div class="a-form-label"
                   [ngClass]="{'error-label': passwordMismatchError || changepassworderror || newpassworderror || confirmpaswrderror || passwordLengthError}">
                {{ 'HOME.ConfirmPswd' | transloco }}
              </div>
              <div class="ImageClass">
                <i class="fa fa-lock iconClass"></i>
                <input class="a-text-input" name="password" [(ngModel)]="confirmPassword"
                       (keyup.enter)="ChangePassword()" type="password"
                       [ngClass]="{'error-box': passwordMismatchError || changepassworderror || newpassworderror || confirmpaswrderror || passwordLengthError}" />
              </div>
            </div>
            <div class="error-message" *ngIf="passwordMismatchError" id="error-guid-password">
              {{ 'HOME.PasswordMismatch' | transloco }}
            </div>
            <div class="error-message" *ngIf="newpassworderror && !changepassworderror" id="error-guid-password">
              {{ 'HOME.NewPaswrdError' | transloco }}
            </div>
            <div class="error-message" *ngIf="changepassworderror" id="error-guid-password">
              {{ 'HOME.EmptyPswrd' | transloco }}
            </div>
            <div class="error-message" *ngIf="confirmpaswrderror && !changepassworderror"
                 id="error-guid-password">
              {{ 'HOME.ConfirmPaswrdError' | transloco }}
            </div>
            <div class="error-message" *ngIf="passwordLengthError && !newpassworderror && !confirmpaswrderror">
              {{ 'HOME.ValidPswd' | transloco }}
            </div>
            <div class="a-textarea-footer">
              <button class="a-btn a-btn-primary a-btn-xl login-btn moh-btn" (click)="ChangePassword()" tabindex="0">
                {{ 'HOME.ChangePassword' | transloco }}
              </button>
            </div>
          </div>
        </div>
      </div>
        <!-- <app-login-footer></app-login-footer> -->
    </div>
</div>
