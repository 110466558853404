import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
@Injectable({
    providedIn: 'root'
})
export class ApplicationInsightService {
    appInsights: ApplicationInsights;
    constructor() {}
    applyInSights(data) {
        this.appInsights = data;
    }
    log(message) {
        this.appInsights.trackTrace({ message: message });
    }
    trackEvent(eventMessage) {
        this.appInsights.trackEvent(eventMessage);
    }
    trackPage(url) {
        this.appInsights.trackPageView({ name: url });
    }
    trackException(exception) {
        if (this.appInsights) {
            this.appInsights.trackException({ exception: exception });
        }
    }
}
