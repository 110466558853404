import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AutoComplete } from 'primeng/autocomplete';
import { Dropdown } from 'primeng/dropdown/dropdown';
import { Subject, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { WebAppService } from 'src/app/web-app.service';
import { EncrDecrService } from '../sharedservice/enc-dec.service';
import { SharedService, successDirective } from '../sharedservice/sharedservice';
import { UtilityService } from 'src/app/service/utility-service.service';

interface IOrganization {
    label: string;
    value: number;
}

@Component({
    selector: 'app-create-entity',
    templateUrl: './create-entity.component.html',
    styleUrls: [ './create-entity.component.css' ]
})
export class CreateEntityComponent implements OnInit {
    selectedEntityExists: IOrganization;
    selectedEntityLabel: string;
    selectedEntityExistsError: boolean;
    selectedEntityDoesnotExists = false;
    entityType: any;
    entityTypeError = false;
    entityName: any;
    entityNameError = false;
    selectedLhin: any;
    selectedLhinError = false;
    entityAddr: any;
    entityAddrError = false;
    entityCity: any;
    entityCityError = false;
    entityPostal: any;
    entityPostalError = false;
    entityList: any[];
    sectorId = parseInt(this.encrDecrService.get(sessionStorage.getItem('userSectorType')), 10);
    entityTypeList: any[];
    LHINOptions: { label: string; value: number; tempLabel: string }[];
    orghealthTab: boolean;
    orghealthSupplyTab: boolean;
    selectedEntity = 0;
    selectedOrgTemplateErr: boolean;
    private ngSubject: Subject<any> = new Subject<any>();
    addressSuggestion = [];
    indigenousValue: string;
    @ViewChild('autoComplete', { static: false })
    autoComplete: AutoComplete;
    IndigenousError: any;
    submitMsg: boolean;
    rejectMsg: boolean;
    accessMsg: boolean;
    newOrgErrMsg: boolean;
    autoCompleteText;
    constructor(
        private router: Router,
        private webappService: WebAppService,
        public service: SharedService,
        public encrDecrService: EncrDecrService,
        private utilService: UtilityService
    ) {}

    ngOnInit(): void {
        this.getOrganizationTypes();
        this.getOrganizationsListBySector();
        this.getLHIN();
    }

    checkEntityBlock(event) {
        if (this.selectedEntityDoesnotExists === false) {
            this.selectedEntityExistsError = false;
            this.entityNameError = false;
            this.entityTypeError = false;
            this.selectedLhinError = false;
            this.selectedOrgTemplateErr = false;
            this.entityAddrError = false;
            this.entityCityError = false;
            this.entityPostalError = false;
            this.IndigenousError = false;
        } else {
            this.selectedOrgTemplateErr = false;
            this.selectedEntityExists = null;
            this.selectedEntityExistsError = false;
            this.accessMsg = false;
            this.rejectMsg = false;
            this.submitMsg = false;
            this.newOrgErrMsg = false;
        }
    }
    getOrganizationTypes() {
        this.entityTypeList = [];
        this.webappService.getOrgTypes().then(async (resp) => {
            this.entityTypeList = resp.organizationTypeList.sort((a, b) => (a.orgType > b.orgType ? 1 : -1));
        });
    }

    getOrganizationsListBySector(organizationType = 1) {
        this.entityList = [];
        const entityListTemp = [];
        this.webappService.getOrdListBySectors(this.sectorId).then(async (resp) => {
            resp.forEach((element) => {
                const orgDetails = {
                    label: element.organizationName + (element.orgType !== null ? ' ( ' + element.orgType + ')' : ''),
                    value: element.organizationId,
                    tempLabel: element.organizationName,
                    stripAccent: ''
                };
                orgDetails.stripAccent = this.utilService.stripAccent(orgDetails.label);
                entityListTemp.push(orgDetails);
            });
            this.entityList = entityListTemp.sort(
                (a, b) => (a.stripAccent.toLowerCase() > b.stripAccent.toLowerCase() ? 1 : -1)
            );
        });
    }

    onSelectType(dd: Dropdown) {
        this.selectedEntityLabel = dd.selectedOption.tempLabel;
    }

    getLHIN() {
        this.webappService.getLHIN().pipe(take(1)).subscribe((lhinData) => {
            this.LHINOptions = lhinData.locationData.map((e) => {
                return {
                    label: e.locationData,
                    value: e.lhinId
                };
            });
            this.newchangeOrganization(this.sectorId);
        });
    }

    BacktoEntity() {
        this.router.navigate([ 'entity-screen' ]);
    }

    submit() {
        this.selectedEntityExistsError = false;
        this.entityTypeError = false;
        this.entityNameError = false;
        this.selectedLhinError = false;
        this.entityAddrError = false;
        this.entityCityError = false;
        this.entityPostalError = false;
        this.selectedOrgTemplateErr = false;
        this.IndigenousError = false;
        const orgId = [];

        if (this.selectedEntityDoesnotExists) {
            if (this.entityName === undefined || this.entityName === null) {
                this.entityNameError = true;
            }

            if (this.entityType === undefined || this.entityType === null) {
                this.entityTypeError = true;
            }
            if (this.entityAddr === undefined || this.entityAddr === null) {
                this.entityAddrError = true;
            }
            if (this.entityCity === undefined || this.entityCity === null || this.entityCity === '') {
                this.entityCityError = true;
            }
            if (this.entityPostal === undefined || this.entityPostal === null || this.entityPostal === '') {
                this.entityPostalError = true;
            }
            this.selectedOrgTemplateErr = this.selectedEntity === 0 ? true : false;
            if (this.indigenousValue === undefined || this.indigenousValue === null) {
                this.IndigenousError = true;
            }
            if (this.sectorId === 1) {
                if (this.selectedLhin === undefined || this.selectedLhin === null || this.selectedLhin.length === 0) {
                    this.selectedLhinError = true;
                }
            }

            if (
                !this.entityNameError &&
                !this.entityTypeError &&
                !this.selectedLhinError &&
                !this.entityAddrError &&
                !this.entityCityError &&
                !this.entityPostalError &&
                !this.selectedOrgTemplateErr &&
                !this.IndigenousError
            ) {
                const submitObj = {
                    organizationName: this.entityName,
                    organizationAddress: this.entityAddr.Text,
                    organizationCity: this.entityCity,
                    organizationPostalCode: this.entityPostal,
                    organizationTypeId: this.entityType.orgId,
                    lhinID: this.selectedLhin,
                    templateId: this.selectedEntity,
                    indigenousCompany: this.indigenousValue
                };
                this.showNewOrgConfirmation(submitObj);
            }
        } else {
            if (this.selectedEntityExists === undefined || this.selectedEntityExists === null) {
                this.selectedEntityExistsError = true;
            }

            if (
                !this.entityNameError &&
                !this.entityTypeError &&
                !this.selectedLhinError &&
                !this.entityAddrError &&
                !this.entityCityError &&
                !this.entityPostalError &&
                !this.selectedEntityExistsError &&
                !this.selectedOrgTemplateErr
            ) {
                if (this.sectorId === 1) {
                    const submitObj = {
                        organizationId: [ this.selectedEntityExists ]
                    };
                    this.showConfirmationForExists(submitObj);
                } else if (this.sectorId === 2) {
                    const submitObj = {
                        organizationId: [ this.selectedEntityExists ]
                    };
                    this.showConfirmationForExists(submitObj);
                }
            }
        }
    }

    showConfirmationForExists(orgDetails) {
        this.webappService.addExistingOrgToUser(orgDetails).then((resp) => {
            const a = resp;
            if (resp.status === true) {
                if (this.sectorId === 1) {
                    if (resp.message === 'Success-Direct Access Provided') {
                        this.service.saveData(this.selectedEntityLabel, successDirective.firstUserEntitySuccess);
                        this.router.navigate([ 'success' ]);
                    } else {
                        this.service.saveData('', successDirective.healthEntitySuccess);
                        this.router.navigate([ 'success' ]);
                    }
                } else if (this.sectorId === 2) {
                    if (resp.message === 'Success-Direct Access Provided') {
                        this.service.saveData(this.selectedEntityLabel, successDirective.firstUserEntitySuccess);
                        this.router.navigate([ 'success' ]);
                    } else {
                        this.service.saveData(this.selectedEntityLabel, successDirective.nonHealthEntitySuccess);
                        this.router.navigate([ 'success' ]);
                    }
                }
            } else {
                if (resp.message === 'User Already Having Access to the Organization') {
                    this.accessMsg = true;
                    this.rejectMsg = false;
                    this.submitMsg = false;
                } else if (resp.message === 'User Access Disabled or Rejected. Please Contact Help Desk') {
                    this.rejectMsg = true;
                    this.accessMsg = false;
                    this.submitMsg = false;
                } else if (resp.message === 'User Access Request Already Submitted') {
                    this.submitMsg = true;
                    this.rejectMsg = false;
                    this.accessMsg = false;
                } else if (resp.message === 'Success-Direct Access Provided') {
                    this.service.saveData(this.selectedEntityLabel, successDirective.firstUserEntitySuccess);
                    this.router.navigate([ 'success' ]);
                }
            }
        });
    }

    showNewOrgConfirmation(orgDetails) {
        this.newOrgErrMsg = false;
        this.webappService.addNewOrgToUser(orgDetails).then((resp) => {
            if (resp.status === true) {
                this.service.saveData('', successDirective.newEntitySuccess);
                this.router.navigate([ 'success' ]);
            } else {
                this.newOrgErrMsg = true;
            }
        });
    }

    validateRegExp(value) {
        const regExp = new RegExp(
            '[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]',
            'i'
        );
        const validate = regExp.test(this.entityPostal);
        if (validate === false) {
            this.entityPostal = '';
        } else {
            this.entityPostal = this.entityPostal.toUpperCase();
        }
    }

    newchangeOrganization(sectorId) {
        const notApplicable = this.LHINOptions.find((e) => {
            return e.label.toLowerCase() === 'not applicable';
        });
        this.sectorId = sectorId;
        if (sectorId === 1) {
            this.selectedLhin = [];
        } else if (sectorId === 2) {
            if (notApplicable) {
                this.selectedLhin = [ notApplicable.value ];
            }
        }

        this.selectedEntityExists = null;
        this.getOrganizationsListBySector(sectorId);
    }

    selectEntity(id) {
        if (id === 1 || id === 2) {
            this.orghealthTab = true;
            this.orghealthSupplyTab = false;
        } else if (id === 3 || id === 4) {
            this.orghealthTab = false;
            this.orghealthSupplyTab = true;
        }
        this.selectedEntity = id;
    }

    searchAddr($event, Id = '') {
        this.webappService
            .getCanadaPostalAddress($event.query, Id)
            .pipe(takeUntil(this.ngSubject))
            .subscribe((data: { Items: [] }) => {
                this.entityCity = '';
                this.entityPostal = '';
                this.addressSuggestion = data.Items;
                if (Id) {
                    this.autoComplete.overlayVisible = true;
                    timer(400).pipe(take(1)).subscribe((w) => {
                        this.autoComplete._suggestions = data.Items;
                    });
                }
            });
    }

    onSelectedAddress($event) {
        if ($event.Next === 'Retrieve') {
            this.webappService
                .selectedCanadaPostalAddress($event.Id)
                .pipe(takeUntil(this.ngSubject))
                .subscribe((data: any) => {
                    this.entityCity = data.Items[0].City;
                    this.entityPostal = data.Items[0].PostalCode;
                });
        } else {
            this.autoCompleteText = $event.query;
            this.searchAddr({ query: $event.Text }, $event.Id);
        }
    }

    // onSelectedAddress($event) {
    //   //  this.OrganizationAddress = $event.query;
    //   if ($event.Next === 'Retrieve') {
    //       this.webappService
    //           .selectedCanadaPostalAddress($event.Id)
    //           .pipe(takeUntil(this.ngSubject))
    //           .subscribe((data: any) => {
    //               this.entityCity = data.Items[0].City;
    //               this.entityPostal = data.Items[0].PostalCode;
    //           });
    //   } else {
    //       this.searchAddr({ query: $event.Text }, $event.Id);
    //   }
    // }

    ngOnDestroy() {
        this.ngSubject.next();
        this.ngSubject.complete();
    }
}
