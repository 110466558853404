<div class="login-wrapper">
  <div class="login-wrapper-in">
    <div class="forgot-password new-login-headertxt" id="forgot-password">
      <div class="login-logo newlogin-width">
        <img src="./assets/images/logo.png" alt="Government of Ontario" />
      </div>
      <h1 class="application-name newlogin-width">
        <div>{{ 'EntityScreen.covid19Header1' | transloco }}</div>
      </h1>
      <div class="process-name a-mb-20">
        <b *ngIf="!pathCheck">
         {{ 'HOME.forgetPass' | transloco }}
          {{ 'HOME.NoProblem' | transloco }}
        </b>
               
        <b *ngIf="pathCheck">{{ 'HOME.Teamppassword' | transloco }}</b>
      </div>
      <div class="application-text newlogin-width" *ngIf="!pathCheck">
        <div class="application-notes">
          {{ 'HOME.passRecoveryDesc' | transloco }}
        </div>
      </div>
      <div class="user-fields newlogin-width">
        <div class="a-d-flex a-flex-column a-textarea-box pt-2">
          <div class="a-form-label" [ngClass]="{'error-label': emptyEmailError || validEmailError}">
            {{'HOME.EmailAddress' | transloco }}
          </div>
          <div class="ImageClass">
            <i class="fa fa-envelope iconClass"></i>
            <input type="email" class="a-text-input" id="input-email" [(ngModel)]="Email"
                   (keyup.enter)="ResetPassowrd()" [ngClass]="{'error-box': emptyEmailError || validEmailError || inValidEmailId}" autofocus title="Please enter your email address">
          </div>
          <div class="error-message" *ngIf="emptyEmailError && !validEmailError && !inValidEmailId" id="error-guid-password">
            {{'HOME.EmptyEmailAddress' | transloco }}
          </div>
          <div class="error-message" *ngIf="validEmailError && !emptyEmailError && !inValidEmailId" id="error-guid-password">
            {{'HOME.EmailNotavail' | transloco }}
          </div>
          
          <div class="error-message" *ngIf="inValidEmailId && !validEmailError && !emptyEmailError" id="error-guid-password">
            {{'HOME.validEmailAddress' | transloco }}
          </div>
          <div class="a-textarea-footer a-mt-20">
            <button class="a-btn a-btn-primary a-btn-xl login-btn  moh-btn" (click)="ResetPassowrd()" tabindex="0">
              <span *ngIf="!pathCheck">{{ 'HOME.Resetpassword' | transloco }}</span>
              <span *ngIf="pathCheck">{{ 'HOME.sendPassword' | transloco }}</span>
            </button>
          </div>
          <div class="forgot-pwd-link text-center">
            <a tabindex="0" (click)="BacktoLogin()" (keyup.enter)="BacktoLogin()" role="button">{{ 'HOME.cancel' | transloco }}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-login-footer></app-login-footer> -->
  </div>
</div>
