import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: [ './message.component.css' ]
})
export class MessageComponent implements OnInit {
    header;
    message;
    /**
     * Display the warning/info message component
     * @param {DynamicDialogRef} dynamicDialogRef
     * @param {DynamicDialogConfig} dynamicDialogConfig
     * @memberof MessageComponent
     */
    constructor(private dynamicDialogRef: DynamicDialogRef, private dynamicDialogConfig: DynamicDialogConfig) {
        
    }
    /**
     * Fetching the data from the parent data
     *
     * @memberof MessageComponent
     */
    ngOnInit(): void {
        this.header = this.dynamicDialogConfig.data.header;
        this.message = this.dynamicDialogConfig.data.message;
    }
    /**
     * On click of OK we will close the popup
     *
     * @memberof MessageComponent
     */
    closesuccessPopup() {
        this.dynamicDialogRef.close();
    }
}
