<div class="container-fluid home-content">
  <div class="col-md-8 home-margin" id="loginAsOrgTYpe">
    <!-- <img src="./assets/images/logo.png" style="width: 34%;" alt="Logo" /> -->
    <h1 class="application-name">
      {{ "HOME.HomeHeader" | transloco }}
    </h1>
    <div class="col-md-12 no-padding">
      <p>
        {{ "HOME.HomeMsg" | transloco }}
      </p>
      <p>
        {{ "HOME.HomeMsg2" | transloco }}
      </p>
      <h2>
        {{ "HOME.UpdateText" | transloco }}
      </h2>
      <!--<p>
    <span style="text-decoration:underline;cursor:pointer;"><a href="{{healthDoc}}"
        target="_blank">{{ 'HOME.UpdateContent1' | transloco }}</a></span>
    <sup>{{ 'HOME.New' | transloco }}</sup>
  </p>
  <p>
    <span style="text-decoration:underline;cursor:pointer;"><a href="{{nonHealthDoc}}"
        target="_blank">{{ 'HOME.UpdateContent2' | transloco }}</a></span>
    <sup>{{ 'HOME.New' | transloco }}</sup>
  </p>
  <p>
    <span style="text-decoration:underline;cursor:pointer;"><a href="{{supplyDoc}}"
        target="_blank">{{ 'HOME.UpdateContent3' | transloco }}</a></span>
    <sup>{{ 'HOME.New' | transloco }}</sup>
  </p>-->
      <p>
        <span style="text-decoration: underline; cursor: pointer;">
          <a href="{{ healthDoc }}" target="_blank">{{
            "HOME.recentUpdate" | transloco
          }}</a>
        </span>
        <sup>{{ "HOME.HealthUpdate" | transloco }}</sup>
      </p>
      <!-- <p>
        <span style="text-decoration: underline; cursor: pointer;">
          <a href="{{ supplyDoc }}" target="_blank">{{
            "HOME.UpdateContent3" | transloco
          }}</a>
        </span>
        <sup>{{ "HOME.HealthSupplyUpdate" | transloco }}</sup>
      </p> -->
      <p>
        {{ "HOME.UpdateContent4" | transloco }}
      </p>
    </div>
    <div
      class="text-left a-textarea-footer forgot-password-footer a-mt-20 a-mb-20"
    >
      <div class="a-textarea-footer">
        <button
          class="a-btn a-btn-primary a-btn-xl next-btn"
          (click)="displayPopup()"
          tabindex="0"
        >
          {{ "HOME.Start" | transloco }}
        </button>
      </div>
    </div>
    <p>
      {{ "HOME.HelpText1" | transloco }}
      <a
        (click)="navigateToHelp()"
        tabindex="0"
        (keyup.enter)="navigateToHelp()"
      >
        {{ "HOME.HelpLink" | transloco }}</a
      >
      {{ "HOME.HelpText2" | transloco }}
    </p>
  </div>
</div>
<p-dialog
  [(visible)]="showNavPopup"
  class="indige-popup"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '40%' }"
  [baseZIndex]="10000">
  <div>
    <div class="a-form-label home-popup-header">{{ "HOME.homeUpdatePopupHeader" | transloco }}</div>
    <div class="ui-g radio-list home-popup-para1">
      <div>{{ "HOME.homeUpdatePopupDesc1" | transloco }} <b>{{ "HOME.homeUpdatePopupDesc2" | transloco }}</b> {{ "HOME.homeUpdatePopupDesc3" | transloco }}</div>
      <div class="home-popup-para2"><b>{{ "HOME.homeUpdatePopupDesc4" | transloco }}</b> {{ "HOME.homeUpdatePopupDesc5" | transloco }}</div>
    </div>
  </div>
  <p-footer>
    <span (click)="Start()" class="home-backbtn">{{ "HOME.homeUpdatePopupLink" | transloco }}</span>
    
    <button
      class="a-btn a-btn-transparent a-btn-lg submit-btn home-popup-startbtn"
      (click)="cancelPopup()"
      tabindex="0">
      {{ "HOME.homeUpdatePopupBtn" | transloco }}
    </button>
  </p-footer>
</p-dialog>
