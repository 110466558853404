import { Injectable } from '@angular/core';
declare var window;
declare var document;

@Injectable({
    providedIn: 'root'
})
export class BrowserDetectService {
    databrowser = [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
    ];
    constructor() {}

    isMobile() {
        return /Mobi/.test(navigator.userAgent);
    }
    matchItem(header, data) {
        let i = 0,
            j = 0,
            html = '',
            regex,
            regexv,
            match,
            matches,
            version;

        for (i = 0; i < data.length; i += 1) {
            regex = new RegExp(data[i].value, 'i');
            match = regex.test(header);
            if (match) {
                regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                matches = header.match(regexv);
                version = '';
                if (matches) {
                    if (matches[1]) {
                        matches = matches[1];
                    }
                }
                if (matches) {
                    matches = matches.split(/[._]+/);
                    for (j = 0; j < matches.length; j += 1) {
                        if (j === 0) {
                            version += matches[j] + '.';
                        } else {
                            version += matches[j];
                        }
                    }
                } else {
                    version = '0';
                }
                return {
                    name: data[i].name,
                    version: parseFloat(version)
                };
            }
        }
        return { name: 'unknown', version: 0 };
    }
    getBrowserName() {
        // Opera 8.0+
        if ((window.opr && window.opr.addons) || window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
            return 'Opera';
        }

        // Firefox 1.0+
        // if (typeof InstallTrigger !== 'undefined') {
        //     return 'Firefox';
        // }

        // Safari 3.0+ "[object HTMLElementConstructor]"
        if (
            /constructor/i.test(window.HTMLElement) ||
            ((p) => {
                return p.toString() === '[object SafariRemoteNotification]';
            })(!window['safari'])
        ) {
            return 'Safari';
        }

        // Internet Explorer 6-11
        if (/* @cc_on!@*/ false || document.documentMode) {
            return 'Internet Explorer';
        }

        // Edge 20+
        if (!document.documentMode && window.StyleMedia) {
            return 'Microsoft Edge';
        }

        // Chrome
        if (window.chrome) {
            return 'Chrome';
        }
    }

    getOSName() {
        let os;
        let ver;
        if (this.isMobile()) {
            if (/Windows/.test(navigator.userAgent)) {
                os = 'Windows';
                if (/Phone 8.0/.test(navigator.userAgent)) {
                    os += ' Phone 8.0';
                } else if (/Phone 10.0/.test(navigator.userAgent)) {
                    os += ' Phone 10.0';
                }
            } else if (/Android/.test(navigator.userAgent)) {
                ver = () => {
                    if (/Android/.test(navigator.appVersion)) {
                        const v = navigator.appVersion.match(/Android (\d+).(\d+)/);
                        return v;
                    }
                };

                os = ver[0];
            } else if (/iPhone;/.test(navigator.userAgent)) {
                ver = () => {
                    if (/iP(hone|od|ad)/.test(navigator.appVersion)) {
                        let v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
                        return [ parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10) ];
                    }
                };

                os = 'iOS ' + ver[0] + '.' + ver[1] + '.' + ver[2];
            } else if (/iPad;/.test(navigator.userAgent)) {
                ver = () => {
                    if (/iP(hone|od|ad)/.test(navigator.appVersion)) {
                        let v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
                        return [ parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10) ];
                    }
                };

                os = 'iOS ' + ver[0] + '.' + ver[1] + '.' + ver[2];
            } else if (/BBd*/.test(navigator.userAgent)) {
                os = 'BlackBerry';
            }
        } else {
            if (/Windows/.test(navigator.userAgent)) {
                os = 'Windows';
                if (/5.1;/.test(navigator.userAgent)) {
                    os += ' XP';
                } else if (/6.0;/.test(navigator.userAgent)) {
                    os += ' Vista';
                } else if (/6.1;/.test(navigator.userAgent)) {
                    os += ' 7';
                } else if (/6.2/.test(navigator.userAgent)) {
                    os += ' 8';
                } else if (/10.0;/.test(navigator.userAgent)) {
                    os += ' 10';
                }

                if (/64/.test(navigator.userAgent)) {
                    os += ' 64-bit';
                } else {
                    os += ' 32-bit';
                }
            } else if (/Macintosh/.test(navigator.userAgent)) {
                os = 'Macintosh';
                if (/OS X/.test(navigator.userAgent)) {
                    os += ' OS X';
                }
            }
        }

        return os;
    }

    getBrowserZoom() {
        if (window.visualViewport) {
            return Math.round(window.outerWidth / window.visualViewport.width * 100);
        } else {
            return Math.round(window.devicePixelRatio * 100);
        }
    }
    getBrowser() {
        const header = [
            navigator.platform,
            navigator.userAgent,
            navigator.appVersion,
            navigator.vendor,
            window.opera
        ].join(' ');
        return {
            os: this.getOSName(),
            browserZoom: this.getBrowserZoom(),
            browser: this.matchItem(header, this.databrowser),
            language: navigator.language,
            languages: navigator.languages,
            user_agent: navigator.userAgent,
            device: this.isMobile() ? 'Mobile' : 'Desktop',
            referrer: document.referrer || 'N/A',
            online: navigator.onLine,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            screen_resolution: screen.width + ' x ' + screen.height,
            cookie_enabled: navigator.cookieEnabled
        };
    }
}
