import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Router, NavigationEnd } from '@angular/router';
import { SharedService } from './components/sharedservice/sharedservice';
import { UserIdleService } from 'angular-user-idle';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageComponent } from './components/message/message.component';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApplicationInsightService } from './service/application-insight.service';
import { environment } from 'src/environments/environment';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FooterComponent } from './components/footer/footer.component';
declare var $;


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.css' ]
})
export class AppComponent implements OnInit, OnDestroy {
    footerAutoCall = false;
    username: string;
    password: string;
    userError: boolean;
    passwordError: boolean;
    currentPath: any;
    @ViewChild(FooterComponent) userInput: FooterComponent;

    title = 'Demo App';
    lang = ' English';
    langStatus = false;
    // the locales the app supports
    locales = [ { label: '🇺🇸 English (US)', value: 'en' }, { label: '🇫🇷 Français', value: 'fr' } ];
    // the user's locale
    detectedLocale = '';
    // the default locale
    locale = this.locales[0].value;
    loader = true;
    idleTimerStart;
    private ngSubject: Subject<any> = new Subject<any>();
    idleTimeOut;
    userNameCheck: boolean;
    constructor(
        private translocoService: TranslocoService,
        public cdr: ChangeDetectorRef,
        private sharedService: SharedService,
        private userIdle: UserIdleService,
        private router: Router,
        private applicationInsight: ApplicationInsightService,
        private dialogService: DialogService,
        private TitleService: Title
    ) {
        this.router.events.subscribe((e) => {
            this.footerAutoCall = false;
            if (e instanceof NavigationEnd) {
                setTimeout(() => {
                    this.footerAutoCall = true;
                    sessionStorage.setItem('pageUrl', router.url);
                }, 0);
            }
        });
        this.sharedService.currentPath.subscribe((path) => {
            this.currentPath = path;
        });
        // this.detectedLocale = this.getUsersLocale('en-US');

        // // generate a regex from the locales we support
        // const supportedRegex = new RegExp('^' + this.locales.map((l) => l.value.substring(0, 2)).join('|^'));
        // // check if the user's preferred language is supported and if so, use it.
        // if (this.detectedLocale.match(supportedRegex)) {
        //     this.updateLocale(this.detectedLocale);
        // }
        // this.sharedService.isLoader.subscribe((data) => {
        //     this.loader = data;
        //     // this.cdr.detectChanges();
        // });
        const d = this.sharedService.idleTimeout.subscribe((data) => {
            if (data) {
                d.unsubscribe();
                this.setUpIdleTimeout();
            }
        });
    }
    ngOnDestroy() {
        this.ngSubject.next();
        this.ngSubject.complete();
    }
     ngOnInit() {
         this.sharedService.isLoader.subscribe((data) => {
             setTimeout(() => {
                this.loader = data;
             });
         });

         timer(0, 1000).subscribe(result => {
            this.userInput.callTimer();
        });
    }
    private setUpIdleTimeout() {
        if (this.idleTimerStart) {
            this.idleTimerStart.unsubscribe();
            this.idleTimeOut.unsubscribe();
            this.userIdle.stopWatching();
        }
        this.userIdle.startWatching();
        this.idleTimerStart = this.userIdle.onTimerStart();
        this.idleTimerStart.subscribe();
        this.idleTimeOut = this.userIdle.onTimeout();
        this.idleTimeOut.pipe(take(1)).subscribe(() => {
            this.sharedService.updateAutoSave(Math.round(Math.random() * 1000000));
            $('.modal').modal('hide'); // closes all active pop ups.
            $('.modal-backdrop').remove(); // removes the grey overlay.
            const idleTimePopUp = this.dialogService.open(MessageComponent, {
                data: {
                    header: this.translocoService.translate('HOME.sessionTimeout'),
                    message: this.translocoService.translate('HOME.sessionMsg')
                },
                showHeader: false,
                closable: false
            });
            idleTimePopUp.onClose.subscribe((data) => {
                this.router.navigate([ 'Login' ]);
            });
        });
    }
    getUsersLocale(defaultValue: string): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return defaultValue;
        }
        const wn = window.navigator as any;
        let lang = wn.languages ? wn.languages[0] : defaultValue;
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
        return lang;
    }
    // change locale/language at runtime
    // updateLocale(locale) {
    //     if (this.locales.some((l) => l.value === locale)) {
    //         this.locale = locale;
    //     }
    //     const lang = locale.substring(0, 2);
    //     this.translocoService.setActiveLang(lang);
    // }

    // statusChange() {
    //     this.langStatus = !this.langStatus;
    //     this.lang = this.langStatus === true ? 'Français' : 'English';
    //     this.updateLocale(this.langStatus === true ? 'fr' : 'en');
    // }
    private configureApplicationInSights() {
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this.getApplicationInsightConfig(),
                enableAutoRouteTracking: true,
                isBrowserLinkTrackingEnabled: true
            }
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView();
        this.applicationInsight.applyInSights(appInsights);
    }
    getApplicationInsightConfig(): string {
        if (environment.environments.local.baseUrl === window.location.origin) {
            return environment.environments.local.apiUrl;
        }
        if (environment.environments.dev.baseUrl === window.location.origin) {
            return environment.environments.dev.apiUrl;
        }

        if (environment.environments.qa.baseUrl === window.location.origin) {
            return environment.environments.qa.apiUrl;
        }
        if (environment.environments.stage.baseUrl === window.location.origin) {
            return environment.environments.stage.apiUrl;
        }

        if (environment.environments.prod.baseUrl === window.location.origin) {
            return environment.environments.prod.apiUrl;
        }
        if (environment.environments.live.baseUrl === window.location.origin) {
            return environment.environments.live.apiUrl;
        }
    }
}
