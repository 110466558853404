import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../sharedservice/sharedservice';
import { EncrDecrService } from '../sharedservice/enc-dec.service';
import { AppComponent } from 'src/app/app.component';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentPath: string;
  sectorType: any;
  showLink = true;
  hideLinkRoute: string[] = ['/Login', '/reset-password', '/forget-password', '/set-new-password', '/Register', '/reset-success', '/registration-message'];
  userName: boolean;
  //@Input() userName :AppComponent;
  constructor(private router: Router, private sharedService: SharedService, private encrDecrService: EncrDecrService, private cdr: ChangeDetectorRef) {
    if (this.hideLinkRoute.indexOf(this.router.url) >= 0){
      this.showLink = false;
    }
  }

  ngOnInit(): void {   
    this.userName = ((sessionStorage.getItem('userName') !== undefined && sessionStorage.getItem('userName') !== null) ? true: false);     
    if (sessionStorage.getItem('userSectorType') !== null && sessionStorage.getItem('userSectorType') !== undefined) {
    this.sectorType = this.encrDecrService.get(sessionStorage.getItem('userSectorType')) ;
    }    
  }

  public callTimer() {
    this.userName = ((sessionStorage.getItem('userName') !== undefined && sessionStorage.getItem('userName') !== null) ? true: false);
    if (sessionStorage.getItem('userSectorType') !== null && sessionStorage.getItem('userSectorType') !== undefined) {
      this.sectorType = this.encrDecrService.get(sessionStorage.getItem('userSectorType')) ;
    }
    this.cdr.detectChanges();
  }

}
