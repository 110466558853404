<div class="insHeader">
  <div tabindex="0">{{'DataInstructions.DataInstHeader' | transloco }}</div>
  <span class="float-right" (click)="continueClicked()" tabindex="0" title="{{'EntityScreen.Close' | transloco }}"><i class="fa fa-times" aria-hidden="true"></i></span>
</div>
<div class="instruction">
  <div class="pb-3" tabindex="0"><b> {{'DataInstructions.Tobegin' | transloco }},</b> {{'DataInstructions.addInventoryMsg' | transloco }}</div>

  <div class="pb-3" tabindex="0">
    {{'DataInstructions.useDropdown' | transloco }}
  </div>

  <div class="pb-1" tabindex="0"><b> {{'DataInstructions.SeeAll' | transloco }}</b></div>
  <div class="pb-3" tabindex="0">{{'DataInstructions.FullListInfo' | transloco }}</div>

  <div class="pb-1" tabindex="0"><b>{{'DataInstructions.SwitchTabs' | transloco }}</b></div>
  <div class="pb-3" tabindex="0">{{'DataInstructions.OtherInvTypes' | transloco }}</div>

  <div class="pb-1" tabindex="0"><b>{{'DataInstructions.PreviousSubmission' | transloco }}</b></div>
  <div class="pb-3" tabindex="0">{{'DataInstructions.PrevSubmisionText' | transloco }}</div>

  <div class="pb-3" tabindex="0">{{'DataInstructions.PleaseNote' | transloco }}</div>

  <div class="pb-1" tabindex="0"><b>{{'DataInstructions.SubmitDI' | transloco }}</b></div>
  <div tabindex="0">{{'DataInstructions.clickSubmit' | transloco }}</div>
  <div class="a-mt-20 a-mb-10 text-right">

    <button tabindex="0" class="a-btn a-btn-transparent a-btn-lg save-btn float-right" (click)="continueClicked()">
      {{'EntityScreen.Continue' | transloco }}
    </button>
  </div>
</div>
