export const environment = {
  production: false,
  postalApiKey: 'af71-kp88-nx79-ty86', //Canada Post AddressComplete api key
  environments: {
    local: {
      env: 'Local',
      baseUrl: 'https://localhost:4200',
      apiUrl: 'https://dev-api-ontario-ppecse-survey.azurewebsites.net',
      instrumentalKey: 'a5720997-7816-45e1-a0fe-4497bc9ae51c',
      inventorySubmitCheck: false,
      documentLink: {
        home: {
          health:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Homepage/Health.pdf',
          nonHealth:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Homepage/NonHealth.pdf',
          nonHealthFr:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Homepage/NonHealth_FR.pdf',
          supply:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Homepage/SupplyEntities.pdf',
          nonHealthSupply:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Homepage/Non-Health_SupplyEntites.pdf',
        },
        help: {
          health:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/Health.pdf',
          healthSupply:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/HealthSupply.pdf',
          nonHealthEn:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/NonHealth.pdf',
          nonHealthFr:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/NonHealth_FR.pdf',
          nonHealthSupplyEn:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/NonHealthSupply.pdf',
          nonHealthSupplyFr: '',
          healthFr:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/HealthHowTo_FR.pdf',
          healthRegGuideFr:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/HealthRegistration_FR.pdf',
          healthRegGuide:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/HealthRegistration.pdf',
          nonHealthRegDocFr:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/NonHealthRegistration_FR.pdf',
          nonHealthRegDocEn:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/NonHealthRegistration.pdf'
        },
        inventory: { //KeyVault: StorageAccountConnectionString > Blob container: covidccesurvey > Folder: Inventory
          health: 'Health.pdf',
          healthSupply: 'HealthSupply.pdf',
          nonHealth: 'NonHealth.pdf',
          nonHealthSupply: 'NonHealthSupply.pdf',
          healthCsv: 'Health.xlsx',
          healthSupplyCsv: 'HealthSupply.xlsx',
          nonHealthCsv: 'NonHealth.xlsx',
          nonHealthSupplyCsv: 'NonHealthSupply.xlsx',
          healthFr: 'Health_FR.pdf',
          healthCsvFr: 'Health_FR.xlsx',
          healthSupplyFr: 'HealthSupply_FR.pdf',
          nonHealthFr: 'NonHealth_FR.pdf',
          nonHealthSupplyFr: 'NonHealthSupply_FR.pdf',
          healthSupplyCsvFr: 'HealthSupply_FR.xlsx',
          nonHealthCsvFr: 'NonHealth_FR.xlsx',
          nonHealthSupplyCsvFr: 'NonHealthSupply_FR.xlsx'
        },
      },
    },
    dev: {
      env: 'dev',
      baseUrl: 'https://dev-ui-ontario-ppecse-survey.azurewebsites.net',
      apiUrl: 'https://dev-api-ontario-ppecse-survey.azurewebsites.net',
      instrumentalKey: 'a5720997-7816-45e1-a0fe-4497bc9ae51c',
      inventorySubmitCheck: false,
      documentLink: {
        home: {
          health:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Homepage/Health.pdf',
          nonHealth:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Homepage/NonHealth.pdf',
          nonHealthFr:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Homepage/NonHealth_FR.pdf',
          supply:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Homepage/SupplyEntities.pdf',
          nonHealthSupply:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Homepage/Non-Health_SupplyEntites.pdf',
        },
        help: {
          health:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/Health.pdf',
          healthSupply:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/HealthSupply.pdf',
          nonHealthEn:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/NonHealth.pdf',
          nonHealthFr:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/NonHealth_FR.pdf',
          nonHealthSupplyEn:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/NonHealthSupply.pdf',
          nonHealthSupplyFr: '',
          healthFr:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/HealthHowTo_FR.pdf',
          healthRegGuideFr:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/HealthRegistration_FR.pdf',
          healthRegGuide:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/HealthRegistration.pdf',
          nonHealthRegDocFr:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/NonHealthRegistration_FR.pdf',
          nonHealthRegDocEn:
            'https://sco0000dev0505oms.blob.core.windows.net/covidccesurvey/Help/NonHealthRegistration.pdf'
        },
        inventory: { //KeyVault: StorageAccountConnectionString > Blob container: covidccesurvey > Folder: Inventory
          health: 'Health.pdf',
          healthSupply: 'HealthSupply.pdf',
          nonHealth: 'NonHealth.pdf',
          nonHealthSupply: 'NonHealthSupply.pdf',
          healthCsv: 'Health.xlsx',
          healthSupplyCsv: 'HealthSupply.xlsx',
          nonHealthCsv: 'NonHealth.xlsx',
          nonHealthSupplyCsv: 'NonHealthSupply.xlsx',
          healthFr: 'Health_FR.pdf',
          healthCsvFr: 'Health_FR.xlsx',
          healthSupplyFr: 'HealthSupply_FR.pdf',
          nonHealthFr: 'NonHealth_FR.pdf',
          nonHealthSupplyFr: 'NonHealthSupply_FR.pdf',
          healthSupplyCsvFr: 'HealthSupply_FR.xlsx',
          nonHealthCsvFr: 'NonHealth_FR.xlsx',
          nonHealthSupplyCsvFr: 'NonHealthSupply_FR.xlsx'
        },
      },
    },
    qa: {
      env: 'qa',
      baseUrl: 'https://ist-0220-sco-web.azurewebsites.net',
      apiUrl: 'https://ist-0220-sco-api.azurewebsites.net',
      instrumentalKey: '5b30bf26-fb46-4405-9c65-b5983185681b',
      inventorySubmitCheck: true,
      documentLink: {
        home: {
          health:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Homepage/Health.pdf',
          nonHealth:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Homepage/NonHealth.pdf',
          nonHealthFr:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Homepage/NonHealth_FR.pdf',
          supply:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Homepage/SupplyEntities.pdf',
          nonHealthSupply:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Homepage/Non-Health_SupplyEntites.pdf',
          },
        help: {
          health:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/Health.pdf',
          healthSupply:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/HealthSupply.pdf',
          nonHealthEn:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealth.pdf',
          nonHealthFr:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealth_FR.pdf',
          nonHealthSupplyEn:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealthSupply.pdf',
          nonHealthSupplyFr: '',
          healthFr:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/HealthHowTo_FR.pdf',
          healthRegGuideFr:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/HealthRegistration_FR.pdf',
          healthRegGuide:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/HealthRegistration.pdf',
          nonHealthRegDocFr:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealthRegistration_FR.pdf',
          nonHealthRegDocEn:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealthRegistration.pdf'
        },
        inventory: { //KeyVault: StorageAccountConnectionString > Blob container: covidccesurvey > Folder: Inventory
          health: 'Health.pdf',
          healthSupply: 'HealthSupply.pdf',
          nonHealth: 'NonHealth.pdf',
          nonHealthSupply: 'NonHealthSupply.pdf',
          healthCsv: 'Health.xlsx',
          healthSupplyCsv: 'HealthSupply.xlsx',
          nonHealthCsv: 'NonHealth.xlsx',
          nonHealthSupplyCsv: 'NonHealthSupply.xlsx',
          healthFr: 'Health_FR.pdf',
          healthCsvFr: 'Health_FR.xlsx',
          healthSupplyFr: 'HealthSupply_FR.pdf',
          nonHealthFr: 'NonHealth_FR.pdf',
          nonHealthSupplyFr: 'NonHealthSupply_FR.pdf',
          healthSupplyCsvFr: 'HealthSupply_FR.xlsx',
          nonHealthCsvFr: 'NonHealth_FR.xlsx',
          nonHealthSupplyCsvFr: 'NonHealthSupply_FR.xlsx'
        },
      },
    },
    stage: {
      env: 'stage',
      baseUrl: 'https://stage.ontario-ppecse-survey.mgcs.gov.on.ca', //Azure: https://ist-0220-sco-web.azurewebsites.net
      apiUrl: 'https://stage.ws.ontario-ppecse-survey.mgcs.gov.on.ca', //Azure: https://ist-0220-sco-api.azurewebsites.net
      instrumentalKey: '5b30bf26-fb46-4405-9c65-b5983185681b',
      inventorySubmitCheck: true,
      documentLink: {
        home: {
          health:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Homepage/Health.pdf',
          nonHealth:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Homepage/NonHealth.pdf',
          nonHealthFr:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Homepage/NonHealth_FR.pdf',
          supply:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Homepage/SupplyEntities.pdf',
          nonHealthSupply:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Homepage/Non-Health_SupplyEntites.pdf',
          },
        help: {
          health:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/Health.pdf',
          healthSupply:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/HealthSupply.pdf',
          nonHealthEn:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealth.pdf',
          nonHealthFr:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealth_FR.pdf',
          nonHealthSupplyEn:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealthSupply.pdf',
          nonHealthSupplyFr: '',
          healthFr:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/HealthHowTo_FR.pdf',
          healthRegGuideFr:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/HealthRegistration_FR.pdf',
          healthRegGuide:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/HealthRegistration.pdf',
          nonHealthRegDocFr:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealthRegistration_FR.pdf',
          nonHealthRegDocEn:
            'https://sco0000ist0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealthRegistration.pdf'
        },
        inventory: { //KeyVault: StorageAccountConnectionString > Blob container: covidccesurvey > Folder: Inventory
          health: 'Health.pdf',
          healthSupply: 'HealthSupply.pdf',
          nonHealth: 'NonHealth.pdf',
          nonHealthSupply: 'NonHealthSupply.pdf',
          healthCsv: 'Health.xlsx',
          healthSupplyCsv: 'HealthSupply.xlsx',
          nonHealthCsv: 'NonHealth.xlsx',
          nonHealthSupplyCsv: 'NonHealthSupply.xlsx',
          healthFr: 'Health_FR.pdf',
          healthCsvFr: 'Health_FR.xlsx',
          healthSupplyFr: 'HealthSupply_FR.pdf',
          nonHealthFr: 'NonHealth_FR.pdf',
          nonHealthSupplyFr: 'NonHealthSupply_FR.pdf',
          healthSupplyCsvFr: 'HealthSupply_FR.xlsx',
          nonHealthCsvFr: 'NonHealth_FR.xlsx',
          nonHealthSupplyCsvFr: 'NonHealthSupply_FR.xlsx'
        },
      },
    },
    live: {
      env: 'live',
      baseUrl: 'https://prd-0220-sco-web.azurewebsites.net',
      apiUrl: 'https://prd-0220-sco-api.azurewebsites.net',
      instrumentalKey: 'b637ef3a-1367-4975-be78-4c38aa8347ec', //Application Insights: prd-0220-SCO-0000
      inventorySubmitCheck: true,
      documentLink: {
        home: {
          health:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Homepage/Health.pdf',
          nonHealth:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Homepage/NonHealth.pdf',
          nonHealthFr:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Homepage/NonHealth_FR.pdf',
          supply:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Homepage/SupplyEntities.pdf',
          nonHealthSupply:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Homepage/Non-Health_SupplyEntites.pdf'
          },
        help: {
          health:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/Health.pdf',
          healthSupply:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/HealthSupply.pdf',
          nonHealthEn:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealth.pdf',
          nonHealthFr:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealth_FR.pdf',
          nonHealthSupplyEn:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealthSupply.pdf',
          nonHealthSupplyFr: '',
          healthFr:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/HealthHowTo_FR.pdf',
          healthRegGuideFr:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/HealthRegistration_FR.pdf',
          healthRegGuide:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/HealthRegistration.pdf',
          nonHealthRegDocFr:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealthRegistration_FR.pdf',
          nonHealthRegDocEn:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealthRegistration.pdf'
        },
        inventory: { //KeyVault: StorageAccountConnectionString > Blob container: covidccesurvey > Folder: Inventory
          health: 'Health.pdf',
          healthSupply: 'HealthSupply.pdf',
          nonHealth: 'NonHealth.pdf',
          nonHealthSupply: 'NonHealthSupply.pdf',
          healthCsv: 'Health.xlsx',
          healthSupplyCsv: 'HealthSupply.xlsx',
          nonHealthCsv: 'NonHealth.xlsx',
          nonHealthSupplyCsv: 'NonHealthSupply.xlsx',
          healthFr: 'Health_FR.pdf',
          healthCsvFr: 'Health_FR.xlsx',
          healthSupplyFr: 'HealthSupply_FR.pdf',
          nonHealthFr: 'NonHealth_FR.pdf',
          nonHealthSupplyFr: 'NonHealthSupply_FR.pdf',
          healthSupplyCsvFr: 'HealthSupply_FR.xlsx',
          nonHealthCsvFr: 'NonHealth_FR.xlsx',
          nonHealthSupplyCsvFr: 'NonHealthSupply_FR.xlsx'
        },
      },
    },
    prod: {
      env: 'prod',
      baseUrl: 'https://ontario-ppecse-survey.mgcs.gov.on.ca', //Azure: https://prd-0220-sco-web.azurewebsites.net
      apiUrl: 'https://ws.ontario-ppecse-survey.mgcs.gov.on.ca', //Azure: https://prd-0220-sco-api.azurewebsites.net
      instrumentalKey: 'b637ef3a-1367-4975-be78-4c38aa8347ec', //Application Insights: prd-0220-SCO-0000
      inventorySubmitCheck: true,
      documentLink: {
        home: {
          health:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Homepage/Health.pdf',
          nonHealth:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Homepage/NonHealth.pdf',
          nonHealthFr:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Homepage/NonHealth_FR.pdf',
          supply:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Homepage/SupplyEntities.pdf',
          nonHealthSupply:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Homepage/Non-Health_SupplyEntites.pdf'
          },
        help: {
          health:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/Health.pdf',
          healthSupply:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/HealthSupply.pdf',
          nonHealthEn:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealth.pdf',
          nonHealthFr:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealth_FR.pdf',
          nonHealthSupplyEn:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealthSupply.pdf',
          nonHealthSupplyFr: '',
          healthFr:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/HealthHowTo_FR.pdf',
          healthRegGuideFr:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/HealthRegistration_FR.pdf',
          healthRegGuide:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/HealthRegistration.pdf',
          nonHealthRegDocFr:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealthRegistration_FR.pdf',
          nonHealthRegDocEn:
            'https://sco0000prd0220oms.blob.core.windows.net/covidccesurvey/Help/NonHealthRegistration.pdf'
        },
        inventory: { //KeyVault: StorageAccountConnectionString > Blob container: covidccesurvey > Folder: Inventory
          health: 'Health.pdf',
          healthSupply: 'HealthSupply.pdf',
          nonHealth: 'NonHealth.pdf',
          nonHealthSupply: 'NonHealthSupply.pdf',
          healthCsv: 'Health.xlsx',
          healthSupplyCsv: 'HealthSupply.xlsx',
          nonHealthCsv: 'NonHealth.xlsx',
          nonHealthSupplyCsv: 'NonHealthSupply.xlsx',
          healthFr: 'Health_FR.pdf',
          healthCsvFr: 'Health_FR.xlsx',
          healthSupplyFr: 'HealthSupply_FR.pdf',
          nonHealthFr: 'NonHealth_FR.pdf',
          nonHealthSupplyFr: 'NonHealthSupply_FR.pdf',
          healthSupplyCsvFr: 'HealthSupply_FR.xlsx',
          nonHealthCsvFr: 'NonHealth_FR.xlsx',
          nonHealthSupplyCsvFr: 'NonHealthSupply_FR.xlsx'
        },
      },
    },
  },
};
