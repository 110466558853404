<div class="page-title a-p-20">
  <h4 style="margin-left: 0.4em;text-align:center"> <img src="./assets/images/logo.png" style="width: 20%;" alt="Logo" />COVID-19 CSE Inventory Survey</h4>
</div>

<div class="SurveyClass" style="margin:40px;">
  <div class="col-sm-4 pbx20" >
    <label class="a-form-label" for="ipt4" id="iptlabel4">1. {{ 'FormControls.FirstName' | transloco }} </label>
    <input class="a-text-input a-input-secondary widthClass" [ngClass]="{'a-input-error':FirstNameErr}" id="ipt4" [(ngModel)]="FirstName" aria-labelledby="iptlabel4" />
    <div *ngIf="FirstNameErr" class="a-form-error">Please enter a valid textual response without numbers or punctuation.</div>
  </div>

  <div class="col-sm-4 pbx20">
    <label class="a-form-label" for="ipt6" id="iptlabel6">2. {{ 'FormControls.LastName' | transloco }}</label>

    <input class="a-text-input a-input-secondary widthClass" [(ngModel)]="LastName" placeholder="Hint text" id="ipt6"
           aria-labelledby="iptlabel6" [ngClass]="{'a-input-error':LastNameErr}" />
    <div *ngIf="LastNameErr" class="a-form-error">Please enter a valid textual response without numbers or punctuation.</div>


  </div>
  <div class="col-sm-4 pbx20">
    <label class="a-form-label" for="ipt4" id="iptlabel4">3. {{ 'FormControls.FormTitle' | transloco }}</label>
    <input class="a-text-input a-input-secondary widthClass" id="ipt4" [ngClass]="{'a-input-error':RespondentTitleErr}" [(ngModel)]="RespondentTitle" aria-labelledby="iptlabel4" />
    <div *ngIf="RespondentTitleErr" class="a-form-error">Please answer this question.</div>

  </div>
  <div class="col-sm-4 pbx20">
    <label class="a-form-label" for="ipt5" id="iptlabel5">4. {{ 'FormControls.FormEmail' | transloco }}</label>
    <input class="a-text-input a-input-secondary widthClass" [ngClass]="{'a-input-error':EmailErr}" [(ngModel)]="EmailId" placeholder="Hint text" id="ipt5"
           aria-labelledby="iptlabel5" />
    <div *ngIf="EmailErr" class="a-form-error">Please answer this question.</div>

  </div>
  <div class="col-sm-4 pbx20">
    <label class="a-form-label" for="ipt6" id="iptlabel6">5. {{ 'FormControls.OrganizationName' | transloco }}</label>

    <input class="a-text-input a-input-secondary widthClass" [ngClass]="{'a-input-error':OrgNameErr}" [(ngModel)]="OrgName" placeholder="Hint text" id="ipt6"
           aria-labelledby="iptlabel6" />
    <div *ngIf="OrgNameErr" class="a-form-error">Please answer this question.</div>


  </div>
  <div class="col-sm-4 pbx20">
    <label class="a-form-label" for="ipt6" id="iptlabel6">6. {{ 'FormControls.OrganizationType' | transloco }}</label>
    <div class="d-flex" id="dropdownField">
      <p-dropdown [options]="oranizationTypes" class="workflow-dropdown width100 dropdownclass" [(ngModel)]="selectedorganizationType" placeholder="Select Organization Type" optionLabel="name"></p-dropdown>

    </div>
    <div *ngIf="selectedorganizationTypeErr" class="a-form-error">Please answer this question.</div>
  </div>
  <div class="col-sm-4 pbx20">
    <label class="a-form-label" for="ipt6" id="iptlabel6">7. {{ 'FormControls.LHIN' | transloco }}</label>
    <div class="d-flex">
      <p-multiSelect tabindex="5" class="width100 multiDropdownCls" [options]="LHINOptions" placeholder="Select" [(ngModel)]="selectedLHIN" name="selectedLHIN" [showHeader]="false" [filter]="false" defaultLabel="Select" maxSelectedLabels="10"></p-multiSelect>

    </div>
    <div *ngIf="selectedLHINErr" class="a-form-error">Please answer this question.</div>
  </div>
  <div class="col-sm-4 pbx20">
    <label class="a-form-label" for="ipt6" id="iptlabel6">8. {{ 'FormControls.OrganizationAddress' | transloco }}</label>

    <input class="a-text-input a-input-secondary widthClass" [ngClass]="{'a-input-error':OrganizationAddressErr}" [(ngModel)]="OrganizationAddress" placeholder="Hint text" id="ipt6"
           aria-labelledby="iptlabel6" />
    <div *ngIf="OrganizationAddressErr" class="a-form-error">Please answer this question.</div>


  </div>
  <div class="col-sm-4 pbx20">
    <label class="a-form-label" for="ipt6" id="iptlabel6">9. {{ 'FormControls.OrganizationCity' | transloco }}</label>

    <input class="a-text-input a-input-secondary widthClass" [ngClass]="{'a-input-error':OrganizationCityErr}" [(ngModel)]="OrganizationCity" placeholder="Hint text" id="ipt6"
           aria-labelledby="iptlabel6" />
    <div *ngIf="OrganizationCityErr" class="a-form-error">Please answer this question.</div>


  </div>
  <div class="col-sm-4 pbx20">
    <label class="a-form-label" for="ipt6" id="iptlabel6">10. {{ 'FormControls.OrganizationPostal' | transloco }}</label>

    <input class="a-text-input a-input-secondary widthClass" [ngClass]="{'a-input-error':OrganizationPostalErr}" [(ngModel)]="OrganizationPostal" placeholder="Hint text" id="ipt6"
           aria-labelledby="iptlabel6" />
    <div *ngIf="OrganizationPostalErr" class="a-form-error">Please answer this question.</div>


  </div>
  <div class="row" style="padding-left: 16px;">
    <div class="col-sm-3" (click)="openHealthTab()" style="padding-bottom:10px;">
      <p-card class="healthCards">
        <p-header style="margin-top: 10px;margin-left: 15px;">
          <i *ngIf="healthTabsOpen" class="fa fa-check-circle" style="color:green;padding-right:5px;"></i>
          <b>
            Health Sector <i style="margin-right:5px;float:right;font-size:20px;" class="fa fa-plus-circle"></i>
          </b>
        </p-header>
        <p style="font-size:10px;">For Health Sector organizations (e.g., Retirement Homes, Home Care Providers)</p>
      </p-card>
    </div>
    <div class="col-sm-1"></div>
    <div class="col-sm-3" (click)="openNonHealthTab()">
      <p-card class="healthCards">
        <p-header style="margin-top: 10px;margin-left: 15px;">
          <i *ngIf="NonHealthTabsOpen" class="fa fa-check-circle" style="color:green;padding-right:5px;"></i>
          <b>Non Health Sector <i style="margin-right:5px;float:right;font-size:20px;" class="fa fa-building"></i></b>
        </p-header>
        <p style="font-size:10px;">For Non-Health Sector organizations (e.g., SolGen, Metrolinx, Service Ontario)</p>
      </p-card>
    </div>
  </div>
  <div class="a-p-20" *ngIf="healthTabsOpen">
    <p-tabView>
      <p-tabPanel header="Health Tab1" [selected]="true">
        Content 1
      </p-tabPanel>
      <p-tabPanel header="Health Tab2">
        Content 2
      </p-tabPanel>
      <p-tabPanel header="Health Tab3">
        Content 3
      </p-tabPanel>
    </p-tabView>
  </div>
  <div class="a-p-20" *ngIf="NonHealthTabsOpen">
    <p-tabView>
      <p-tabPanel header="Non Health Tab1" [selected]="true">
        Content 1
      </p-tabPanel>
      <p-tabPanel header="Non Health Tab2">
        Content 2
      </p-tabPanel>
      <p-tabPanel header="Non Health Tab3">
        Content 3
      </p-tabPanel>
    </p-tabView>
  </div>
  <!--<div class="a-p-20" *ngIf="SelectedHealthOrg === 'HealthOrg' ">
    <a href="assets/HealthOrgSurvey.xlsx">COVID19 PPE Survey Spreadsheet Health Sector</a>
  </div>
  <div class="a-p-20" *ngIf="SelectedHealthOrg === 'NonHealthOrg' ">
    <a href="assets/SurveyExcel.xlsx">COVID19 Critical Supplies and Equipment Survey Spreadsheet Non Health Sector</a>
  </div>-->

  <div style="text-align:right;padding-top:20px;">
    <button aria-label="Close" class="a-btn a-btn-transparent a-btn-lg a-btn-gray" (click)="saveUser()">SUBMIT</button>
  </div>
</div>
