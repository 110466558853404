<div class="message-popup">
    <div class="a-modal-header text-center">
        <div class="flex-fill">
            <span class="a-h4">{{header}}</span>
        </div>
    </div>
    <div class="a-modal-body text-center">    
        {{message}}
    </div>
    <div class="a-modal-footer text-center">
      <!--<a class="a-btn a-btn-transparent a-btn-lg ok-btn" (click)="closesuccessPopup()" tabindex="0">{{ 'HOME.OK' | transloco }}</a>-->
      <button class="a-btn a-btn-transparent a-btn-lg ok-btn" (click)="closesuccessPopup()" tabindex="0" (keyup.enter)="closesuccessPopup()">
        {{ 'HOME.OK' | transloco }}
      </button>
    </div>
</div>
