import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { SidebarModule } from 'primeng/sidebar';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoConfig, TranslocoModule, TRANSLOCO_CONFIG } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataViewComponent } from './components/data-view/data-view.component';
import { EntityScreenComponent } from './components/entity-screen/entity-screen.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { GridComponent } from './components/grid/grid.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MessageComponent } from './components/message/message.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SurveyFormComponent } from './components/survey-form/survey-form.component';
import { AddRowDirective } from './directives/addrow.directive';
import { UserIdleModule } from 'angular-user-idle';
import { TranslocoRootModule } from './transloco-root.module';
import { NavigationGuard } from './service/navigation-guard.service';
import { EntitySelectedComponent } from './components/entity-selected/entity-selected.component';
import { LoginFooterComponent } from './components/login-footer/login-footer.component';
import { LogComponent } from './components/log/log.component';
import { FaqComponent } from './components/faq/faq.component';
import { MainComponent } from './components/main/main.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AllowPatternDirective } from './directives/allow-pattern.directive';
import { UtilityService } from './service/utility-service.service';
registerLocaleData(localeFr, 'fr', localeFrExtra);
import { ToastModule } from 'primeng/toast';
import { ConfrimSubmitComponent } from './components/confrim-submit/confrim-submit.component';
import { RegisterComponent } from './components/register/register.component';
import { SuccessComponent } from './components/success/success.component';
import { SharedService } from './components/sharedservice/sharedservice';
import { CheckboxModule } from 'primeng/checkbox';
import { AuthInterceptorService } from './service/auth-interceptor.service';
import { FooterComponent } from './components/footer/footer.component';
import { DowntimeComponent } from './components/downtime/downtime.component';
import { RegistrationMessageComponent } from './components/registration-message/registration-message.component';
import { InputMaskModule } from 'primeng/inputmask';
import { ErrorHandlerService } from './service/error-handler.service';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InstructionComponent } from './components/instruction/instruction.component';
import { CreateEntityComponent } from './components/create-entity/create-entity.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import { NonHealthHomeComponent } from './components/non-health-home/non-health-home.component';
import { NonHealthFAQComponent } from './components/non-health-faq/non-health-faq.component';
@NgModule({
    imports: [
        MessagesModule,
        MessageModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        TranslocoModule,
        TranslocoRootModule,
        BrowserAnimationsModule,
        TableModule,
        DialogModule,
        ButtonModule,
        CalendarModule,
        TabViewModule,
        InputTextModule,
        DropdownModule,
        InputMaskModule,
        MultiSelectModule,
        CardModule,
        AutoCompleteModule,
        PanelModule,
        DialogModule,
        TooltipModule,
        SidebarModule,
        ConfirmDialogModule,
        ToastModule,
        CheckboxModule,
        UserIdleModule.forRoot({ idle: 14400, timeout: 1, ping: 60 }),
        RadioButtonModule
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        SurveyFormComponent,
        GridComponent,
        AddRowDirective,
        DataViewComponent,
        HomeComponent,
        EntityScreenComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        MessageComponent,
        EntitySelectedComponent,
        LoginFooterComponent,
        LogComponent,
        FaqComponent,
        MainComponent,
        AllowPatternDirective,
        ConfrimSubmitComponent,
        RegisterComponent,
        SuccessComponent,
        FooterComponent,
        DowntimeComponent,
        RegistrationMessageComponent,
        InstructionComponent,
        CreateEntityComponent,
        NonHealthHomeComponent,
        NonHealthFAQComponent
    ],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: {
                reRenderOnLangChange: true,
                availableLangs: [ 'en', 'fr' ],
                listenToLangChange: true,
                defaultLang: 'en',
                fallbackLang: 'en',
                prodMode: false
            } as TranslocoConfig
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService
        },
        DatePipe,
        DialogService,
        NavigationGuard,
        ConfirmationService,
        UtilityService,
        MessageService,
        SharedService,
        Title
    ],
    entryComponents: [ MessageComponent, InstructionComponent ],
    bootstrap: [ AppComponent ]
})
export class AppModule {}
