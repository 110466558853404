import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import * as Excel from 'exceljs/dist/exceljs.min';
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { filter } from 'rxjs/operators';
import { WebAppService } from 'src/app/web-app.service';
import { empty } from 'rxjs';
import { EncrDecrService } from '../sharedservice/enc-dec.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageComponent } from '../message/message.component';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css'],
})
export class LogComponent implements OnInit {
  orgTypeEntityValues: any;
  entitycols: {
    field: string;
    header: string;
    width: string;
    sortField: string;
    contentWidth: string;
  }[];
  currentTabDetails: any;
  templateId: any;
  cols: any[];
  stockTypes: any[];
  orgDetils: any;
  stockCategory: any;
  stockCategoryData: any;
  stockItemList: any;
  imageURLB64: any;
  ExcelOrgName: any;
  ExcelOrgDate: any;
  OrgType: string;
  FilenameDate: any;
  constructor(
    private webappService: WebAppService,
    private translocoService: TranslocoService,
    private datePipe: DatePipe,
    private encrDecrService: EncrDecrService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.getBase64ImageFromUrl('../../assets/images/Ontario_Logo.png')
      .then((result) => (this.imageURLB64 = result))
      .catch((err) => console.error(err));

    // this.orgDetils = JSON.parse(sessionStorage.getItem('OrgainisationDetails'));
    this.getUserOrgDetails();
    //this.getOrganisationType();
    const selectedorgName = sessionStorage.getItem('organizationName');
    this.webappService.updateOrganisationName(selectedorgName, true);
   // this.getStockCategoryList();
  }
  getItemsDescriptions(StockCategoryId) {
    this.webappService.GetMasterItemsList().then((resp) => {
      this.stockItemList = resp;
    });
  }
  getOrganisationType(templateId) {
    // this.templateId = parseInt(
    //   this.encrDecrService.get(sessionStorage.getItem('organizationTemplate'))
    // ); // this.orgDetils.templateId;
    switch (templateId.toString()) {
      case '1': {
        this.OrgType = 'Health Sector Items List';
        break;
      }
      case '2': {
        this.OrgType = 'Non-Health Sector Items List';
        break;
      }
      case '3': {
        this.OrgType = 'Health Supply Entity Items List';
        break;
      }
      case '4': {
        this.OrgType = 'Non-Health Supply Entity Items List';
        break;
      }
      default:
        this.OrgType = 'Health Sector Items List';
    }
  }
  getStockColDef(stockTypeName, templateId) {
    const templateId1 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhand') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerConsumed') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerExpected') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyNextOrder',
        header:
          this.translocoService.translate('Grid.headerNextOrder') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedDeliveryDateStr',
        header:
          this.translocoService.translate('Grid.headerNextOrderDate') +
          this.translocoService.translate('Grid.mmddyyyy'),
      },
      {
        field: 'totalQtyInBackOrder',
        header:
          this.translocoService.translate('Grid.headerBackOrder') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedBackOrderDeliveryDateStr',
        header:
          this.translocoService.translate('Grid.headerBackOrderDate') +
          this.translocoService.translate('Grid.mmddyyyy'),
      },
    ];
    const templateId2 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhand') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerNHConsumed') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerNHExpected') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyNextOrder',
        header:
          this.translocoService.translate('Grid.headerNextOrder') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedDeliveryDateStr',
        header:
          this.translocoService.translate('Grid.headerNextOrderDate') +
          this.translocoService.translate('Grid.mmddyyyy'),
      },
      {
        field: 'totalQtyInBackOrder',
        header:
          this.translocoService.translate('Grid.headerBackOrder') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedBackOrderDeliveryDateStr',
        header:
          this.translocoService.translate('Grid.headerBackOrderDate') +
          this.translocoService.translate('Grid.mmddyyyy'),
      },
    ];
    const templateId3 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhandSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerConsumedSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerExpectedSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyNextOrder',
        header:
          this.translocoService.translate('Grid.headerNextOrderSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedDeliveryDateStr',
        header:
          this.translocoService.translate('Grid.headerNextOrderDate') +
          this.translocoService.translate('Grid.mmddyyyy'),
      },
      {
        field: 'totalQtyInBackOrder',
        header:
          this.translocoService.translate('Grid.headerBackOrderSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedBackOrderDeliveryDateStr',
        header:
          this.translocoService.translate('Grid.headerBackOrderDate') +
          this.translocoService.translate('Grid.mmddyyyy'),
      },
    ];
    const templateId4 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhandSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerConsumedNHSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerExpected') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyNextOrder',
        header:
          this.translocoService.translate('Grid.headerNextOrderSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedDeliveryDateStr',
        header:
          this.translocoService.translate('Grid.headerNextOrderDate') +
          this.translocoService.translate('Grid.mmddyyyy'),
      },
      {
        field: 'totalQtyInBackOrder',
        header:
          this.translocoService.translate('Grid.headerBackOrderSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedBackOrderDeliveryDateStr',
        header:
          this.translocoService.translate('Grid.headerBackOrderDate') +
          this.translocoService.translate('Grid.mmddyyyy'),
      },
    ];
    const expiredInventoryTemplate1 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhand') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerConsumed') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerExpected') +
          this.translocoService.translate('Grid.eaches'),
      },
    ];
    const expiredInventoryTemplate2 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhand') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerNHConsumed') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerNHExpected') +
          this.translocoService.translate('Grid.eaches'),
      },
    ];
    const expiredInventoryTemplate3 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhandSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerConsumedSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerExpectedSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
    ];
    const expiredInventoryTemplate4 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhandSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerConsumedNHSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerExpectedNHSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
    ];
    
    let cols = [];
    if (
      stockTypeName.toLowerCase().includes('expir')
    ) {
     
      switch (templateId.toString()) {
        case '1':
          cols = expiredInventoryTemplate1;
          break;
        case '2':
          cols = expiredInventoryTemplate2;
          break;
        case '3':
          cols = expiredInventoryTemplate3;
          break;
        case '4':
          cols = expiredInventoryTemplate3;
          break;
        default:
          cols = expiredInventoryTemplate1;
      }
    } else {
      switch (templateId.toString()) {
        case '1':
          cols = templateId1;
          break;
        case '2':
          cols = templateId2;
          break;
        case '3':
          cols = templateId3;
          break;
        case '4':
          cols = templateId4;
          break;
        default:
          cols = templateId1;
      }
    }
    return cols;
  }

  async getStockCategoryList(templateId) {
    await this.webappService.GetStockCategoryList(templateId).then((resp) => {
      this.currentTabDetails = resp.stockTypes[0];
      this.stockCategory = [];
      resp.stockTypes.forEach((element) => {
        var stockTypeObj = {
          stockTypeId: element.stockTypeId,
          stockTypeName: this.getCategoryName(element.stockTypeName),
          itemsList: this.stockItemList,
        };
        this.stockCategory.push(stockTypeObj);
      });
    });
  }
  getCategoryName(stockTypeName) {
    let stockNameLang: any;
    if (stockTypeName.toLowerCase().includes('regular')) {
      stockNameLang = this.translocoService.translate('Grid.regular');
    } else if (stockTypeName.toLowerCase().includes('expir')) {
      stockNameLang = this.translocoService.translate('Grid.expired');
    } else if (stockTypeName.toLowerCase().includes('pandemic')) {
      stockNameLang = this.translocoService.translate('Grid.pandemic');
    } else if (stockTypeName.toLowerCase().includes('aged')) {
      stockNameLang = this.translocoService.translate('Grid.aged');
    } else if (stockTypeName.toLowerCase().includes('donated')) {
      stockNameLang = this.translocoService.translate('Grid.donated');
    } else
    {
        stockNameLang = '';
    }
    return stockNameLang;
  }
  async getLastSurveyDataById(surveyTrackId) {
    this.webappService.GetSubmissionLogData(surveyTrackId).then(async(resp) => {
      //fetch template id of submission log
      
      var templateIdList = [];
      resp.lastSurveyData.forEach(e =>{
        templateIdList.push(e.templateId);
      });
      const distinctIdList = templateIdList.filter((n, i) => templateIdList.indexOf(n) === i);
      if(distinctIdList.length > 1){
        
        this.dialogService.open(MessageComponent, {
          showHeader: false,
          closable: false,
          data: {
              header: this.translocoService.translate('HOME.error'),
              message: this.translocoService.translate('HOME.logDownloadErr')
          }
      });
      } else {
      var templateId = distinctIdList[0];
      await this.getStockCategoryList(templateId);
      this.getOrganisationType(templateId);
      
      
      this.stockCategoryData = [];
      if(this.stockCategory){
       this.stockCategory.forEach(async (element) => {
        const stockcol = this.getStockColDef(element.stockTypeName,templateId);
        
        resp.lastSurveyData.forEach(e=>
          {
            e.stockCategoryName = (e.stockCategoryName === null)?"":e.stockCategoryName;
          })
        const StockData = resp.lastSurveyData
        .filter(
          (e) => ((e.stockCategoryId === element.stockTypeId) || (e.stockCategoryName.toString().includes(element.stockTypeName)))
        );
        
        this.webappService.GetMasterItemsList().then((resp) => {
          this.stockItemList = resp;

          StockData.forEach((element) => {
            const stockFilteredItem = this.stockItemList.filter(
              (e) => e.itemId === element.itemsId
            );
            element.itemsId = stockFilteredItem[0].itemType;
            element.description = stockFilteredItem[0].itemName;

            element.onHandQty = element.onHandQty === 0 ? 0 : element.onHandQty;
            element.qtyConsumed =
              element.qtyConsumed === 0 ? 0 : element.qtyConsumed;
            element.expectedQtyConsumed =
              element.expectedQtyConsumed === 0
                ? 0
                : element.expectedQtyConsumed;
            element.expectedQtyNextOrder =
              element.expectedQtyNextOrder === 0
                ? 0
                : element.expectedQtyNextOrder;
            element.totalQtyInBackOrder =
              element.totalQtyInBackOrder === 0
                ? 0
                : element.totalQtyInBackOrder;
            element.expectedBackOrderDeliveryDate = element.expectedBackOrderDeliveryDate
              ? new Date(element.expectedBackOrderDeliveryDate)
              : null;
            element.expectedBackOrderDeliveryDateStr = element.expectedBackOrderDeliveryDate
              ? this.datePipe.transform(
                  element.expectedBackOrderDeliveryDate,
                  'MM/dd/yyyy'
                )
              : '';
            element.expectedDeliveryDate = element.expectedDeliveryDate
              ? new Date(element.expectedDeliveryDate)
              : null;
            element.expectedDeliveryDateStr = element.expectedDeliveryDate
              ? this.datePipe.transform(
                  element.expectedDeliveryDate,
                  'MM/dd/yyyy'
                )
              : null;
          });
        });
        var stockTypeObj = {
          stockTypeId: element.stockTypeId,
          stockTypeName: element.stockTypeName,
          stockData: StockData,
          stockColExcelDef: stockcol.map((col) => ({
            header: col.header,
            key: col.field,
          })),
          stockColDef: stockcol.map((col) => ({
            title: col.header,
            dataKey: col.field,
          })),
          stockCol: stockcol,
        };
        this.stockCategoryData.push(stockTypeObj);
      }); }
    }
    });
  }
  getUserOrgDetails() {
    this.orgTypeEntityValues = [];
    this.webappService.SubmissionLog().then(async (resp) => {
      let UserOrgDetails;
      resp.organizationList.forEach((element) => {
        const lastDate = new DatePipe('en-US').transform(
          element.lastModified,
          'mediumDate'
        );
        const lastDateTime = new DatePipe('en-US').transform(
          element.lastModified,
          'hh:mm a'
        );
        let lastMod = lastDate + ' - ' + lastDateTime + ' EST';
        const timenMs = new Date(element.lastModified).getTime();
        if (element.lastModified.includes('0001')) {
          lastMod = '';
        }
        UserOrgDetails = {
          organizationId: element.organizationId,
          organizationName: element.organizationName,
          organizationAddress: element.organizationAddress,
          postalCode: element.postalCode,
          lastModified: lastMod,
          submittedBy: element.submittedBy,
          timenMs: timenMs,
          surveyTrackId: element.surveyTrackId,
          lastModifiedDate: element.lastModified,
        };

        this.orgTypeEntityValues.push(UserOrgDetails);
      });
      this.orgTypeEntityValues = this.orgTypeEntityValues.sort(
        (a, b) => b.timenMs - a.timenMs
      );

      this.columnHeaders();
      this.translocoService.events$
        .pipe(filter((e: any) => e.type === 'translationLoadSuccess'))
        .subscribe(() => {
          this.columnHeaders();
        });
      this.translocoService.langChanges$.subscribe(() => {
        this.columnHeaders();
      });
    });
  }
  columnHeaders() {
    this.entitycols = [
      {
        field: 'organizationName',
        header: this.translocoService.translate('EntityScreen.OrgName'),
        width: '19vw',
        contentWidth: '19vw',
        sortField: 'organizationName',
      },
      {
        field: 'organizationAddress',
        header: this.translocoService.translate('EntityScreen.Address'),
        width: '19vw',
        contentWidth: '19vw',
        sortField: 'organizationAddress',
      },
      {
        field: 'postalCode',
        header: this.translocoService.translate('EntityScreen.postalCode'),
        width: '9vw',
        contentWidth: '9vw',
        sortField: 'postalCode',
      },
      {
        field: 'lastModified',
        header: this.translocoService.translate('EntityScreen.LastSubmitted'),
        width: '19vw',
        contentWidth: '19vw',
        sortField: 'timenMs',
      },
      {
        field: 'submittedBy',
        header: this.translocoService.translate('EntityScreen.SubmittedBy'),
        width: '15vw',
        contentWidth: '15vw',
        sortField: 'submittedBy',
      },
      {
        field: 'DOWNLOADFILE',
        header: ' ',
        width: '8vw',
        contentWidth: '8vw',
        sortField: ' ',
      },
    ];
  }

  /**
   * To calculate the width the body
   *
   * @param {*} index
   * @returns
   * @memberof LogComponent
   */
  calculateWidth(index) {
    if (index === 4) {
      return 'calc(19vw - 17px)';
    } else {
      return '19vw';
    }
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName);
  }
  async DownloadInventoryPDF(rowData) {
    this.ExcelOrgName = rowData.organizationName;
    this.ExcelOrgDate = rowData.lastModified;
    this.FilenameDate = new DatePipe('en-US').transform(
      rowData.lastModifiedDate,
      'MM_dd_yyyy'
    );

    this.getLastSurveyDataById(rowData.surveyTrackId);

    const doc = new jsPDF('l', 'mm', [700, 1000]);
    let xCord = 15;
    let yCord = 10;
    let startYCord = 45;
    setTimeout(() => {
      this.stockCategoryData.forEach((element, index) => {
        doc.setFontType('bold');
        doc.setFontSize(15);
        doc.setTextColor(0, 0, 0);
        if (this.imageURLB64) {
          doc.addImage(this.imageURLB64, 'JPEG', xCord, 7, 26, 12);
        }
        doc.text(
          xCord + 30,
          15,
          this.translocoService.translate('HOME.HomeHeader') +
            ' - ' +
            this.OrgType
        );
        doc.setLineWidth(0.3);
        doc.setDrawColor(187, 191, 188);
        doc.line(
          xCord,
          yCord + 10,
          doc.internal.pageSize.width - xCord,
          yCord + 10
        );
        doc.setFontSize(10);
        var OrgNameWidth =
          (doc.getStringUnitWidth(this.ExcelOrgName) *
            doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - OrgNameWidth) / 2;
        doc.text(textOffset, yCord + 17, this.ExcelOrgName);
        doc.line(
          xCord,
          yCord + 20,
          doc.internal.pageSize.width - xCord,
          yCord + 20
        );
        var OrgDateWidth =
          (doc.getStringUnitWidth(this.ExcelOrgDate) *
            doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - OrgDateWidth) / 2;
        doc.text(textOffset, yCord + 27, this.ExcelOrgDate);
        doc.line(
          xCord,
          yCord + 30,
          doc.internal.pageSize.width - xCord,
          yCord + 30
        );
        doc.setTextColor(0, 0, 0);

        doc.setFontSize(14);
        doc.text(xCord, startYCord + 5, element.stockTypeName);
        doc.setFontSize(9);

        element.stockData.sort((a: any, b: any) => {
          if (!a.itemsId.toString().localeCompare(b.itemsId)) {
            return a.description.toString().localeCompare(b.description);
          }
          return a.itemsId.toString().localeCompare(b.itemsId);
        });
        doc.autoTable({
          columns: element.stockColDef,
          body: element.stockData,
          startY: startYCord + yCord,
          margin: { horizontal: 15 },
          theme: 'plain',
          styles: {
            cellPadding: 3,
            fontSize: 10,
            lineColor: [249, 249, 249],
            lineWidth: 0.5,
            tableWidth: 'auto',
            halign: 'right',
          },
          columnStyles: { 0: { halign: 'left' }, 1: { halign: 'left' } },
          headStyles: {
            halign: 'center',
            valign: 'middle',
            textColor: [93, 109, 164],
            fillColor: [255, 255, 255],
            lineWidth: 0,
          },
          alternateRowStyles: { fillColor: [249, 249, 249] },
        });

        if (element.stockData.length === 0) {
          var noDataMsg = this.translocoService.translate('Grid.NoSurvey');
          doc.setFontSize(10);
          doc.setFontType('normal');
          var noDataMsgWidth =
            (doc.getStringUnitWidth(noDataMsg) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.width - noDataMsgWidth) / 2;
          doc.text(textOffset, doc.autoTable.previous.finalY + 10, noDataMsg);
        }
        //startYCord = doc.autoTable.previous.finalY + 10;
        if (index != Number(this.stockCategoryData.length) - 1) {
          doc.addPage();
        }
      });
      doc.save(rowData.organizationName + '_' + this.FilenameDate + '.pdf');
    }, 700);
  }
  async DownloadInventoryExcel(rowData) {
    this.ExcelOrgName = rowData.organizationName;
    this.ExcelOrgDate = rowData.lastModified;
    this.FilenameDate = new DatePipe('en-US').transform(
      rowData.lastModifiedDate,
      'MM_dd_yyyy'
    );

    this.getLastSurveyDataById(rowData.surveyTrackId);

    const workbook = new Excel.Workbook();
    setTimeout(() => {
      var headerbarHeight = 33;
      var imageHeight = 38;
      var subheaderheight = 28;
      
      this.stockCategoryData.forEach((element) => {
       
        //sort table data
        element.stockData.sort((a: any, b: any) => {
          if (!a.itemsId.toString().localeCompare(b.itemsId)) {
            return a.description.toString().localeCompare(b.description);
          }
          return a.itemsId.toString().localeCompare(b.itemsId);
        });

        var worksheet = workbook.addWorksheet(
          element.stockTypeName + ' Inventory',
          {
            properties: {},
            views: [
              {
                state: 'visible',
                ySplit: 5,
                activeCell: 'A1',
                showGridLines: false,
              },
            ],
          }
        );
        worksheet.getColumn('A').width = 18;
        worksheet.getColumn('B').width = 21;
        worksheet.getColumn('C').width = 18;
        worksheet.getColumn('D').width = 18;
        worksheet.getColumn('E').width = 18;
        worksheet.getColumn('F').width = 18;
        worksheet.getColumn('G').width = 18;
        worksheet.getColumn('H').width = 18;
        worksheet.getColumn('I').width = 18;
        worksheet.getColumn('J').width = 18;
        worksheet.getColumn('K').width = 18;
        worksheet.getColumn('L').width = 18;

        let logo = workbook.addImage({
          base64: this.imageURLB64,
          extension: 'png',
        });

        worksheet.addImage(logo, {
          tl: { col: 0, row: 0 },
          ext: { width: 92, height: 40 },
        });
        worksheet.mergeCells('B1', 'I1');
        worksheet.mergeCells('A2', 'I2');
        worksheet.mergeCells('A3', 'I3');
        worksheet.getCell('B1').value =
          this.translocoService.translate('HOME.HomeHeader') +
          ' - ' +
          this.OrgType;

        let headerRow = [];
        element.stockColExcelDef.forEach((h) => {
          headerRow.push(h.header);
        });
        let data = [];
        element.stockData.forEach((tableRow) => {
          let rowData = [];
          element.stockColExcelDef.forEach((h) => {
            Object.keys(tableRow).map((key) => {
              if (key === h.key) {
                if (tableRow[key] === null) {
                  tableRow[key] = '';
                }
                rowData.push(tableRow[key]);
              }
            });
          });
          data.push(rowData);
        });

        worksheet.addRow(headerRow);
        data.forEach((tRow) => {
          worksheet.addRow(tRow);
        });
        let rowIndex = 1;
        for (rowIndex; rowIndex <= worksheet.rowCount; rowIndex++) {
          worksheet.getRow(rowIndex).alignment = {
            vertical: 'middle',
            wrapText: true,
          };
          worksheet.getRow(rowIndex).font = {
            name: 'Arial',
            family: 2,
            size: 8,
          };
          if (rowIndex > 4) {
            worksheet.getRow(rowIndex).alignment = {
              indent: 1,
              vertical: 'middle',
              horizontal: 'right',
            };
            worksheet.getRow(rowIndex).height = 25;
            worksheet.getRow(rowIndex).eachCell((cell) => {
              Object.assign(cell, {
                border: {
                  right: { style: 'dotted', color: { argb: 'A5A2A2' } },
                },
              });
            });
            if (rowIndex % 2 != 0) {
              worksheet.getRow(rowIndex).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'F9F9F9' },
              };
            }
          }
        }
        worksheet.getColumn('A').alignment = { horizontal: 'left' };
        worksheet.getColumn('B').alignment = { horizontal: 'left' };
        worksheet.getCell('B1').font = {
          name: 'Arial',
          family: 2,
          size: 13,
          bold: true,
        };
        worksheet.getRow(1).height = headerbarHeight;
        worksheet.getCell('A2').value = this.ExcelOrgName;
        worksheet.getCell('A3').value = this.ExcelOrgDate;
        worksheet.getRow(2).height = subheaderheight;
        worksheet.getRow(3).height = subheaderheight;
        worksheet.getCell('A1').alignment = {
          vertical: 'bottom',
          horizontal: 'middle',
        };
        worksheet.getCell('B1').alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getCell('A2').alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getCell('A3').alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(2).font = {
          name: 'Arial',
          family: 2,
          size: 9,
          bold: true,
        };
        worksheet.getRow(3).font = {
          name: 'Arial',
          family: 2,
          size: 8,
          bold: true,
        };

        worksheet.getRow(1).eachCell((cell) =>
          Object.assign(cell, {
            border: {
              top: { style: 'thin' },
              //left: { style: 'thin' },
              bottom: { style: 'thin' },
              //right: { style: 'thin' }
            },
          })
        );
        worksheet.getRow(2).eachCell((cell) =>
          Object.assign(cell, {
            border: {
              top: { style: 'thin' },
              //left: { style: 'thin' },
              //bottom: { style: 'thin' },
              //right: { style: 'thin' }
            },
          })
        );
        worksheet.getRow(3).eachCell((cell) =>
          Object.assign(cell, {
            border: {
              top: { style: 'thin' },
              //left: { style: 'thin' },
              bottom: { style: 'thin' },
              //right: { style: 'thin' }
            },
          })
        );
        worksheet.getRow(4).font = {
          name: 'Arial',
          family: 2,
          size: 8,
          color: { argb: '545484' },
          bold: true,
        };
        worksheet.getRow(4).alignment = {
          wrapText: true,
          vertical: 'middle',
          indent: 1,
          horizontal: 'center',
        };
        worksheet.getRow(4).height = 55;
        if (data.length === 0) {
          worksheet.mergeCells('A5', 'I5');
          worksheet.getRow(5).height = 45;
          worksheet.getCell('A5').alignment = {
            vertical: ';middle',
            horizontal: 'center',
          };
          worksheet.getCell('A5').value = this.translocoService.translate(
            'Grid.NoSurvey'
          );
          worksheet.getRow(5).font = {
            name: 'Arial',
            family: 2,
            size: 8,
          };
        }
      });
      //Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(
          blob,
          this.ExcelOrgName + '_' + this.FilenameDate + '.xlsx'
        );
      });
    }, 500);
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }
}
