import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { WebAppService } from 'src/app/web-app.service';
import { SharedService } from '../sharedservice/sharedservice';
import { EncrDecrService } from '../sharedservice/enc-dec.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  username: string;
  downTime = true;
  password: string;
  userError: boolean;
  passwordError: boolean;
  showPassword: boolean;
  showPasswordContent = false;
  UserDEtails: any;
  title = 'Demo App';
  lang = ' English';
  langStatus = false;
  passwordLengthError: boolean;
  isOrganizationSelected: any;
  validEmail: boolean;
  entercredentials: boolean;
  sectorType: any;
  locales = [
    { label: '🇺🇸 English (US)', value: 'en' },
    { label: '🇫🇷 Français', value: 'fr' },
  ];
  // the user's locale
  detectedLocale = '';
  // the default locale
  locale = this.locales[0].value;
  userDisabledMsg: boolean;
  userOnHoldMsg: boolean;
  userRejectedMsg: boolean;
  constructor(
    private translocoService: TranslocoService,
    private router: Router,
    private webappService: WebAppService,
    private sharedService: SharedService,
    private encrDecrService: EncrDecrService
  ) {
    // this.detectedLocale = this.getUsersLocale('en-US');

    // // generate a regex from the locales we support
    // const supportedRegex = new RegExp('^' + this.locales.map((l) => l.value.substring(0, 2)).join('|^'));
    // // check if the user's preferred language is supported and if so, use it.
    // if (this.detectedLocale.match(supportedRegex)) {
    //     this.updateLocale(this.detectedLocale);
    // }
    this.webappService.getDownTime().subscribe((data) => {
      this.downTime = data;
      this.sharedService.updateIsDown(false);
    });
  }
  clearErr() {
    this.passwordError = false;
    this.passwordLengthError = false;
    this.userError = false;
    this.entercredentials = false;
    this.validEmail = false;
  }
  clearFields() {
    this.username = '';
    this.password = '';
  }
  ngOnInit(): void {
    this.webappService.updateOrganisationName('', false);
    this.clearErr();
    this.clearFields();
  }
  getUsersLocale(defaultValue: string): string {
    if (
      typeof window === 'undefined' ||
      typeof window.navigator === 'undefined'
    ) {
      return defaultValue;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    return lang;
  }
  // change locale/language at runtime
  updateLocale(locale) {
    if (this.locales.some((l) => l.value === locale)) {
      this.locale = locale;
    }
    const lang = locale.substring(0, 2);
    this.translocoService.setActiveLang(lang);
  }

  login() {
    this.clearErr();
    let loginvalidation = true;
    if (
      this.username === '' ||
      this.username === undefined ||
      this.username === null
    ) {
      this.userError = true;
      loginvalidation = false;
    } else {
      this.userError = false;
      if (this.username.trim() === '') {
        this.userError = true;
        loginvalidation = false;
      } else {
        this.userError = false;
      }
    }

    if (
      this.password === '' ||
      this.password === undefined ||
      this.password === null
    ) {
      this.passwordError = true;
      loginvalidation = false;
    } else {
      this.passwordError = false;
      if (this.password.trim() === '') {
        this.passwordError = true;
        loginvalidation = false;
      } else {
        this.passwordError = false;
        if (this.password.length < 7) {
          this.passwordLengthError = true;
          loginvalidation = false;
        } else {
          this.passwordLengthError = false;
        }
      }
    }

    if (this.passwordError && this.userError) {
      this.entercredentials = true;
    } else {
      this.entercredentials = false;
    }

    if (loginvalidation) {
      this.UserDEtails = {
        userName: this.username,
        passWord: this.password,
      };
      this.logintoApplication(this.UserDEtails);
    }
  }
  async logintoApplication(UserDetails) {
    this.validEmail = false;
    this.userDisabledMsg = false;
    this.userOnHoldMsg = false;
    this.userRejectedMsg = false;
    // this.sharedService.updateAutoSave(0)
    this.webappService.LogintoSurvey(UserDetails).then(async (resp) => {
      const result = resp;
      this.sharedService.currentUser = resp;
      this.isOrganizationSelected = resp.isOrganizationSelected;
      this.sectorType = resp.userSectorType;

      sessionStorage.setItem('access_token', resp.accessToken);
      sessionStorage.setItem('userName', resp.userName);
      sessionStorage.setItem('selectedOrg', resp.isOrganizationSelected);
      sessionStorage.setItem(
        'userSectorType',
        this.encrDecrService.set(resp.userSectorType)
      );
      if (result.accessAllowed === false) {
        if (result.responseMessage.toLowerCase().includes('disabled')) {
          this.userDisabledMsg = true;
        } else if (result.responseMessage.toLowerCase().includes('hold')) {
          this.userOnHoldMsg = true;
        } else if (result.responseMessage.toLowerCase().includes('rejected')) {
          this.userRejectedMsg = true;
        } else {
          this.validEmail = true;
        }
      } else if (result.accessAllowed === true && result.isReset === 'Yes') {
        this.router.navigate(['reset-password']);
      } else if (result.accessAllowed === true && result.isReset === 'No') {
        if (this.isOrganizationSelected.toLowerCase() !== 'no') {
          if (this.sectorType === 1) {
            this.router.navigate(['home']);
          } else {
            this.router.navigate(['non-health-home']);
          }
        } else {
          this.webappService.getUserOrgDetails().subscribe(async (resp) => {
            if (resp !== undefined) {
              if (resp.organizationList.length === 1) {
                this.UpdateValidOrg(resp.organizationList[0].organizationId);
              } else {
                this.router.navigate(['entity-selected']);
              }
            }
          });
        }
      }
    });
  }

  showHidePassword(status) {
    this.showPasswordContent = status;
  }
  showForgotPassword() {
    this.router.navigate(['forget-password']);
  }
  showRegister() {
    this.router.navigate(['Register']);
  }
  showResetPassword() {
    this.router.navigate(['set-new-password']);
  }
  UpdateValidOrg(orgId) {
    this.webappService.UpdateValidOrg(orgId).then(async (resp) => {
      this.sharedService.currentUser.isOrganizationSelected = 'Yes';
      if (this.sectorType === 1) {
        this.router.navigate(['home']);
      } else {
        this.router.navigate(['non-health-home']);
      }
    });
  }
}
