import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input } from '@angular/core';
declare var $: any; // jquery reference

// Directive decorator
@Directive({ selector: '[appAllowPattern]' })
export // Directive class
class AllowPatternDirective {
    /*Decimal Regex*/
    private regex = new RegExp(/^(\d{1,7})(\.(\d{1,6})|(\.?))$/g); /*/^[0-9]+(\.[0-9]*){0,1}$/*/
    constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {
        this.el.nativeElement.addEventListener('change', (e) => {
            this.checkValueMatchingWithRegExp(e, e.target.value);
        });
        this.el.nativeElement.addEventListener('blur', (e) => {
            this.checkValueMatchingWithRegExp(e, e.target.value);
        });
    }
    /**
     * On keyboard event
     *
     * @param {*} $event
     * @returns
     * @memberof AllowPatternDirective
     */
    @HostListener('keypress', [ '$event' ])
    onkeypress($event) {
        const keyCode = $event.which || $event.keyCode;

        const keyCodeChar = String.fromCharCode(keyCode);
        const checkMaxlength = this.el.nativeElement.maxLength || '';
        const attrpattern = this.el.nativeElement.getAttribute('allow-pattern');
        const dataPattern = this.el.nativeElement.getAttribute('data-allow-pattern');
        const global = this.el.nativeElement.getAttribute('global');
        const globalDataPattern = this.el.nativeElement.getAttribute('data-global');
        let pattern = dataPattern ? dataPattern : attrpattern;
        let glPattern = globalDataPattern ? globalDataPattern : global;
        glPattern = glPattern ? glPattern : 'g';
        pattern = pattern ? pattern : '.*';
        const isDecimal = this.el.nativeElement.getAttribute('decimal') || null;
        if (isDecimal && isDecimal === 1) {
            const current: string = this.el.nativeElement.value;
            const ele: HTMLInputElement = <HTMLInputElement>$event.target;
            const next: string =
                current.substr(0, ele.selectionStart) + keyCodeChar + current.substr(ele.selectionStart);
            if (next && !next.match(this.regex)) {
                $event.preventDefault();
            }
        } else {
            if (!keyCodeChar.match(new RegExp(pattern, glPattern))) {
                $event.preventDefault();
                return false;
            }
        }
    }

    @HostListener('document:paste', [ '$event' ])
    onGlobalPaste(event) {
        const text = event.clipboardData.getData('Text');
        if (event.target.hasAttribute('allow-pattern')) {
            this.checkPasteData(event);
        }
        event.clipboardData.setData('text/plain', '');
    }

    onLocalPaste(event) {
        const text = event.clipboardData.getData('Text');
        if (event.target.hasAttribute('allow-pattern')) {
            this.checkPasteData(event);
        }
        event.clipboardData.setData('text/plain', '');
    }

    private checkPasteData(event: any) {
        const text = event.clipboardData.getData('Text');
        this.checkValueMatchingWithRegExp(event, text);
    }
    /**
     * Validating the data with regexp
     *
     * @param {*} event
     * @param {*} text
     * @returns
     * @memberof AllowPatternDirective
     */
    checkValueMatchingWithRegExp(event, text) {
        const splitedText = text.split('');
        const isDecimal = this.el.nativeElement.getAttribute('decimal') || null;

        const pattern = this.el.nativeElement.getAttribute('allow-pattern');
        if (isDecimal && isDecimal === 1) {
            if (text && !String(text).match(this.regex)) {
                event.preventDefault();
            }
        } else {
            for (let i = 0; i < splitedText.length; i++) {
                if (!splitedText[i].match(new RegExp(pattern, 'gi'))) {
                    event.preventDefault();
                    return false;
                }
            }
        }
    }
}
