import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../sharedservice/sharedservice';
import { environment } from 'src/environments/environment';
import { WebAppService } from 'src/app/web-app.service';
import { EncrDecrService } from '../sharedservice/enc-dec.service';
import { MessageComponent } from '../message/message.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslocoService } from '@ngneat/transloco';
@Component({
  selector: 'app-non-health-home',
  templateUrl: './non-health-home.component.html',
  styleUrls: ['./non-health-home.component.css']
})
export class NonHealthHomeComponent implements OnInit {
  healthDoc: any;
  nonHealthDoc: any;
  supplyDoc: any;
  showNavPopup: boolean;
  nonHealthFr: any;

  constructor(private router: Router, private sharedService: SharedService,
              private webappService: WebAppService, private encrDecrService: EncrDecrService,
              private dialogService: DialogService,
              private translocoService: TranslocoService) {
    if (environment.environments.local.baseUrl === window.location.origin) {
      this.healthDoc = environment.environments.local.documentLink.home.health;
      this.nonHealthDoc = environment.environments.local.documentLink.home.nonHealth;
      this.supplyDoc = environment.environments.local.documentLink.home.nonHealthSupply;
      this.nonHealthFr = environment.environments.local.documentLink.home.nonHealthFr;
    }
    if (environment.environments.dev.baseUrl === window.location.origin) {
      this.healthDoc = environment.environments.dev.documentLink.home.health;
      this.nonHealthDoc = environment.environments.dev.documentLink.home.nonHealth;
      this.supplyDoc = environment.environments.dev.documentLink.home.nonHealthSupply;
      this.nonHealthFr = environment.environments.dev.documentLink.home.nonHealthFr;
    }

    if (environment.environments.qa.baseUrl === window.location.origin) {
      this.healthDoc = environment.environments.qa.documentLink.home.health;
      this.nonHealthDoc = environment.environments.qa.documentLink.home.nonHealth;
      this.supplyDoc = environment.environments.qa.documentLink.home.nonHealthSupply;
      this.nonHealthFr = environment.environments.qa.documentLink.home.nonHealthFr;
    }
    if (environment.environments.prod.baseUrl === window.location.origin) {
      this.healthDoc = environment.environments.prod.documentLink.home.health;
      this.nonHealthDoc = environment.environments.prod.documentLink.home.nonHealth;
      this.supplyDoc = environment.environments.prod.documentLink.home.nonHealthSupply;
      this.nonHealthFr = environment.environments.prod.documentLink.home.nonHealthFr;
    }

    if (environment.environments.stage.baseUrl === window.location.origin) {
      this.healthDoc = environment.environments.stage.documentLink.home.health;
      this.nonHealthDoc = environment.environments.stage.documentLink.home.nonHealth;
      this.supplyDoc = environment.environments.stage.documentLink.home.nonHealthSupply;
      this.nonHealthFr = environment.environments.stage.documentLink.home.nonHealthFr;
    }
    if (environment.environments.live.baseUrl === window.location.origin) {
      this.healthDoc = environment.environments.live.documentLink.home.health;
      this.nonHealthDoc = environment.environments.live.documentLink.home.nonHealth;
      this.supplyDoc = environment.environments.live.documentLink.home.nonHealthSupply;
      this.nonHealthFr = environment.environments.live.documentLink.home.nonHealthFr;
    }
  }
  environment;

  ngOnInit(): void {
    this.environment = environment;
    this.sharedService.updateIdleTimeout();
  }
  /**
* Navigate to entity screen
*
* @memberof HomeComponent
*/
  Start() {
    this.webappService.getUserOrgDetails().subscribe(async (resp) => {
      if (resp !== undefined) {
        if (resp.organizationList.length === 1) {
          this.navigateToInventory(resp.organizationList[0]);
        }
        else {
          this.router.navigate(['entity-screen']);
        }
      }
    });
  }
  navigateToInventory(selectedOrganizationType) {

    // sessionStorage.setItem('OrgainisationDetails', JSON.stringify(selectedOrganizationType));
    this.webappService.updateOrganisationName(selectedOrganizationType.organizationName, true);
    sessionStorage.setItem('organizationName', selectedOrganizationType.organizationName);
    sessionStorage.setItem('organizationTemplate', this.encrDecrService.set(selectedOrganizationType.templateId));
    this.webappService.GetOrgAccessToken(selectedOrganizationType.organizationId).then(async (resp) => {
      this.showNavPopup = false;
     
      if ( resp !== undefined)
      {
      if (resp.userStatus === 'Active' && resp.orgStatus === 'Active' && resp.accessStatus === 'Active' ) {
        sessionStorage.setItem('access_token', resp.orgToken);
        if (selectedOrganizationType) {
          this.router.navigate(['inventory']);
        }
      } else if (resp.accessStatus !== 'Active')
      {
        const popup = this.dialogService.open(MessageComponent, {
          showHeader: false,
          closable: false,
          width: '40%',
          data: {
            header: this.translocoService.translate('Grid.Warning'),
            message: this.translocoService.translate('HOME.accessDisableMsg'),
          },
        });
        popup.onClose.subscribe((data) => {
        this.router.navigate(['non-health-home']);
       });
      } else if (resp.userStatus !== 'Active')
      {
        const popup = this.dialogService.open(MessageComponent, {
          showHeader: false,
          closable: false,
          width: '40%',
          data: {
            header: this.translocoService.translate('Grid.Warning'),
            message: this.translocoService.translate('HOME.userDisabledMsg'),
          },
        });
        popup.onClose.subscribe((data) => {
          sessionStorage.clear();
          this.router.navigate(['Login']);
        });
      } else if (resp.orgStatus !== 'Active')
      {
        const popup = this.dialogService.open(MessageComponent, {
          showHeader: false,
          closable: false,
          width: '40%',
          data: {
            header: this.translocoService.translate('Grid.Warning'),
            message: this.translocoService.translate('HOME.orgDisableMsg'),
          },
        });
        popup.onClose.subscribe((data) => {
          this.router.navigate(['non-health-home']);
         });
      }
    }
  });
  }

  navigateToHelp() {
    this.router.navigate(['non-health-faq']);

  }

  displayPopup()
  {
      this.showNavPopup = true;
  }
  cancelPopup()
  {
     this.showNavPopup = false;
  }}
