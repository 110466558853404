import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { interval, Observable, forkJoin, Subject, timer } from 'rxjs';
import { take, filter, takeUntil } from 'rxjs/operators';
import { WebAppService } from 'src/app/web-app.service';
import { UtilityService } from '../../service/utility-service.service';
import { InstructionComponent } from '../instruction/instruction.component';
import { SharedService } from '../sharedservice/sharedservice';
import { environment } from 'src/environments/environment';
import { MessageComponent } from '../message/message.component';
import { saveAs } from 'file-saver';
import { EncrDecrService } from '../sharedservice/enc-dec.service';

declare var $;

export interface Item {
    id: number;
    type: string;
    description: string;
    inventory: number;
    quantityConsumed: number;
    received: number;

    expectedQuantityConsumed: number;
    nextDelivery: Date;
    nextDeliveryAmount: number;
    backorder: Date;
    backorderAmount: number;
    priority: number;
    gridId: number;
    isEditable: boolean;
}
export interface expiredItem {
    id: number;
    type: string;
    description: string;
    inventory: number;
    quantityConsumed: number;
    isEditable: boolean;
}
export interface Organization {
    name: string;
    code: number;
}
@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: [ './grid.component.css' ]
})
export class GridComponent implements OnInit, OnDestroy {
    templateId: any;
    height = 'calc(100vh - 575px)';
    idCounter = 0;
    lastSurveyCounter = 0;
    alreadySavedLength = 0;
    isSubmitDisabled;
    healthDoc: string;
    healthSupplyDoc: string;
    nonHealthEnDoc: string;
    nonHealthSupplyEnDoc: string;
    healthDocCsv: string;
    healthSupplyDocCsv: string;
    nonHealthEnDocCsv: string;
    nonHealthSupplyEnDocCsv: string;
    autoSaveTimer;
    autoSaveDate;
    autoSaveTime;
    showIndigenousPopup: boolean;
    ngSubject: Subject<any> = new Subject<any>();
    indigenousValue: string;
    indigenousValueError: boolean;
    sectorType: any;
    isloadAutoSaveData: boolean;
    isloadSurveyData: boolean;
    loadAutoSaveDateTime: any;
    @ViewChildren('pTable') pTable: QueryList<Table>;
    allItemDescription: any[];
    healthDocFr: string;
    healthDocCsvFr: string;
    nonHealthDocFr: string;
    healthSupplyDocFr: string;
    nonHealthSupplyDocFr: string;
    nonHealthDocCsvFr: string;
    healthSupplyDocCsvFr: string;
    nonHealthSupplyDocCsvFr: string;
    apiStockTypes: any;
    
    constructor(
        private webappService: WebAppService,
        private router: Router,
        private utilService: UtilityService,
        private confirmationService: ConfirmationService,
        private translocoService: TranslocoService,
        public service: SharedService,
        private dialogService: DialogService,
        private datePipe: DatePipe,
        private encrDecrService: EncrDecrService
    ) {
        this.service.isAutoSave.pipe(takeUntil(this.ngSubject)).subscribe((data) => {
            if (data) {
                this.autoSaveData();
            }
        });

        if (environment.environments.local.baseUrl === window.location.origin) {
            this.isSubmitDisabled = environment.environments.local.inventorySubmitCheck;
            this.healthDoc = environment.environments.local.documentLink.inventory.health;
            this.healthSupplyDoc = environment.environments.local.documentLink.inventory.healthSupply;
            this.nonHealthEnDoc = environment.environments.local.documentLink.inventory.nonHealth;
            this.nonHealthSupplyEnDoc = environment.environments.local.documentLink.inventory.nonHealthSupply;
            this.healthDocCsv = environment.environments.local.documentLink.inventory.healthCsv;
            this.healthSupplyDocCsv = environment.environments.local.documentLink.inventory.healthSupplyCsv;
            this.nonHealthEnDocCsv = environment.environments.local.documentLink.inventory.nonHealthCsv;
            this.nonHealthSupplyEnDocCsv = environment.environments.local.documentLink.inventory.nonHealthSupplyCsv;
            this.healthDocFr = environment.environments.local.documentLink.inventory.healthFr;
            this.healthDocCsvFr = environment.environments.local.documentLink.inventory.healthCsvFr;
            this.nonHealthDocFr = environment.environments.local.documentLink.inventory.nonHealthFr;
            this.healthSupplyDocFr = environment.environments.local.documentLink.inventory.healthSupplyFr;
            this.nonHealthSupplyDocFr = environment.environments.local.documentLink.inventory.nonHealthSupplyFr;
            this.nonHealthDocCsvFr = environment.environments.local.documentLink.inventory.nonHealthCsvFr;
            this.healthSupplyDocCsvFr = environment.environments.local.documentLink.inventory.healthSupplyCsvFr;
            this.nonHealthSupplyDocCsvFr = environment.environments.local.documentLink.inventory.nonHealthSupplyCsvFr;
        }
        if (environment.environments.dev.baseUrl === window.location.origin) {
            this.isSubmitDisabled = environment.environments.dev.inventorySubmitCheck;
            this.healthDoc = environment.environments.dev.documentLink.inventory.health;
            this.healthSupplyDoc = environment.environments.dev.documentLink.inventory.healthSupply;
            this.nonHealthEnDoc = environment.environments.dev.documentLink.inventory.nonHealth;
            this.nonHealthSupplyEnDoc = environment.environments.dev.documentLink.inventory.nonHealthSupply;
            this.healthDocCsv = environment.environments.dev.documentLink.inventory.healthCsv;
            this.healthSupplyDocCsv = environment.environments.dev.documentLink.inventory.healthSupplyCsv;
            this.nonHealthEnDocCsv = environment.environments.dev.documentLink.inventory.nonHealthCsv;
            this.nonHealthSupplyEnDocCsv = environment.environments.dev.documentLink.inventory.nonHealthSupplyCsv;
            this.healthDocFr = environment.environments.dev.documentLink.inventory.healthFr;
            this.healthDocCsvFr = environment.environments.dev.documentLink.inventory.healthCsvFr;
            this.nonHealthDocFr = environment.environments.dev.documentLink.inventory.nonHealthFr;
            this.healthSupplyDocFr = environment.environments.dev.documentLink.inventory.healthSupplyFr;
            this.nonHealthSupplyDocFr = environment.environments.dev.documentLink.inventory.nonHealthSupplyFr;
            this.nonHealthDocCsvFr = environment.environments.dev.documentLink.inventory.nonHealthCsvFr;
            this.healthSupplyDocCsvFr = environment.environments.dev.documentLink.inventory.healthSupplyCsvFr;
            this.nonHealthSupplyDocCsvFr = environment.environments.dev.documentLink.inventory.nonHealthSupplyCsvFr;
        }

        if (environment.environments.qa.baseUrl === window.location.origin) {
            this.isSubmitDisabled = environment.environments.qa.inventorySubmitCheck;
            this.healthDoc = environment.environments.qa.documentLink.inventory.health;
            this.healthSupplyDoc = environment.environments.qa.documentLink.inventory.healthSupply;
            this.nonHealthEnDoc = environment.environments.qa.documentLink.inventory.nonHealth;
            this.nonHealthSupplyEnDoc = environment.environments.qa.documentLink.inventory.nonHealthSupply;
            this.healthDocCsv = environment.environments.qa.documentLink.inventory.healthCsv;
            this.healthSupplyDocCsv = environment.environments.qa.documentLink.inventory.healthSupplyCsv;
            this.nonHealthEnDocCsv = environment.environments.qa.documentLink.inventory.nonHealthCsv;
            this.nonHealthSupplyEnDocCsv = environment.environments.qa.documentLink.inventory.nonHealthSupplyCsv;
            this.healthDocFr = environment.environments.qa.documentLink.inventory.healthFr;
            this.healthDocCsvFr = environment.environments.qa.documentLink.inventory.healthCsvFr;
            this.nonHealthDocFr = environment.environments.qa.documentLink.inventory.nonHealthFr;
            this.healthSupplyDocFr = environment.environments.qa.documentLink.inventory.healthSupplyFr;
            this.nonHealthSupplyDocFr = environment.environments.qa.documentLink.inventory.nonHealthSupplyFr;
            this.nonHealthDocCsvFr = environment.environments.qa.documentLink.inventory.nonHealthCsvFr;
            this.healthSupplyDocCsvFr = environment.environments.qa.documentLink.inventory.healthSupplyCsvFr;
            this.nonHealthSupplyDocCsvFr = environment.environments.qa.documentLink.inventory.nonHealthSupplyCsvFr;
        }
        if (environment.environments.prod.baseUrl === window.location.origin) {
            this.isSubmitDisabled = environment.environments.prod.inventorySubmitCheck;
            this.healthDoc = environment.environments.prod.documentLink.inventory.health;
            this.healthSupplyDoc = environment.environments.prod.documentLink.inventory.healthSupply;
            this.nonHealthEnDoc = environment.environments.prod.documentLink.inventory.nonHealth;
            this.nonHealthSupplyEnDoc = environment.environments.prod.documentLink.inventory.nonHealthSupply;
            this.healthDocCsv = environment.environments.prod.documentLink.inventory.healthCsv;
            this.healthSupplyDocCsv = environment.environments.prod.documentLink.inventory.healthSupplyCsv;
            this.nonHealthEnDocCsv = environment.environments.prod.documentLink.inventory.nonHealthCsv;
            this.nonHealthSupplyEnDocCsv = environment.environments.prod.documentLink.inventory.nonHealthSupplyCsv;
            this.healthDocFr = environment.environments.prod.documentLink.inventory.healthFr;
            this.healthDocCsvFr = environment.environments.prod.documentLink.inventory.healthCsvFr;
            this.nonHealthDocFr = environment.environments.prod.documentLink.inventory.nonHealthFr;
            this.healthSupplyDocFr = environment.environments.prod.documentLink.inventory.healthSupplyFr;
            this.nonHealthSupplyDocFr = environment.environments.prod.documentLink.inventory.nonHealthSupplyFr;
            this.nonHealthDocCsvFr = environment.environments.prod.documentLink.inventory.nonHealthCsvFr;
            this.healthSupplyDocCsvFr = environment.environments.prod.documentLink.inventory.healthSupplyCsvFr;
            this.nonHealthSupplyDocCsvFr = environment.environments.prod.documentLink.inventory.nonHealthSupplyCsvFr;
        }

        if (environment.environments.stage.baseUrl === window.location.origin) {
            this.isSubmitDisabled = environment.environments.stage.inventorySubmitCheck;
            this.healthDoc = environment.environments.stage.documentLink.inventory.health;
            this.healthSupplyDoc = environment.environments.stage.documentLink.inventory.healthSupply;
            this.nonHealthEnDoc = environment.environments.stage.documentLink.inventory.nonHealth;
            this.nonHealthSupplyEnDoc = environment.environments.stage.documentLink.inventory.nonHealthSupply;
            this.healthDocCsv = environment.environments.stage.documentLink.inventory.healthCsv;
            this.healthSupplyDocCsv = environment.environments.stage.documentLink.inventory.healthSupplyCsv;
            this.nonHealthEnDocCsv = environment.environments.stage.documentLink.inventory.nonHealthCsv;
            this.nonHealthSupplyEnDocCsv = environment.environments.stage.documentLink.inventory.nonHealthSupplyCsv;
            this.healthDocFr = environment.environments.stage.documentLink.inventory.healthFr;
            this.healthDocCsvFr = environment.environments.stage.documentLink.inventory.healthCsvFr;
            this.nonHealthDocFr = environment.environments.stage.documentLink.inventory.nonHealthFr;
            this.healthSupplyDocFr = environment.environments.stage.documentLink.inventory.healthSupplyFr;
            this.nonHealthSupplyDocFr = environment.environments.stage.documentLink.inventory.nonHealthSupplyFr;
            this.nonHealthDocCsvFr = environment.environments.stage.documentLink.inventory.nonHealthCsvFr;
            this.healthSupplyDocCsvFr = environment.environments.stage.documentLink.inventory.healthSupplyCsvFr;
            this.nonHealthSupplyDocCsvFr = environment.environments.stage.documentLink.inventory.nonHealthSupplyCsvFr;
        }
        if (environment.environments.live.baseUrl === window.location.origin) {
            this.isSubmitDisabled = environment.environments.live.inventorySubmitCheck;
            this.healthDoc = environment.environments.live.documentLink.inventory.health;
            this.healthSupplyDoc = environment.environments.live.documentLink.inventory.healthSupply;
            this.nonHealthEnDoc = environment.environments.live.documentLink.inventory.nonHealth;
            this.nonHealthSupplyEnDoc = environment.environments.live.documentLink.inventory.nonHealthSupply;
            this.healthDocCsv = environment.environments.live.documentLink.inventory.healthCsv;
            this.healthSupplyDocCsv = environment.environments.live.documentLink.inventory.healthSupplyCsv;
            this.nonHealthEnDocCsv = environment.environments.live.documentLink.inventory.nonHealthCsv;
            this.nonHealthSupplyEnDocCsv = environment.environments.live.documentLink.inventory.nonHealthSupplyCsv;
            this.healthDocFr = environment.environments.live.documentLink.inventory.healthFr;
            this.healthDocCsvFr = environment.environments.live.documentLink.inventory.healthCsvFr;
            this.nonHealthDocFr = environment.environments.live.documentLink.inventory.nonHealthFr;
            this.healthSupplyDocFr = environment.environments.live.documentLink.inventory.healthSupplyFr;
            this.nonHealthSupplyDocFr = environment.environments.live.documentLink.inventory.nonHealthSupplyFr;
            this.nonHealthDocCsvFr = environment.environments.live.documentLink.inventory.nonHealthCsvFr;
            this.healthSupplyDocCsvFr = environment.environments.live.documentLink.inventory.healthSupplyCsvFr;
            this.nonHealthSupplyDocCsvFr = environment.environments.live.documentLink.inventory.nonHealthSupplyCsvFr;
        }
    }
    @ViewChildren('globalSearch') userInput: QueryList<ElementRef>;

    todayDate = new Date();
    oranizationTypes: any;
    LHINOptions: any;
    visibleSidebar;
    submitDisabled = false;
    FirstNameErr: boolean;
    FirstName: any;
    LastName: any;
    LastNameErr: boolean;
    RespondentTitle: any;
    RespondentTitleErr: boolean;
    EmailId: any;
    EmailErr: boolean;
    OrgName: any;
    OrgNameErr: boolean;
    validationSucces: boolean;
    OrganizationAddress: any;
    OrganizationAddressErr: boolean;
    OrganizationCity: any;
    OrganizationCityErr: boolean;
    OrganizationPostal: any;
    OrganizationPostalErr: boolean;
    msgs = [];
    selectedLHIN: any;
    selectedLHINErr: boolean;
    selectedorganizationType: any;
    selectedorganizationTypeErr: boolean;
    blocked: boolean;
    healthTabsOpen: boolean;
    NonHealthTabsOpen: boolean;
    healthSupplyOpen: boolean;
    selectedorgName: any;
    currentTab = 0;
    currentTabDetails;
    // Grid Variables
    lastSurveryData: any;
    firstTime = false;
    types: any;
    descriptions: any;
    deleteColumnVisible: boolean;
    organization: any;
    typeDescriptions: any;
    newlyAddedRow: any[] = [];
    xVisible = false;
    cols = [];
    // hardcoded value. to be changed
    StockCategoryId = 6;
    cloneStockData = [];
    currentStockTypes;
    stockTypes = [];
    lastSurveyStockTypes = [];
    submitTimer;
    display: boolean;
    fileURL: any;
    // Grid Functions Start
    getColsFor() {
        const templateId1 = [
            {
                field: 'type',
                header:
                    '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerType') + '</span>',
                width: '10%'
            },
            {
                field: 'description',
                header:
                    '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerDesc') + '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipDescription'),
                width: '20%'
            },
            {
                field: 'onHandQty',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerOnhand') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipOnhand'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'qtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerConsumed') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipConsumed'),
                width: '10%',
                type: 'number'
            },
            {
                field: 'expectedQtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerExpected') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipExpected'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'expectedQtyNextOrder',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerNextOrder') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipNextOrder'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'expectedDeliveryDate',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerNextOrderDate') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipNextOrderDate'),
                width: '10%',
                type: 'date'
            },
            {
                field: 'totalQtyInBackOrder',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerBackOrder') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipBackOrder'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'expectedBackOrderDeliveryDate',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerBackOrderDate') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipBackOrderDate'),
                width: '10%',
                type: 'date'
            }
        ];
        //
        const templateId2 = [          
            {
                field: 'type',
                header: '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerType'),
                width: '10%'
            },
            {
                field: 'description',
                header: '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerDesc'),
                tooltip: this.translocoService.translate('Grid.tooltipDescription'),
                width: '20%'
            },
            {
                field: 'onHandQty',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerOnhand') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipOnhand'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'qtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerNHConsumed') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipheaderNHConsumed'),
                width: '10%',
                type: 'number'
            },
            {
                field: 'expectedQtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerNHExpected') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipExpected'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'expectedQtyNextOrder',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerNextOrder') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipNextOrder'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'expectedDeliveryDate',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerNextOrderDate') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipNextOrderDate'),
                width: '10%',
                type: 'date'
            },
            {
                field: 'totalQtyInBackOrder',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerBackOrder') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipBackOrder'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'expectedBackOrderDeliveryDate',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerBackOrderDate') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipBackOrderDate'),
                width: '10%',
                type: 'date'
            }
        ];
        const templateId3 = [
            {
                field: 'type',
                header:
                    '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerType') + '</span>',

                width: '10%'
            },
            {
                field: 'description',
                header:
                    '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerDesc') + '</span>',
                    tooltip: this.translocoService.translate('Grid.tooltipDescription'),
                width: '20%'
            },
            {
                field: 'onHandQty',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerOnhandSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipOnhandSupplies'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'qtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerConsumedSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipConsumedSupplies'),
                width: '10%',
                type: 'number'
            },
            {
                field: 'expectedQtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerExpectedSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipExpectedSupplies'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'expectedQtyNextOrder',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.NextOrderHealthSupply') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipNextOrderSupplies'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'expectedDeliveryDate',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerNextOrderDateSupplies') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipNextOrderDateSupplies'),
                width: '10%',
                type: 'date'
            },
            {
                field: 'totalQtyInBackOrder',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerBackOrderSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipBackOrderSupplies'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'expectedBackOrderDeliveryDate',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerBackOrderDate') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipBackOrderDateSupplies'),
                width: '10%',
                type: 'date'
            }
        ];
        const templateId4 = [
            {
                field: 'type',
                header:
                    '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerType') + '</span>',

                width: '10%'
            },
            {
                field: 'description',
                header:
                    '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerDesc') + '</span>',
                    tooltip: this.translocoService.translate('Grid.tooltipDescription'),
                width: '20%'
            },
            {
                field: 'onHandQty',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerOnhandSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipOnhandSupplies'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'qtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerConsumedNHSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipConsumedSupplies'),
                width: '10%',
                type: 'number'
            },
            {
                field: 'expectedQtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerExpectedNHSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipExpectedSupplies'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'expectedQtyNextOrder',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerNextOrderSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipNextOrderSupplies'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'expectedDeliveryDate',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerNextOrderDateSupplies') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipNextOrderDateSupplies'),
                width: '10%',
                type: 'date'
            },
            {
                field: 'totalQtyInBackOrder',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerBackOrderSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipBackOrderDateSupplies'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'expectedBackOrderDeliveryDate',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerBackOrderDate') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipBackOrderSupplies'),
                width: '10%',
                type: 'date'
            }
        ];
        const expiredInventoryTemplate1 = [
            {
                field: 'type',
                header:
                    '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerType') + '</span>',

                width: '10%'
            },
            {
                field: 'description',
                header:
                    '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerDesc') + '</span>',
                    tooltip: this.translocoService.translate('Grid.tooltipDescription'),
                width: '20%'
            },
            {
                field: 'onHandQty',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerOnhand') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipOnhand'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'qtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerConsumed') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipConsumed'),
                width: '10%',
                type: 'number'
            },
            {
                field: 'expectedQtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerExpected') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipExpected'),
                width: '9%',
                type: 'number'
            }
        ];
        const expiredInventoryTemplate2 = [
            {
                field: 'type',
                header: '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerType'),
                width: '10%'
            },
            {
                field: 'description',
                header: '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerDesc'),
                tooltip: this.translocoService.translate('Grid.tooltipDescription'),
                width: '20%'
            },
            {
                field: 'onHandQty',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerOnhand') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipOnhand'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'qtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerNHConsumed') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipheaderNHConsumed'),
                width: '10%',
                type: 'number'
            },
            {
                field: 'expectedQtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerNHExpected') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipExpected'),
                width: '9%',
                type: 'number'
            }
        ];
        const expiredInventoryTemplate3 = [
            {
                field: 'type',
                header:
                    '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerType') + '</span>',
                width: '10%'
            },
            {
                field: 'description',
                header:
                    '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerDesc') + '</span>',
                    tooltip: this.translocoService.translate('Grid.tooltipDescription'),
                    width: '20%'
            },
            {
                field: 'onHandQty',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerOnhandSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipOnhandSupplies'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'qtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerConsumedSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipConsumedSupplies'),
                width: '10%',
                type: 'number'
            },
            {
                field: 'expectedQtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerExpectedSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipExpectedSupplies'),
                width: '9%',
                type: 'number'
            }
        ];
        const expiredInventoryTemplate4 = [
            {
                field: 'type',
                header:
                    '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerType') + '</span>',

                width: '10%'
            },
            {
                field: 'description',
                header:
                    '<span class="grid-bold-header">' + this.translocoService.translate('Grid.headerDesc') + '</span>',
                    tooltip: this.translocoService.translate('Grid.tooltipDescription'),
                width: '20%'
            },
            {
                field: 'onHandQty',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerOnhandSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipOnhandSupplies'),
                width: '9%',
                type: 'number'
            },
            {
                field: 'qtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerConsumedNHSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipConsumedSupplies'),
                width: '10%',
                type: 'number'
            },
            {
                field: 'expectedQtyConsumed',
                header:
                    '<span class="grid-bold-header">' +
                    this.translocoService.translate('Grid.headerExpectedNHSupplies') +
                    '</span><br><span class="grid-grey-text">' +
                    this.translocoService.translate('Grid.eaches') +
                    '</span>',
                tooltip: this.translocoService.translate('Grid.tooltipExpectedSupplies'),
                width: '9%',
                type: 'number'
            }
        ];

        let cols = [];
        if (this.currentTabDetails) {
            if (this.currentTabDetails.stockTypeName.toLowerCase().includes('expir')) {
                switch (this.templateId.toString()) {
                    case '1':
                        cols = expiredInventoryTemplate1;
                        break;
                    case '2':
                        cols = expiredInventoryTemplate2;
                        break;
                    case '3':
                        cols = expiredInventoryTemplate3;
                        break;
                    case '4':
                        cols = expiredInventoryTemplate3;
                        break;
                    default:
                        cols = expiredInventoryTemplate1;
                }
            } else {
                switch (this.templateId.toString()) {
                    case '1':
                        cols = templateId1;
                        break;
                    case '2':
                        cols = templateId2;
                        break;
                    case '3':
                        cols = templateId3;
                        break;
                    case '4':
                        cols = templateId4;
                        break;
                    default:
                        cols = templateId1;
                }
            }
            const deleteCol = { field: 'delete', header: '', width: '5%' };
            const editCol = { field: 'edit', header: '', width: '10%' };
            cols.unshift(editCol)
            cols.push(deleteCol);
            this.cols = cols;
        }
    }

    /**
   * Fetch the item description
   *
   * @param {*} StockCategoryId
   * @param {boolean} [isLastSruveyData=false]
   * @param {*} [stockTypes={}]
   * @memberof GridComponent
   */
    getItemsDescriptions(StockCategoryId, isLastSruveyData = false, stockTypes = {}) {
        this.webappService.GetItemsList(StockCategoryId).then((resp) => {
            this.typeDescriptions = Array.from(new Set(resp.itemsList.map((e) => JSON.stringify(e)))).map((eel: any) =>
                JSON.parse(eel)
            );
            this.types = Array.from(new Set(this.typeDescriptions.map((el) => el.itemType)));
            this.types = this.types.map((el) => {                
                return { label: el, value: el };
            });            
            this.types = this.types.sort((a, b) => (a.value > b.value ? 1 : -1));
            this.types.unshift({
                label: this.translocoService.translate('Grid.PleaseSelect'),
                value: 'Please Select',
                tabindex:'0'
            });
            if (isLastSruveyData) {
                this.currentStockTypes = stockTypes;

                this.getLastSurveyData(stockTypes);
                this.translocoService.events$
                    .pipe(filter((e: any) => e.type === 'translationLoadSuccess'), takeUntil(this.ngSubject))
                    .subscribe(() => {
                        if (this.firstTime) {
                            this.autoSaveData(true, stockTypes);
                        } else {
                            this.getLastSurveyData(stockTypes);
                        }
                    });
                this.translocoService.langChanges$.pipe(takeUntil(this.ngSubject)).subscribe(() => {
                    if (this.firstTime) {
                        this.autoSaveData(true, stockTypes);
                    } else {
                        this.getLastSurveyData(stockTypes);
                    }
                });
            }
        });
    }

    getNotNullValues() {
        let listDescription = JSON.parse(JSON.stringify(this.typeDescriptions));
        const description = this.stockTypes[this.currentTab].stockData.map(e => ({description: e.description, type: e.type}));
        for (let i = 0; i < listDescription.length; i++) {
            const e = listDescription[i];
            const findDesc = description.find((el) => e.itemName === el.description && e.itemType === el.type);


            if (findDesc) {
                listDescription[i] = undefined;
            }
        }

        listDescription = listDescription.filter((e) => e !== undefined);
        this.types = Array.from(new Set(listDescription.map((el) => el.itemType)));
        this.types = this.types.map((el) => {
            return { label: el, value: el };
        });
        this.types = this.types.sort((a, b) => (a.value > b.value ? 1 : -1));
        this.types.unshift({
            label: this.translocoService.translate('Grid.PleaseSelect'),
            value: 'Please Select',
            tabindex: '0'
        });
    }
    /**
   * Restrict the data if there is any duplicate description
   *
   * @param {*} rowData
   * @param {*} gridData
   * @returns
   * @memberof GridComponent
   */
    validateRow(rowData, gridData) {
        if (rowData.description === this.translocoService.translate('Grid.PleaseSelect')) {
            return false;
        }

        const allDescription = gridData.map((e) => ({description:e.description,itemType:e.type}));
        if (allDescription.filter((e) => e.description === rowData.description && e.itemType === rowData.type).length === 1) {
            return true;
        } else {
            const sameDescriptionRowId = gridData
                .filter((e) => e.description === rowData.description)
                .filter((e) => e !== undefined)
                .sort((a, b) => a.gridId - b.gridId);
            if (rowData.gridId === sameDescriptionRowId[0].gridId) {
                return true;
            } else {
                return false;
            }
        }
    }

    validateDescription(rowData, gridData) {
        if (rowData.description === this.translocoService.translate('Grid.PleaseSelect')) {
            return false;
        }
        return true;
    }

    /**
   * To find the distinct types
   *
   * @returns
   * @memberof GridComponent
   */
    getTypesFor() {
        this.types = Array.from(new Set(this.typeDescriptions.map((el) => el.itemType)));
        this.types = this.types.map((el) => {
            return { label: el, value: el };
        });
        this.types.sort((a, b) => (a.value > b.value ? 1 : -1));
        this.types.unshift({
            label: this.translocoService.translate('Grid.PleaseSelect'),
            value: 'Please Select',
            tabindex: '0'
        });
        return this.types;
    }
    /**
   * On click of type
   *
   * @param {*} rowdata
   * @param {*} event
   * @memberof GridComponent
   */
    onTypeClick(rowdata, event) {
        const query = rowdata.type;
        if (this.currentTabDetails.stockTypeName.toLowerCase().includes('expir') || this.templateId < 3) {
            this.getNotNullValues();
        }
        this.descriptions = this.typeDescriptions.filter((el) => el.itemType === query).map((e) => {
            return { label: e.itemName, value: e.itemName };
        });
        this.descriptions = this.descriptions.sort((a, b) => (a.value > b.value ? 1 : -1));
        this.descriptions.unshift({
            label: this.translocoService.translate('Grid.PleaseSelect'),
            value: 'Please Select',
            tabindex: '0'
        });
    }
    /**
   * Fetch the items list
   *
   * @param {*} rowdata
   * @param {*} event
   * @memberof GridComponent
   */
    onTypeChange(rowdata, event) {
        rowdata.description = this.translocoService.translate('Grid.PleaseSelect');
        rowdata.type = event.value;
        this.descriptions = this.typeDescriptions.filter((el) => el.itemType === event.value).map((e) => {
            return { label: e.itemName, value: e.itemName };
        });
        this.descriptions = this.descriptions.sort((a, b) => (a.value > b.value ? 1 : -1));
        this.descriptions.unshift({
            label: this.translocoService.translate('Grid.PleaseSelect'),
            value: 'Please Select',
            tabindex: '0'
        });
    }
    /**
   * Display the description
   *
   * @param {*} event
   * @param {*} rowData
   * @memberof GridComponent
   */
    fetchDescription(event, rowData, gridData,onClickDescription=false) {
        const allDescription = gridData.map(e => ({description: e.description, type: e.type}));
        const allItemTypes = gridData.map((e) => e.type);
        this.descriptions = [];
        const filteredDescriptions = this.typeDescriptions.filter((el) => el.itemType === rowData.type);        
        this.descriptions = this.descriptions.sort((a, b) => (a.value > b.value ? 1 : -1));
        
        for (var i = 0; i < filteredDescriptions.length; i++) {
            var found = false;
            for (var j = 0; j<allDescription.length; j++) {
                if (filteredDescriptions[i].itemName == allDescription[j].description && allDescription[j].type === filteredDescriptions[i].itemType) {
                     found = true;
                     break;
                 }
            }
            if (found == false) {
                this.descriptions.push({ label: filteredDescriptions[i].itemName, value: filteredDescriptions[i].itemName });
            }
        }
        
        this.descriptions.unshift({
            label: this.translocoService.translate('Grid.PleaseSelect'),
            value: 'Please Select',
            tabindex: '0'
        });

    }
    /**
   * On change of description dropdown
   *
   * @param {*} e
   * @param {*} rowdata
   * @memberof GridComponent
   */
    onDropdownClick(e, rowdata) {
        const item = this.typeDescriptions.find((el) => el.itemName === e.value && el.itemType === rowdata.type);
        if(item!=undefined)
        rowdata.itemsId = item.itemsId;            
    }
    /**
   * Add new row
   *
   * @param {*} stockCategory
   * @memberof GridComponent
   */
    add(stockCategory) {
        this.clearGlobalFilter(' ', this.currentTab);        
        stockCategory.stockData.filter(rowdata => {
            if (!rowdata.inventoryItemId) {
                if (rowdata.type != "Please Select" && rowdata.description != "Please Select") {
                    const randomUniqueId = this.newlyAddedRow.pop();
                    rowdata.inventoryItemId = randomUniqueId;
                }
            }
        });
        if (this.newlyAddedRow.length == 0){
            const randomUniqueId = this.getRandomUniqueId(stockCategory);
            this.newlyAddedRow.push(randomUniqueId);
            this.deleteColumnVisible = true;
            this.idCounter++;
            stockCategory.stockData.unshift({
                gridId: this.idCounter,
                type: this.translocoService.translate('Grid.PleaseSelect'),
                description: this.translocoService.translate('Grid.PleaseSelect'),
                stockCategoryId: stockCategory.stockTypeId,
                additionalComments: ''
            });

        }                  
    }

    getRandomUniqueId(stockCategory: any) {
        const stockids = stockCategory.stockData.filter((e) => e.id);
        const randId = Math.floor(1000 + Math.random() * 9000);
        if (stockids.indexOf(randId) === -1) {
            return randId;
        } else {
            this.getRandomUniqueId(stockCategory);
        }
        return;
    }

    handleTabChange(event) {
       
        const tabData = [ ...this.stockTypes ].filter((e, i, ar) => i === event.index);
        this.currentTab = event.index;
        if (tabData[0]) {
            this.currentTabDetails = tabData[0];
            const TypeId = tabData[0].stockTypeId;
            this.getItemsDescriptions(TypeId);
        }
        this.getColsFor();
        this.translocoService.events$
            .pipe(filter((e: any) => e.type === 'translationLoadSuccess'), takeUntil(this.ngSubject))
            .subscribe(() => {
                this.getColsFor();
            });
        this.translocoService.langChanges$.pipe(takeUntil(this.ngSubject)).subscribe(() => {
            this.getColsFor();
        });
        this.deleteColumnVisible = false;
        this.clearGlobalFilter(null, this.currentTab);
        this.xVisible = false;
    }

    isCancelable(rowdata) {
        if (this.newlyAddedRow.indexOf(rowdata.id) !== -1) {
            return true;
        } else {
            return false;
        }
    }
    // Global Search Functionalities Start
    onKeyup(event, i) {
        const userArray = this.userInput.toArray();
        if (userArray[i].nativeElement.value != '') {
            this.xVisible = true;
        } else {
            this.xVisible = false;
        }
    }
    clearGlobalFilter(a, index) {
        if (this.userInput !== undefined) {
            const userArray = this.userInput.toArray();
            if (userArray != null) {
                if (userArray.length > 0) {
                    userArray[index].nativeElement.value = '';
                    this.filterGlobal({ target: { value: '' } }, index);
                    this.xVisible = false;
                }
            }
        }
    }
    // // Global Search Functionalities End
     isNewRow(rowdata) {  
         return !rowdata.inventoryItemId;
    }

    // Check to freeze duplicate row
    onEditInit(event, stock): void {       
        this.firstTime = true;
        this.service.updateInventoryEdited(true);                                                                         
    }

    onRowEditCancel(stock, item: Item) {
        this.clearGlobalFilter(' ', this.currentTab);
        const delItem = stock.stockData.findIndex((e) => e.gridId === item.gridId);
        stock.stockData.splice(delItem, 1);
        this.newlyAddedRow = [];    
    }

    // Added for p-table editMode="row": Edit event
    onRowEditInit(rowdata) {
        // Fix for internal server error 500 upon Submit of survey when the Edit button is used: 
        // --> No extra code required here, just put the p-cellEditor cells in edit mode               
        // console.log(rowdata);
        // console.log("descriptoins",this.descriptions)            
    }
        
    // Added for p-table editMode="row": Save event
    onRowEditSave(rowdata) {          
        if (!rowdata.inventoryItemId) {
            if (rowdata.type != "Please Select" && rowdata.description != "Please Select") {

                const randomUniqueId = this.newlyAddedRow.pop();
                rowdata.inventoryItemId = randomUniqueId;
                this.newlyAddedRow = [];       
            }                            
        }
    }

    // Grid Functions End
    ngOnInit() {
        this.sectorType = parseInt(this.encrDecrService.get(sessionStorage.getItem('userSectorType')));
        this.getAdditionaldata();
        this.display = false;
        // const orgDetils = JSON.parse(
        //   sessionStorage.getItem('OrgainisationDetails')
        // );

        this.templateId = parseInt(this.encrDecrService.get(sessionStorage.getItem('organizationTemplate'))); // orgDetils.templateId;
        this.selectedorgName = sessionStorage.getItem('organizationName');
        if (!this.selectedorgName) {
            if (this.sectorType === 1) {
                this.router.navigate([ 'home' ]);
            } else {
                this.router.navigate([ 'non-health-home' ]);
            }
        }
        this.webappService.updateOrganisationName(this.selectedorgName, true);

        this.getStockCategoryList();
    }

    private pushMessage() {
        let detailsText = '';
        const autoSaveDateLs = new DatePipe('en-US').transform(this.loadAutoSaveDateTime, 'MM/dd/yyyy');
        const autoSaveTimeLs = new DatePipe('en-US').transform(this.loadAutoSaveDateTime, 'HH:mm:ss');
        if (this.isloadAutoSaveData) {
            detailsText =
                this.translocoService.translate('HOME.autoSaveDataLoadMsg') + autoSaveDateLs + ',' + autoSaveTimeLs;
        }
        if (this.isloadSurveyData) {
            detailsText = this.translocoService.translate('HOME.submissionMsg');
        }
        this.msgs = [];
        this.msgs.push({
            severity: 'info',
            summary: this.translocoService.translate('HOME.DataPopulated'),
            detail: detailsText
        });
    }

    /**
   * Fetching the category list
   *
   * @memberof GridComponent
   */
    getStockCategoryList() {
        this.getStockCategoryAPI();
        this.translocoService.events$
            .pipe(filter((e: any) => e.type === 'translationLoadSuccess'), takeUntil(this.ngSubject))
            .subscribe(() => {
                this.handleTabChange({ index: 0 });
            });
        this.translocoService.langChanges$.pipe(takeUntil(this.ngSubject)).subscribe(() => {
            this.handleTabChange({ index: 0 });
        });
    }

    getStockCategoryAPI() {
        this.webappService.GetStockCategoryList(this.templateId).then((resp) => {
            const apiList = [];
            this.apiStockTypes = resp.stockTypes;
            for (const stockList of resp.stockTypes) {
                apiList.push(this.webappService.GetItemsList(stockList.stockTypeId));
            }
            forkJoin(apiList).pipe(take(1)).subscribe((result: any) => {
                let itemList = [];
                for (const res of result) {
                    if (res.itemsList) {
                        itemList = [ ...itemList, ...res.itemsList ];
                    }
                }

                this.allItemDescription = Array.from(new Set(itemList.map((e) => JSON.stringify(e)))).map((eel: any) =>
                    JSON.parse(eel)
                );
                this.getItemsDescriptions(resp.stockTypes[0].stockTypeId, true, resp.stockTypes);
                this.currentTabDetails = resp.stockTypes[0];
                this.getColsFor();
            });
        });
    }
    async manipulateData(stockType, lastSurvey = false) {
        for (const e of stockType.stockData) {
            e.hideDetails = false;
            const items = this.allItemDescription.find((m) => m.itemsId === e.itemsId);
            if (items) {
                e.type = items.itemType;
                e.description = items.itemName;
            } else {
                e.hideDetails = true;
            }
            if (!lastSurvey) {
                this.idCounter++;
            } else {
                this.lastSurveyCounter++;
            }
            e.gridId = !lastSurvey ? this.idCounter : this.lastSurveyCounter;
            //           e.qtyReceived = e.qtyReceived === 0 ? '0' : e.qtyReceived;
            e.onHandQty = e.onHandQty === 0 ? '0' : e.onHandQty;
            e.qtyConsumed = e.qtyConsumed === 0 ? '0' : e.qtyConsumed;
            e.expectedQtyConsumed = e.expectedQtyConsumed === 0 ? '0' : e.expectedQtyConsumed;
            e.expectedQtyNextOrder = e.expectedQtyNextOrder === 0 ? '0' : e.expectedQtyNextOrder;
            e.totalQtyInBackOrder = e.totalQtyInBackOrder === 0 ? '0' : e.totalQtyInBackOrder;
            e.expectedBackOrderDeliveryDate = e.expectedBackOrderDeliveryDate
                ? new Date(e.expectedBackOrderDeliveryDate)
                : null;
            e.expectedBackOrderDeliveryDateStr = e.expectedBackOrderDeliveryDate
                ? this.datePipe.transform(e.expectedBackOrderDeliveryDate, 'MM/dd/yyyy')
                : '';
            e.expectedDeliveryDate = e.expectedDeliveryDate ? new Date(e.expectedDeliveryDate) : null;
            e.expectedDeliveryDateStr = e.expectedDeliveryDate
                ? this.datePipe.transform(e.expectedDeliveryDate, 'MM/dd/yyyy')
                : null;
        }
        // stockType.stockData = stockType.stockData.filter(
        //   (e) => e.hideDetails === false
        // );
        stockType.stockData = stockType.stockData.length
            ? stockType.stockData.filter((e) => e.type && e.description)
            : stockType.stockData;
            
        stockType.stockData.sort(
            (a, b) =>
                a.type > b.type
                    ? 1
                    : a.type < b.type
                      ? -1
                      : a.description > b.description
                        ? 1
                        : a.description < b.description ? -1 : a.gridId > b.gridId ? 1 : a.gridId < b.gridId ? -1 : 0
        );

        if (!lastSurvey) {
            this.stockTypes.push(stockType);
        } else {
            this.lastSurveyStockTypes.push(stockType);
        }
        
    }
    /**
   * Fetching the last survey data
   *
   * @param {*} stockTypesResponse
   * @memberof GridComponent
   */
    getLastSurveyData(stockTypesResponse) {
        this.stockTypes = [];
        this.idCounter = 0;
        if (this.lastSurveryData) {
            this.lastSurveryData.unsubscribe();
        }
        const autoSave = this.webappService.getAutoSave();
        const lastSurvery = this.webappService.GetLastSurveyData();
        if (this.autoSaveTimer) {
            this.autoSaveTimer.unsubscribe();
        }
        this.autoSaveTimer = interval(1000 * 60 * 5).subscribe((t) => {
            this.autoSaveData();
        });
        this.lastSurveryData = forkJoin([ autoSave, lastSurvery ])
            .pipe(take(1))
            .subscribe(async (responseData: any) => {
                let resp: any = {};
                resp = responseData[0].lastSurveyData.length > 0 ? responseData[0] : responseData[1];
                if (responseData[0].lastSurveyData != null) {
                    if (responseData[0].lastSurveyData.length > 0) {
                        this.isloadAutoSaveData = true;
                        this.isloadSurveyData = false;
                        this.loadAutoSaveDateTime = responseData[0].autoSaveDateTime;
                        resp = responseData[0];
                    } else {
                        this.isloadAutoSaveData = false;
                        this.isloadSurveyData = true;
                        resp = responseData[1];
                    }
                } else {
                    this.isloadAutoSaveData = false;
                    this.isloadSurveyData = true;
                    resp = responseData[1];
                }
                let systemTime = new Date(resp.loadDateTime);
                if (this.isSubmitDisabled) {
                    if (systemTime.getHours() >= 17) {
                        this.submitDisabled = true;
                    }
                    this.submitTimer = interval(1000 * 60).subscribe((t) => {
                        systemTime = new Date(systemTime.getTime() + 60000);
                        if (systemTime.getHours() >= 17) {
                            this.submitTimer.unsubscribe();
                            this.submitDisabled = true;
                        }
                    });
                }
                for (const el of stockTypesResponse) {
                    let stockNameLang: any;

                    if (el.stockTypeName.toLowerCase().includes('regular')) {
                        stockNameLang = this.translocoService.translate('Grid.regular');
                    } else if (el.stockTypeName.toLowerCase().includes('expir')) {
                        stockNameLang = this.translocoService.translate('Grid.expired');
                    } else if (el.stockTypeName.toLowerCase().includes('pandemic')) {
                        stockNameLang = this.translocoService.translate('Grid.pandemic');
                    } else if (el.stockTypeName.toLowerCase().includes('aged')) {
                        stockNameLang = this.translocoService.translate('Grid.aged');
                    } else if (el.stockTypeName.toLowerCase().includes('donated')) {
                        stockNameLang = this.translocoService.translate('Grid.donated');
                    } else {
                        stockNameLang = '';
                    }
                    
                    const stockType = {
                        stockTypeId: el.stockTypeId,
                        stockTypeName: stockNameLang,
                        stockData: resp.lastSurveyData.filter((e) => (e.stockCategoryId === el.stockTypeId || e.stockCategoryName === el.stockTypeName))
                      
                    };
                    
                    const lastSurveyStockType = {
                        stockTypeId: el.stockTypeId,
                        stockTypeName: stockNameLang,
                        stockData: responseData[1].lastSurveyData.filter((e) => (e.stockCategoryId === el.stockTypeId || e.stockCategoryName === el.stockTypeName))
                        
                    };
                    
                    
                    
                    await this.manipulateData(lastSurveyStockType, true);
                    await this.manipulateData(stockType);
                    
                }

                this.cloneStockData = undefined;
                this.cloneStockData = JSON.parse(JSON.stringify(this.lastSurveyStockTypes));
                this.cloneStockData.forEach((el) => {
                    for (const e of el.stockData) {
                        e.expectedBackOrderDeliveryDate = e.expectedBackOrderDeliveryDate
                            ? new Date(e.expectedBackOrderDeliveryDate)
                            : null;

                        e.expectedDeliveryDate = e.expectedDeliveryDate ? new Date(e.expectedDeliveryDate) : null;
                    }
                });

                this.pushMessage();
                this.translocoService.events$
                    .pipe(filter((e: any) => e.type === 'translationLoadSuccess'), takeUntil(this.ngSubject))
                    .subscribe(() => {
                        this.pushMessage();
                    });
                this.translocoService.langChanges$.pipe(takeUntil(this.ngSubject)).subscribe(() => {
                    this.pushMessage();
                });
            });
    }
    /**
   * on click of submit
   *
   * @memberof GridComponent
   */
    submitClicked() {
        let data = [];
        this.stockTypes.forEach((e: any) => {
            data = [ ...data, ...e.stockData ];
        });
        const fieldCheck = [
            'onHandQty',
            'qtyConsumed',
            'expectedQtyConsumed',
            'expectedQtyNextOrder',
            'expectedDeliveryDate',
            'totalQtyInBackOrder',
            'expectedBackOrderDeliveryDate'
        ];
        data = data.filter(
            (e) =>
                e.type !== this.translocoService.translate('Grid.PleaseSelect') &&
                e.description !== this.translocoService.translate('Grid.PleaseSelect')
        );

        const validateData = [];
        for (const el of data) {            
            if(el.stockCategoryName){
            var stId = this.apiStockTypes.filter(e => e.stockTypeName === el.stockCategoryName);
            if(stId){
                el.stockCategoryId = stId[0].stockTypeId;
            }}
            let fieldEntered = false;
            for (const field of fieldCheck) {
                if (el[field] && el[field] != 0) {
                    fieldEntered = true;
                }
            }
            if (fieldEntered) {
                validateData.push(el);
            }
        }
       
        const isOverDueDate = data.find((e) => {
            if (e) {
                const date = new Date();
                date.setHours(0, 0, 0, 0);
                let returnValue = false;
                if (e.expectedDeliveryDate) {
                    if (new Date(e.expectedDeliveryDate) < date) {
                        returnValue = true;
                    }
                }
                if (e.expectedBackOrderDeliveryDate) {
                    if (new Date(e.expectedBackOrderDeliveryDate) < date) {
                        returnValue = true;
                    }
                }
                return returnValue;
            } else {
                return false;
            }
        });
        if (!isOverDueDate) {
            this.confirmationService.confirm({
                message:
                    this.translocoService.translate('Grid.confirmEntity') +
                    ' <b>' +
                    sessionStorage.getItem('organizationName') +
                    '</b> ?',
                acceptLabel: this.translocoService.translate('Grid.confirm'),
                rejectLabel: this.translocoService.translate('HOME.cancel'),
                accept: () => {
                    this.webappService
                        .insertSurveyData(validateData)
                        .pipe(take(1))
                        .subscribe(
                            (response: {
                                surveyTrackId: number;
                                status: string;
                                message: string;
                                submittedBy: string;
                                submittedDate: string;
                            }) => {
                                sessionStorage.setItem(
                                    'surveyTrackId',
                                    this.encrDecrService.set(response.surveyTrackId.toString())
                                );

                                sessionStorage.setItem('lastSurveySubmittedOn', response.submittedDate);
                                sessionStorage.setItem('lastSurveySubmittedBy', response.submittedBy);

                                if (response.status.toString() === 'true') {
                                    this.webappService.updateOrganisationName('', false);
                                    this.service.saveData('', 2);
                                    this.service.updateInventoryEdited(false);
                                    this.router.navigate([ 'confirm-submit' ]);
                                    // sessionStorage.removeItem('organizationName');
                                } else {
                                    this.dialogService.open(MessageComponent, {
                                        showHeader: false,
                                        closable: false,
                                        data: {
                                            header: this.translocoService.translate('Grid.InsertSurveyErrorHeader'),
                                            message: this.translocoService.translate('Grid.InsertSurveyErrorMessage')
                                        }
                                    });
                                }
                            }
                        );
                }
            });
        } else {
            this.confirmationService.confirm({
                header: this.translocoService.translate('Grid.InvalidExpdate'),
                message: this.translocoService.translate('Grid.InvalidExpMsg'),
                rejectVisible: false,
                acceptLabel: this.translocoService.translate('Grid.InvalidOkButton')
            });
        }
    }
    /**
   * check the data is edited
   *
   * @param {*} rowData
   * @param {*} columnName
   * @param {*} rowIndex
   * @returns
   * @memberof GridComponent
   */
    checkEdited(rowData, columnName, rowIndex) {
        if (this.stockTypes) {
            if (rowData.inventoryItemId) {
                if (this.cloneStockData.length > 0) {
                    if (this.cloneStockData[this.currentTab].stockData) {
                        const item = this.cloneStockData[this.currentTab].stockData.find(
                            (e) => e.gridId === rowData.gridId
                        );
                        if (item) {
                            rowData[columnName] = rowData[columnName] ? rowData[columnName] : '';
                            item[columnName] = item[columnName] ? item[columnName] : '';
                            const returnValue = item[columnName].toString() !== rowData[columnName].toString();
                            if (returnValue) {
                                this.service.updateInventoryEdited(true);
                            }
                            return returnValue;
                        }
                    }
                }
            }
        }
    }
    /**
   * Download the item list as csv
   *
   * @memberof GridComponent
   */
    itemDownload() {
        this.display = true;
        // let fileURL = '';
        // switch (this.templateId) {
        //    case 1:
        //        fileURL = this.healthDoc;
        //        break;
        //    case 2:
        //        fileURL = this.nonHealthEnDoc;
        //        break;
        //    case 3:
        //        fileURL = this.healthSupplyDoc;
        //        break;
        //    case 4:
        //        fileURL = this.nonHealthSupplyEnDoc;
        //        break;
        // }
        // window.open(fileURL, '_blank');
        if( (localStorage.getItem('Lang') === '') || (localStorage.getItem('Lang') == null) ) //if the language is not set, sets default to 'en'
        {
            console.log('itemDownload(): localStorage.Lang not set, default value sets to \'en\'');
            localStorage.setItem('Lang', 'en');
        }
    }

    displayPopup() {
        this.display = true;
      }
      cancelPopup() {
        this.display = false;
      }
    
    DownloadPdf() {
        this.fileURL = '';
        let fileName = '';
        console.log('DownloadPdf(): localStorage.Lang= ' + localStorage.getItem('Lang'));
        switch (this.templateId) {
            case 1:
                if (localStorage.getItem('Lang') === 'en') {
                    this.fileURL = this.healthDoc;
                } else {
                    this.fileURL = this.healthDocFr;
                }
                fileName = 'Health Sector Items List.pdf';
                break;
            case 2:
                if (localStorage.getItem('Lang') === 'en') {
                    this.fileURL = this.nonHealthEnDoc;
                } else {
                    this.fileURL = this.nonHealthDocFr;
                }
                fileName = 'Non-Health Sector Items List.pdf';
                break;
            case 3:
                if (localStorage.getItem('Lang') === 'en') {
                    this.fileURL = this.healthSupplyDoc;
                } else {
                    this.fileURL = this.healthSupplyDocFr;
                }
                fileName = 'Health Supply Sector Items List.pdf';
                break;
            case 4:
                if (localStorage.getItem('Lang') === 'en') {
                    this.fileURL = this.nonHealthSupplyEnDoc;
                } else {
                    this.fileURL = this.nonHealthSupplyDocFr;
                }
                fileName = 'Non-Health Supply Sector Items List.pdf';
                break;
        }
        this.downloadFiles(this.fileURL, fileName);
    }
    DownloadCsv() {
        this.fileURL = '';
        let fileName = '';
        console.log('DownloadCsv(): localStorage.Lang= ' + localStorage.getItem('Lang'));
        switch (this.templateId) {
            case 1:
                if (localStorage.getItem('Lang') === 'en') {
                    this.fileURL = this.healthDocCsv;
                } else {
                    this.fileURL = this.healthDocCsvFr;
                }
                fileName = 'Health Sector Items List.xlsx';
                break;
            case 2:
                if (localStorage.getItem('Lang') === 'en') {
                    this.fileURL = this.nonHealthEnDocCsv;
                } else {
                    this.fileURL = this.nonHealthDocCsvFr;
                }
                fileName = 'Non-Health Sector Items List.xlsx';
                break;
            case 3:
                if (localStorage.getItem('Lang') === 'en') {
                    this.fileURL = this.healthSupplyDocCsv;
                } else {
                    this.fileURL = this.healthSupplyDocCsvFr;
                }
                fileName = 'Health Supply Sector Items List.xlsx';
                break;
            case 4:
                if (localStorage.getItem('Lang') === 'en') {
                    this.fileURL = this.nonHealthSupplyEnDocCsv;
                } else {
                    this.fileURL = this.nonHealthSupplyDocCsvFr;
                }
                fileName = 'Non-Health Supply Sector Items List.xlsx';
                break;
        }
        this.downloadFiles(this.fileURL, fileName);
    }
    downloadFiles(fileURL, fileName) {
        this.display = false;
        console.log('downloadFiles(): fileURL= ' + this.fileURL + ' | fileName= ' + fileName);
        this.webappService.DownloadFile(fileURL).subscribe((resp) => {
            const blob = resp.body;
            saveAs(blob, fileName);
        });
    }

    downloadUrl(fileurl: string, fileName: string) {
        const a = document.createElement('a');
        const file = new Blob([ fileurl ], { type: 'application/pdf' });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
        a.remove();
    }
    closePopup() {
        this.display = false;
    }
    /**
   *
   *
   * @param {*} field
   * @returns
   * @memberof GridComponent
   */
    checkVisibleTabBased(field) {
        const col = this.cols.find((e) => e.field === field);
        return col;
    }
    /**
   * on click of clear button to clear the values
   *
   * @memberof GridComponent
   */
    clearBtn() {
        this.confirmationService.confirm({
            header: this.translocoService.translate('Grid.Warning'),
            message: this.translocoService.translate('Grid.Cleardata'),
            acceptLabel: this.translocoService.translate('Grid.YES'),
            rejectLabel: this.translocoService.translate('Grid.NO'),
            accept: () => {
                if (this.stockTypes[this.currentTab].stockData) {
                    for (const element of this.stockTypes[this.currentTab].stockData) {
                        element.onHandQty = null;
                        element.qtyConsumed = null;
                        element.expectedQtyConsumed = null;
                    }
                }
            }
        });
    }
    /**
   * To find the date is past due
   *
   * @param {*} date
   * @returns
   * @memberof GridComponent
   */
    checkOverDue(date) {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        return new Date(date) < now;
    }
    /**
   *To open the instruction dialog
   *
   * @memberof GridComponent
   */
    openInstructionDialog() {
        this.dialogService.open(InstructionComponent, {
            showHeader: false,
            dismissableMask: false,
            width: '35vw'
        });
    }
    /**
   * Destory the timer when we destroy the component
   *
   * @memberof GridComponent
   */
    ngOnDestroy() {
        if (this.submitTimer) {
            this.submitTimer.unsubscribe();
        }
        if (this.autoSaveTimer) {
            this.autoSaveTimer.unsubscribe();
        }
        this.ngSubject.next();
        this.ngSubject.complete();
    }
    /**
   * Check max values for the tempalte health and non-health supplies
   *
   * @param {*} rowData
   * @param {*} field
   * @memberof GridComponent
   */
    checkMaxValues(rowData, field) {
        if (this.templateId < 3 && rowData[field]) {
            if (rowData[field] >= 10000000) {
                rowData[field] = 10000000;
            }
        } else if (this.templateId >= 3) {
            if (rowData[field] >= 1000000000) {
                rowData[field] = 1000000000;
            }
        }
    }

    autoSaveData(isLastSurveyData = false, stockTypes = {}) {
        let data = [];
        this.stockTypes.forEach((e: any) => {
            data = [ ...data, ...e.stockData ];
        });
        for (const el of data) {
            if(el.stockCategoryName){
            var stId = this.apiStockTypes.filter(e => e.stockTypeName === el.stockCategoryName);
            if(stId){
                el.stockCategoryId = stId[0].stockTypeId;
            }}
        }
        data = data.filter(
            (e) =>
                e.type !== this.translocoService.translate('Grid.PleaseSelect') &&
                e.description !== this.translocoService.translate('Grid.PleaseSelect')
        );
        this.service.updateAutoSave(null);
        this.webappService.autoSave(data).pipe(take(1)).subscribe((savedData) => {
            const date = new Date();
            this.autoSaveDate = new DatePipe('en-US').transform(date, 'MM/dd/yyyy');
            this.autoSaveTime = new DatePipe('en-US').transform(date, 'HH:mm:ss');
            if (isLastSurveyData) {
                this.getLastSurveyData(stockTypes);
            }
        });
    }

    getAdditionaldata() {
        this.webappService.getAdditionalData().then(async (resp) => {
            const getData = resp;
            if (getData.getIndigenousData === 'Yes') {
                this.showIndigenousPopup = true;
            } else {
                this.showIndigenousPopup = false;
            }
        });
    }

    saveIndigenous() {
        // const checkValue = this.indigenousValue;
        this.indigenousValueError = false;
        if (this.indigenousValue === undefined || this.indigenousValue === null || this.indigenousValue === '') {
            this.indigenousValueError = true;
        }

        if (!this.indigenousValueError) {
            this.updateIndegenous(this.indigenousValue);
        }
    }

    async updateIndegenous(checkValue) {
        const requestValue = {
            indigenousCompany: checkValue
        };
        await this.webappService.updateIndigenousData(requestValue).then(async (resp) => {
            // const response =  resp;
            this.showIndigenousPopup = false;
        });
    }
    validateDescripiton(rowData, gridData) {
        if (rowData.description === this.translocoService.translate('Grid.PleaseSelect')) {
            return false;
        }
        return true;
    }
    cancelIndigenous() {
        this.showIndigenousPopup = false;
    }
    filterGlobal($event, index) {
        this.pTable.toArray()[index].filterGlobal($event.target.value, 'contains');
    }
}
