<div>
    <nav class="navbar navbar-expand-lg navbar-light bg-transparent" style="border-bottom: solid 1px #eee;">
        <a class="navbar-brand col-md-2 col-sm-3 col-8" href="#"><img src="../assets/images/logo.png" alt="Logo" class="img-fluid" /></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
      
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto navbar-right">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        St.James Hospital
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" href="#">Action</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</div>

<div class="container-fluid a-p-20 mobile-grid">
    <div class="row">
        <div class="col-md-3 col-sm-4 col-xs-8 form-group">
            <label>
                Inventory 
            </label>
            <select class="form-control">
                <option value="Regular">Regular</option>
                <option value="Pandemic">Pandemic</option>
                <option vcalue="Expired">Expired</option>
                <option value="Aged">Aged</option>
                <option value="Donated">Donated</option>
            </select>
        </div>
    </div> 
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 a-mb-10">
            <span class="appkiticon icon-filter-outline"></span>
            Filters
        </div>   
        <div class="col-md-2 col-sm-2 col-4 form-group a-pr-0">
            <label>
                Item Type 
            </label>
            <select class="form-control">                
                <option>Select</option>
            </select>
        </div>
        <div class="col-md-4 col-sm-4 col-8 form-group">
            <label>
                Description
            </label>
            <select class="form-control">                
                <option>Select</option>
            </select>
        </div>        
    </div>
    <div class="row">
        <div class="col-md-4 col-sm-4 col-xs-12 form-group">            
            <button class="a-btn a-btn-secondary a-btn-lg add-btn" (click)="addNewPopup()">
                + Add New Item
            </button>
        </div>
    </div>
    <div class="row a-mt-10">
        <div class="col-md-12 col-sm-12 col-xs-12 a-mb-10">
            <b><span class="appkiticon icon-clinic-outline"></span> 3M N95 9210+</b>
            <button class="edit-btn" (click)="editPopup()">
                <span class="appkiticon icon-pencil-outline pull-right"></span>
            </button>
        </div>
        <div class="container-fluid a-mb-10">
            <div class="row form-group">
                <div class="col-md-4 col-sm-4 col-xs-4 col-4">
                    On Hand
                    <div>5,000</div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4 col-4">
                    Consumed
                    <div>5,000</div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4 col-4">
                    Received
                    <div>4,000</div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 a-mb-10">
            <b><span class="appkiticon icon-clinic-outline"></span> 3M N95 9210+</b>
            <button (click)="editPopup()" class="edit-btn">
                <span class="appkiticon icon-pencil-outline pull-right"></span>
            </button>
        </div>
        <div class="container-fluid a-mb-10">
            <div class="row form-group">
                <div class="col-md-4 col-sm-4 col-xs-4 col-4">
                    On Hand
                    <div>5,000</div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4 col-4">
                    Consumed
                    <div>5,000</div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4 col-4">
                    Received
                    <div>4,000</div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 a-mb-10">
            <b><span class="appkiticon icon-clinic-outline"></span> 3M N95 9210+</b>
            <button (click)="editPopup()" class="edit-btn">
                <span class="appkiticon icon-pencil-outline pull-right"></span>
            </button>
        </div>
        <div class="container-fluid a-mb-10">
            <div class="row form-group">
                <div class="col-md-4 col-sm-4 col-xs-4 col-4">
                    On Hand
                    <div>5,000</div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4 col-4">
                    Consumed
                    <div>5,000</div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4 col-4">
                    Received
                    <div>4,000</div>
                </div>
            </div>
        </div>
    </div>
</div>

    
<div aria-hidden="true" class="modal a-modal fade modal-popup" id="itemPopup" role="dialog" tabindex="-1" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog a-modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="a-modal-header">
                    <div class="flex-fill">
                        <span class="a-h4">
                            {{title}}
                        </span>
                    </div>
                    <button aria-label="Close" clas="icon-wrapper" data-dismiss="modal" style="background: transparent;">
                        <span class="appkiticon icon-close-fill a-close-btn"></span>
                    </button>
                </div>
                <div class="a-modal-body">                    
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-12 form-group">
                            <label>
                                Item Type
                            </label>
                            <select class="form-control">   
                                <option>Select</option>
                                <option *ngFor="let data of popupData.itemType" value={{data.id}}>{{data.name}}</option>
                            </select>
                        </div>
                        <div class="col-md-8 col-sm-8 col-12 form-group">
                            <label>
                                Item Description
                            </label>
                            <select class="form-control"> 
                                <option>Select</option>               
                                <option *ngFor="let desc of popupData.itemDescription" value={{desc.id}}>{{desc.descName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-8 col-sm-8 col-6 align-items-center d-flex">
                            <label>
                                On Hand Currently
                            </label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-6">
                            <input type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-8 col-sm-8 col-6 align-items-center d-flex">
                            <label>
                                Expected Use Tomorrow
                            </label>
                        </div>
                            <div class="col-md-8 col-sm-8 col-6">
                            <input type="text" class="form-control" />
                        </div>
                    </div>
                    <hr>
                    <div class="row form-group">
                        <div class="col-md-8 col-sm-8 col-6 align-items-center d-flex">
                            <label>
                                Consumed
                            </label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-6">
                            <input type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-8 col-sm-8 col-6 align-items-center d-flex">
                            <label>
                                Received
                            </label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-6">
                            <input type="text" class="form-control" />
                        </div>
                    </div>                    
                    <hr>
                    <div class="form-group">
                        Next Delivery <button class="a-ml-10" (click)="addAddress()" style="background: transparent;">+ Add Delivery</button>
                    </div>
                    <div *ngFor="let address of popupData.delivery">
                        <div class="row form-group">
                            <div class="col-md-8 col-sm-8 col-6 align-items-center d-flex">
                                <label>
                                    Quantity
                                </label>
                            </div>
                            <div class="col-md-8 col-sm-8 col-6">
                                <input type="text" class="form-control" [(ngModel)]="address.qty" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-8 col-sm-8 col-6 align-items-center d-flex">
                                <label>
                                    Delivery Date
                                </label>
                            </div>
                            <div class="col-md-8 col-sm-8 col-6">
                                <input type="date" class="form-control" [(ngModel)]="address.expectedDate" />
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="form-group">
                        Backorder <button class="a-ml-10" (click)="addOrderAddress()" style="background: transparent;">+ Add Delivery</button>
                    </div>
                    <div *ngFor="let orderAddr of popupData.backOrder">
                        <div class="row form-group">
                            <div class="col-md-8 col-sm-8 col-6 align-items-center d-flex">
                                <label>
                                    Quantity
                                </label>
                            </div>
                            <div class="col-md-8 col-sm-8 col-6">
                                <input type="text" class="form-control" [(ngModel)]="orderAddr.qty" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-8 col-sm-8 col-6 align-items-center d-flex">
                                <label>
                                    Delivery Date
                                </label>
                            </div>
                            <div class="col-md-8 col-sm-8 col-6">
                                <input type="date" class="form-control" [(ngModel)]="orderAddr.expectedDate" />
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-md-8 col-sm-8 col-12">
                            <label>Notes</label>
                        </div>                        
                        <div class="col-md-8 col-sm-8 col-12">
                            <textarea class="form-control"></textarea> 
                        </div>                       
                    </div>
                </div>
                <div class="a-modal-footer">                    
                    <button aria-label="Close" class="a-btn a-btn-transparent a-btn-md cancel-btn a-mr-10" data-dismiss="modal">CANCEL</button>
                    <button aria-label="Close" class="a-btn a-btn-transparent a-btn-md save-btn" data-dismiss="modal">SAVE</button>
                </div>
            </div>
        </div>
    </div>
