import { Component, OnInit } from '@angular/core';
import { WebAppService } from '../../web-app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration-message',
  templateUrl: './registration-message.component.html',
  styleUrls: ['./registration-message.component.css'],
})
export class RegistrationMessageComponent implements OnInit {
  isNewUserRegistration: any = false;
  isFirstUserRegistration: any = false;
  isNewOrgRegistration: any = false;
  isHealthUser = false;
  orgName: string;

  constructor(private webappService: WebAppService, private router: Router) {
    this.webappService.isHealthUser.subscribe((status) => {
      this.isHealthUser = status;
    });
    this.webappService.isNewRegistartion.subscribe((status) => {
      this.isNewUserRegistration = status;
    });
    this.webappService.isNewOrgRegistration.subscribe((status) => {
      this.isNewOrgRegistration = status;
    });
    this.webappService.isFirstUserRegistration.subscribe((status) => {
      this.isFirstUserRegistration = status;
    });
    this.webappService.nonHealthOrgName.subscribe((status) => {
      this.orgName = status;
    });
  }
  ngOnInit(): void {}
  BacktoLogin() {
    this.router.navigate(['Login']);
  }
  showForgotPassword() {
    //this.webappService.updateIsNewRegistration(false);
    this.router.navigate(['forget-password']);
  }
  showCreatePassword() {
    this.router.navigate(['set-new-password']);
  }
}
