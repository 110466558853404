import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WebAppService {
    apiUrl: string;
    authHeaders: HttpHeaders = new HttpHeaders();
    organisationName: BehaviorSubject<any> = new BehaviorSubject<any>('');
    showChangeOrg: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    isHealthUser: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    isNewRegistartion: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    isNewOrgRegistration: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    isFirstUserRegistration: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    nonHealthOrgName: BehaviorSubject<any> = new BehaviorSubject<any>('');
    constructor(private http: HttpClient) {
        console.log('origin: ' + window.location.origin);
        if (environment.environments.local.baseUrl === window.location.origin) {
            this.apiUrl = environment.environments.local.apiUrl;
            console.log('env: local; baseUrl: ' + environment.environments.local.baseUrl);
        }
        if (environment.environments.dev.baseUrl === window.location.origin) {
            this.apiUrl = environment.environments.dev.apiUrl;
            console.log('env: dev; baseUrl: ' + environment.environments.dev.baseUrl);
        }

        if (environment.environments.qa.baseUrl === window.location.origin) {
            this.apiUrl = environment.environments.qa.apiUrl;
            console.log('env: qa; baseUrl: ' + environment.environments.qa.baseUrl);
        }
        if (environment.environments.stage.baseUrl === window.location.origin) {
            this.apiUrl = environment.environments.stage.apiUrl;
            console.log('env: stage; baseUrl: ' + environment.environments.stage.baseUrl);
        }

        if (environment.environments.prod.baseUrl === window.location.origin) {
            this.apiUrl = environment.environments.prod.apiUrl;
            console.log('env: prod; baseUrl: ' + environment.environments.prod.baseUrl);
        }
        if (environment.environments.live.baseUrl === window.location.origin) {
            this.apiUrl = environment.environments.live.apiUrl;
            console.log('env: live; baseUrl: ' + environment.environments.live.baseUrl);
        }

        console.log('apiUrl: ' + this.apiUrl);
        if( this.apiUrl === undefined ) {
            this.apiUrl = 'https://ws.ontario-ppecse-survey.mgcs.gov.on.ca'; // Default
            console.log('apiUrl cannot be determined, using default apiUrl: ' + this.apiUrl);
        }
        //this.apiUrl = 'https://ws.ontario-ppecse-survey.mgcs.gov.on.ca'; // For debugging only
    }
    public getOrgTypes(): Promise<any> {
        return this.http
            .get(`${this.apiUrl}/api/Master/GetOrgTypes`)
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    updateIsNewRegistration(health) {
        if (health) {
            this.isHealthUser.next(true);
        } else {
            this.isHealthUser.next(false);
        }
        this.isNewRegistartion.next(true);
        this.isFirstUserRegistration.next(false);
        this.isNewOrgRegistration.next(false);
    }

    updateIsNewOrgRegistration() {
        this.isNewRegistartion.next(false);
        this.isFirstUserRegistration.next(false);
        this.isNewOrgRegistration.next(true);
    }

    updateIsFirstRegistration() {
        this.isNewRegistartion.next(false);
        this.isFirstUserRegistration.next(true);
        this.isNewOrgRegistration.next(false);
    }

    public VerifyEmail(name): Promise<any> {
        return this.http
            .get(`${this.apiUrl}/api/Registration/VerifyEmailAddress?name=${name}`)
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }
    updateOrganisationName(name, showChangeOrg) {
        this.organisationName.next(name);
        this.showChangeOrg = showChangeOrg;
    }
    updateNonHealthOrgName(name) {
        this.nonHealthOrgName.next(name);
    }

    public LogintoSurvey(UserDetails): Promise<any> {
        return this.http
            .post(`${this.apiUrl}/api/Authentication/Login`, UserDetails)
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public getUserOrgDetails(): Observable<any> {
        this.assignHeaders();

        return this.http.get(`${this.apiUrl}/api/User/GetUserOrgList`, { headers: this.authHeaders });
    }

    private assignHeaders() {
        const authHeaders: HttpHeaders = new HttpHeaders({
            Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache'
        });
        this.authHeaders = authHeaders;
    }

    public GetTemplatesList(): Promise<any> {
        this.assignHeaders();
        return this.http
            .get(`${this.apiUrl}/api/Master/GetTemplatesList`, { headers: this.authHeaders })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public UpdateValidOrg(OrgId): Promise<any> {
        this.assignHeaders();
        return this.http
            .post(`${this.apiUrl}/api/User/UpdateValidOrg`, OrgId, { headers: this.authHeaders })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public GetStockCategoryList(TemplateId): Promise<any> {
        return this.http
            .get(`${this.apiUrl}/api/Master/GetStockCategoryList?TemplateId=${TemplateId}`)
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public GetItemsList(StockCategoryId): Promise<any> {
        // return this.http.get(`${this.apiUrl}/api/Master/GetStockCategoryList?StockCategoryId=${StockCategoryId}`)
        return this.http
            .get(`${this.apiUrl}/api/Master/GetItemsList?StockCategoryId=${StockCategoryId}`)
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public GetMasterItemsList(): Promise<any> {
        // return this.http.get(`${this.apiUrl}/api/Master/GetStockCategoryList?StockCategoryId=${StockCategoryId}`)
        return this.http
            .get(`${this.apiUrl}/api/Master/GetAllItems`)
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public ChangePassword(newCredentials): Promise<any> {
        this.assignHeaders();

        return this.http
            .post(`${this.apiUrl}/api/Authentication/ChangePassword`, newCredentials, { headers: this.authHeaders })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public GetOrgAccessToken(OrgId): Promise<any> {
        this.assignHeaders();

        return this.http
            .get(`${this.apiUrl}/api/Authentication/GetOrgAccessToken?organizationId=${OrgId}`, {
                headers: this.authHeaders,
                responseType: 'text'
            })
            .toPromise()
            .then((resp) => JSON.parse(resp))
            .catch((e) => e.error);
    }

    public PasswordResetFlag(): Promise<any> {
        this.assignHeaders();

        return this.http
            .get(`${this.apiUrl}/api/Authentication/PasswordResetFlag`, { headers: this.authHeaders })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public GetLastSurveyData(): Observable<any> {
        this.assignHeaders();
        return this.http.get(`${this.apiUrl}/api/Survey/GetLastSurveyData`, { headers: this.authHeaders });
    }

    public ForgetPassword(name): Promise<any> {
        this.assignHeaders();
        return this.http
            .get(`${this.apiUrl}/api/Authentication/ForgetPassword?name=${name}`, {
                headers: this.authHeaders,
                responseType: 'text'
            })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }
    public insertSurveyData(data) {
        this.assignHeaders();
        data.forEach((element) => {
            element.onHandQty = parseInt(element.onHandQty, 10);
            element.qtyConsumed = parseInt(element.qtyConsumed, 10);
            element.qtyReceived = parseInt(element.qtyReceived, 10);
            element.expectedQtyConsumed = parseInt(element.expectedQtyConsumed, 10);
            element.expectedQtyNextOrder = parseInt(element.expectedQtyNextOrder, 10);
            element.totalQtyInBackOrder = parseInt(element.totalQtyInBackOrder, 10);
            element.expectedBackOrderDeliveryDate = element.expectedBackOrderDeliveryDate
                ? new Date(element.expectedBackOrderDeliveryDate).toLocaleDateString().replace(/[^ -~]/g, '')
                : null;
            element.expectedDeliveryDate = element.expectedDeliveryDate
                ? new Date(element.expectedDeliveryDate).toLocaleDateString().replace(/[^ -~]/g, '')
                : null;
        });
        return this.http.put(`${this.apiUrl}/api/Survey/InsertSurveyData`, data, { headers: this.authHeaders });
    }

    public SubmissionLog(): Promise<any> {
        this.assignHeaders();
        return this.http
            .get(`${this.apiUrl}/api/Survey/SubmissionLog`, { headers: this.authHeaders })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }
    public getOrdListBySectors(sectorId): Promise<any> {
        this.assignHeaders();
        return this.http
            .get(`${this.apiUrl}/api/Registration/GetOrganizationListBySectors?SectorId=${sectorId}`, {
                headers: this.authHeaders
            })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public userRegistration(userRegDetails): Promise<any> {
        return this.http
            .post(`${this.apiUrl}/api/Registration/RegisterUserForExistingOrg`, userRegDetails)
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }
    public userRegistrationWithNewOrg(userRegDetails): Promise<any> {
        return this.http
            .post(`${this.apiUrl}/api/Registration/RegisterUserWithNewOrg`, userRegDetails)
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }
    public getDownTime(): Observable<any> {
        return this.http.get(`${this.apiUrl}/api/Authentication/DownTimeWindow`);
    }

    public getLHIN(): Observable<any> {
        return this.http.get(`${this.apiUrl}/api/Master/GetLHINList`);
    }
    getSessionTime(): Observable<any> {
        return this.http.get(`${this.apiUrl}/api/Master/GetTrainingSessions`);
    }
    getSessionTimeFrench(): Observable<any> {
        return this.http.get(`${this.apiUrl}/api/Master/GetFrenchTrainingSessions`);
    }

    public getCanadaPostalAddress(query, lastId = '') {
        const url = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws';
        let params = '';
        params += '?Key=' + encodeURIComponent(environment.postalApiKey);
        params += '&SearchTerm=' + encodeURIComponent(query);
        if (lastId) {
            params += '&LastId=' + encodeURIComponent(lastId);
        }
        params += '&Country=CAN&LanguagePreference=en&SearchFor=Everything&OrderBy=UserLocation';
        return this.http.get(url + params);
    }
    public selectedCanadaPostalAddress(query) {
        const url =
            'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/RetrieveFormatted/v2.10/json3ex.ws';
        let params = '';
        params += '?Key=' + encodeURIComponent(environment.postalApiKey);
        params += '&Id=' + query;
        return this.http.get(url + params);
    }
    /**
 * To get the browser information
 *
 * @param {*} browserData
 * @returns
 * @memberof WebAppService
 */
    public browserInfo(browserData) {
        this.assignHeaders();
        return this.http.post(this.apiUrl + '/api/Authentication/LogBrowserMetadata', browserData, {
            headers: this.authHeaders,
            responseType: 'text'
        });
    }
    /**
     * To fetch the autosave data
     *
     * @returns
     * @memberof WebAppService
     */
    public getAutoSave() {
        this.assignHeaders();
        return this.http.get(this.apiUrl + '/api/Survey/GetAutoSaveData', {
            headers: this.authHeaders
        });
    }
    /**
     * To Save the data
     *
     * @param {*} data
     * @returns
     * @memberof WebAppService
     */
    public autoSave(data) {
        this.assignHeaders();
        for (const element of data) {
            element.onHandQty = element.onHandQty ? parseInt(element.onHandQty, 10) : null;
            element.qtyConsumed = element.qtyConsumed ? parseInt(element.qtyConsumed, 10) : null;
            element.expectedQtyConsumed = element.expectedQtyConsumed
                ? parseInt(element.expectedQtyConsumed, 10)
                : null;
            element.expectedQtyNextOrder = element.expectedQtyNextOrder
                ? parseInt(element.expectedQtyNextOrder, 10)
                : null;
            element.totalQtyInBackOrder = element.totalQtyInBackOrder
                ? parseInt(element.totalQtyInBackOrder, 10)
                : null;
            element.expectedBackOrderDeliveryDate = element.expectedBackOrderDeliveryDate
                ? new Date(element.expectedBackOrderDeliveryDate).toLocaleDateString().replace(/[^ -~]/g, '')
                : null;
            element.expectedDeliveryDate = element.expectedDeliveryDate
                ? new Date(element.expectedDeliveryDate).toLocaleDateString().replace(/[^ -~]/g, '')
                : null;
        }

        return this.http.put(this.apiUrl + '/api/Survey/AutoSaveData', data, {
            headers: this.authHeaders
        });
    }
    public GetSubmissionLogData(surveyTrackId): Promise<any> {
        this.assignHeaders();
        return this.http
            .get(`${this.apiUrl}/api/Survey/GetSubmissionLogData?surveyTrackId=${surveyTrackId}`, {
                headers: this.authHeaders
            })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public getAdditionalData(): Promise<any> {
        this.assignHeaders();
        return this.http
            .get(`${this.apiUrl}/api/Survey/GetAdditionalData`, { headers: this.authHeaders })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public updateIndigenousData(indigenousValue): Promise<any> {
        this.assignHeaders();

        return this.http
            .post(`${this.apiUrl}/api/Survey/UpdateIndigenousData`, indigenousValue, { headers: this.authHeaders })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }


    public acceptNewOrgNotify(userId, orgId): Promise<any> {
        this.assignHeaders();
        return this.http
            .get(`${this.apiUrl}/api/Registration/AcceptNewOrgRequestNotification?userId=${userId}&orgId=${orgId}`, {
                headers: this.authHeaders })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public addExistingOrgToUser(orgDetails): Promise<any> {
        this.assignHeaders();
        return this.http
            .post(`${this.apiUrl}/api/Registration/AddExistingOrgToUser`, orgDetails, { headers: this.authHeaders })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
    }

    public addNewOrgToUser(orgDetails): Promise<any> {
        this.assignHeaders();
        return this.http
            .post(`${this.apiUrl}/api/Registration/AddNewOrgtoUser`, orgDetails, { headers: this.authHeaders })
            .toPromise()
            .then((resp) => resp)
            .catch((e) => e.error);
  }

  public DownloadFile(fileURL): Observable<any> {
   this.assignHeaders();
   return this.http.get(`${this.apiUrl}/api/Survey/DownloadFile?file=${fileURL}`, {
     headers: this.authHeaders,
     observe: 'response',
     responseType: 'blob'
   });
  }
}


