<div class="content-section">
    <router-outlet></router-outlet>
</div>
<p-toast></p-toast>
<app-footer ></app-footer>
<div *ngIf="loader">
    <div class="bgLoader">
    </div>
    <div class="d-flex justify-content-center align-items-center w-100 loaderClass">
        <div class="col-12 text-center">
            <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
        </div>
    </div>
</div>