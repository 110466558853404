import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpSentEvent,
    HttpHandler,
    HttpResponse,
    HttpProgressEvent,
    HttpHeaderResponse,
    HttpErrorResponse,
    HttpUserEvent,
    HttpEvent
} from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { take, map, catchError, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SharedService } from '../components/sharedservice/sharedservice';
declare var $;
@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
    private tokenHeader = '';
    constructor(private router: Router, private behaviourSubjectService: SharedService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const rHeaders = request.headers;
        rHeaders.set('cache-control', 'no-cache');
        rHeaders.set('pragma', 'no-cache');
        this.behaviourSubjectService.updateLoader(true);
        const crequest = request.clone({ headers: rHeaders });
        return next.handle(crequest).pipe(
            map((evt: HttpEvent<any>) => {
                if (evt instanceof HttpResponse) {
                    this.behaviourSubjectService.updateLoader(false);
                }
                return evt;
            }),
            catchError((error: HttpErrorResponse) => {
                //
                this.behaviourSubjectService.updateLoader(false);
                let data = {};
                data = {
                    reason: error.message,
                    status: error.status
                };
                if (error.status === 401) {
                    sessionStorage.clear();
                    this.router.navigate([ 'Login' ]);
                }
                return throwError(error);
            })
        );
    }
}
