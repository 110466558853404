import { Component, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
declare var $;

@Component({
  selector: 'app-data-view',
  templateUrl: './data-view.component.html',
  styleUrls: ['./data-view.component.css']
})
export class DataViewComponent implements OnInit {
  title: string;
  tempPopupData: any = {
    itemType: [
      {id: 1, name: 'Mask'}
    ],
    itemDescription: [
      {id: 1, descName: '3M N95 1860'},
      {id: 2, descName: '3M N95 9210+'},
    ],
    onHand: '',
    expectedUseTom: '',
    consumed: '',
    recieved: '',
    delivery: [
      {qty: '', expectedDate: ''}
    ],
    backOrder: [
      {qty: '', expectedDate: ''}
    ],
    note: ''
  }

  popupData: any = {};


  constructor() { }

  ngOnInit(): void {
  }

  addNewPopup() {
    this.title = 'Add Item';
    this.popupData = Object.assign({}, this.tempPopupData);
    const viewpopup = document.getElementById('itemPopup');
    document.querySelector('body').appendChild(viewpopup);
    $('#itemPopup').modal('show');    
  }

  editPopup() {
    this.title = 'Edit Item';
    this.popupData = Object.assign({}, this.tempPopupData);
    const viewpopup = document.getElementById('itemPopup');
    document.querySelector('body').appendChild(viewpopup);
    $('#itemPopup').modal('show');    
  }
  
  addAddress() {
    this.popupData.delivery.push({
      qty:'', expectedDate:''
    })
  }

  addOrderAddress() {
    this.popupData.backOrder.push({
      qty:'', expectedDate:''
    })
  }
}
