<div *ngIf="isNewUserRegistration">

    <div class="login-wrapper">
        <div class="login-wrapper-in display-tab-w100">
            <div class="login org-grid" id="loginAsOrgTYpe" style="width: 60vw;">
                <div class="login-logo a-mb-20">
                    <!-- <img src="./assets/images/logo.png" alt="Logo" /> -->
                    <i class="fa fa-check-circle" style="font-size:112px; color:#666666"></i>
                </div>

                <div style="margin-bottom:30px; font-size: 20px; font-weight: bold; line-height: 1.58;" *ngIf="isHealthUser">
                    {{ 'REGISTRATION-MSG.submitted' | transloco }} </div>
                <div style="margin-bottom:30px; font-size: 16px; line-height: 1.58;" *ngIf="isHealthUser">
                    {{ 'REGISTRATION-MSG.sentEmail' | transloco }} 
                </div>
                <div style="margin-bottom:30px; font-size: 16px; line-height: 1.58;" *ngIf="isHealthUser">
                    {{ 'REGISTRATION-MSG.receiveEmail' | transloco }} 
                </div>
                <div style="margin-bottom:30px; font-size: 20px; font-weight: bold; line-height: 1.58;" *ngIf="!isHealthUser">                                       
                    {{ 'REGISTRATION-MSG.healthRegMail1' | transloco }} 
                    {{ orgName }}
                    {{ 'REGISTRATION-MSG.healthRegMail2' | transloco }}
                </div>
                                
                <div class="text-center a-textarea-footer forgot-password-footer a-mt-20">
                    <div class="a-textarea-footer">
                        <button id="Nextbtn" class="a-btn a-btn-primary a-btn-xl login-btn"
                            (click)="BacktoLogin()" tabindex="0"
                            style="padding: .8125rem 3.25rem;width: auto !important;background-color:#386152 !important; position:relative; font-weight: bold;">
                            {{ 'Grid.loginBack' | transloco }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div *ngIf="isFirstUserRegistration">
    <div class="login-wrapper">
        <div class="login-wrapper-in display-tab-w100">
            <div class="login org-grid" id="loginAsOrgTYpe" style="width: 80vw;">
                <div class="login-logo a-mb-20">
                    <!-- <img src="./assets/images/logo.png" alt="Logo" /> -->
                    <i class="fa fa-check-circle" style="font-size:112px; color:#666666"></i>
                </div>

                <div style="margin-bottom:30px; font-size: 20px; font-weight: bold; line-height: 1.58;">
                    {{ 'REGISTRATION-MSG.submitted' | transloco }} </div>
                <div style="margin-bottom:30px; font-size: 16px; line-height: 1.58;">
                    {{ 'REGISTRATION-MSG.firstPerson' | transloco }} </div>
                <div style="margin-bottom:30px; font-size: 16px; line-height: 1.58;">
                    {{ 'REGISTRATION-MSG.click' | transloco }} </div>

                <div class="text-center a-textarea-footer forgot-password-footer a-mt-20">
                    <div class="a-textarea-footer">
                        <button id="Nextbtn" class="a-btn a-btn-primary a-btn-xl login-btn"
                            (click)="showCreatePassword()" tabindex="0"
                            style="padding: .8125rem 3.25rem;width: auto !important;background-color:#7B9C90 !important; position:relative; font-weight: bold;">
                            {{ 'REGISTRATION-MSG.createPassword' | transloco }}
                        </button>
                    </div>
                </div>
               
            </div>
        </div>
    </div>

</div>

<div *ngIf="isNewOrgRegistration">

    <div class="login-wrapper">
        <div class="login-wrapper-in display-tab-w100">
          <div class="login org-grid" id="loginAsOrgTYpe" style="width: 60vw;">
            <div class="login-logo a-mb-20">
              <!-- <img src="./assets/images/logo.png" alt="Logo" /> -->
              <i class="fa fa-check-circle" style="font-size:112px; color:#666666"></i>
            </div>

            <div style="margin-bottom: 30px; font-size: 20px; font-weight: bold; line-height: 1.58;">
              {{ 'REGISTRATION-MSG.neworgReceived' | transloco }}
            </div>
            <div style="margin-bottom:30px; font-size: 16px; line-height: 1.58;">
              {{ 'REGISTRATION-MSG.newOrg' | transloco }}
            </div>
            <div style="margin-bottom:30px; font-size: 16px; line-height: 1.58;">
              {{ 'REGISTRATION-MSG.newOrgDesc' | transloco }}
            </div>
            <div style="margin-bottom:30px; font-size: 16px; line-height: 1.58;">
              {{ 'REGISTRATION-MSG.newOrgDesc2' | transloco }}
            </div>
            <div class="text-center a-textarea-footer forgot-password-footer a-mt-20">
              <div class="a-textarea-footer">
                <button id="Nextbtn" class="a-btn a-btn-primary a-btn-xl login-btn"
                        (click)="BacktoLogin()" tabindex="0"
                        style="padding: .8125rem 3.25rem;width: auto !important;background-color:#7B9C90 !important; position:relative; font-weight: bold;">
                  {{ 'Grid.loginBack' | transloco }}
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>

</div>
