import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { SharedService } from '../components/sharedservice/sharedservice';
import { ConfirmationService } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { WebAppService } from '../web-app.service';

@Injectable()
export class NavigationGuard implements CanActivateChild, CanActivate {
    isInventoryEdited = false;
    constructor(
        private router: Router,
        private sharedService: SharedService,
        private webAppService: WebAppService,
        private confirmService: ConfirmationService,
        private translocoService: TranslocoService
    ) {
        this.sharedService.isInventoryEdited.subscribe((data) => {
            if (data !== this.isInventoryEdited) {
                this.isInventoryEdited = data;
            }
        });
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot) {
        return this.validateToken(childRoute);
    }
    canActivate(childRoute: ActivatedRouteSnapshot) {
        return this.validateToken(childRoute);
    }
    validateToken(childRoute) {
        if (!this.isInventoryEdited) {
            const token = sessionStorage.getItem('access_token');
            const accessToken = sessionStorage.getItem('access_token');
            const isRoutable = token || accessToken ? true : false;
            if (isRoutable) {
                if(childRoute.routeConfig.path.toLowerCase().includes('home') || childRoute.routeConfig.path.toLowerCase().includes('entity')) {
                    this.webAppService.updateOrganisationName('', false);
                }
                this.sharedService.updatePath(childRoute.routeConfig.path);
                this.sharedService.updateIdleTimeout();
                return true;
            } else {
                this.router.navigate([ 'Login' ]);
            }
        } else {

            this.confirmService.confirm({
                header: '',
                message:
                '<span>' +
                this.translocoService.translate('Grid.gridNavigationMsg') +
                '<b> ' + this.translocoService.translate('Grid.gridNavigationMsg1') +
                '</b> </span>',
                acceptIcon: '',
                rejectIcon: '',
                acceptLabel: this.translocoService.translate('Grid.yesContinue'),
                rejectLabel: this.translocoService.translate('Grid.noCancel'),
                accept: () => {
                    this.sharedService.updateAutoSave(Math.floor(Math.random() * 100000000));
                    timer(1000).pipe(take(1)).subscribe((data) => {
                        this.sharedService.updateInventoryEdited(false);
                        this.sharedService.updatePath(childRoute.routeConfig.path);
                        if(childRoute.routeConfig.path.toLowerCase().includes('home') || childRoute.routeConfig.path.toLowerCase().includes('entity')) {
                            this.webAppService.updateOrganisationName('', false);
                        }
                        this.router.navigate([ childRoute.routeConfig.path ]);
                    });

                    // this.sharedService.updateInventoryEdited(false);
                    // this.sharedService.updatePath(childRoute.routeConfig.path);
                    // this.router.navigate([ childRoute.routeConfig.path ]);
                },
                reject: () => {}
            });
            return false;
        }
    }
}
