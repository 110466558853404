import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import * as Excel from 'exceljs/dist/exceljs.min';
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { WebAppService } from 'src/app/web-app.service';
import { SharedService } from '../sharedservice/sharedservice';
import { EncrDecrService } from '../sharedservice/enc-dec.service';

@Component({
  selector: 'app-confrim-submit',
  templateUrl: './confrim-submit.component.html',
  styleUrls: ['./confrim-submit.component.css'],
})
export class ConfrimSubmitComponent implements OnInit {
  msgData: any;
  msgDetails: any;
  componentType: any;
  surveyTrackId: any;
  currentTabDetails: any;
  templateId: any;
  cols: any[];
  stockTypes: any[];
  orgDetils: any;
  stockCategory: any;
  stockCategoryData: any;
  stockItemList: any;
  imageURLB64: any;
  ExcelOrgName: any;
  ExcelOrgDate: any;
  submittedBy: any;
  submittedOn: any;
  showAddAnotherEntity: boolean;
  OrgType: string;
  FilenameDate: string;
  constructor(
    public service: SharedService,
    private router: Router,
    private webappservice: WebAppService,
    private translocoService: TranslocoService,
    private datePipe: DatePipe,
    private encrDecrSevice: EncrDecrService
  ) {
    //this.msgData = service.getData();
    this.msgDetails = service.getData('msgDetails');
    this.componentType = service.getData('componentType');
    if (this.componentType === '2') {
      this.webappservice.getUserOrgDetails().subscribe(async (resp) => {
        if (resp !== undefined) {
          if (resp.organizationList.length === 1) {
            this.showAddAnotherEntity = false;
          } else {
            this.showAddAnotherEntity = true;
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.surveyTrackId = JSON.parse(this.encrDecrSevice.get(sessionStorage.getItem('surveyTrackId')));
    this.getBase64ImageFromUrl('../../assets/images/Ontario_Logo.png')
      .then((result) => (this.imageURLB64 = result))
      .catch((err) => console.error(err));

    //this.orgDetils = JSON.parse(sessionStorage.getItem('OrgainisationDetails'));
    this.submittedBy = sessionStorage.getItem('lastSurveySubmittedBy');
    this.submittedOn = sessionStorage.getItem('lastSurveySubmittedOn');
    const lastDate = new DatePipe('en-US').transform(
      this.submittedOn,
      'mediumDate'
    );
    const lastDateTime = new DatePipe('en-US').transform(
      this.submittedOn,
      'hh:mm a'
    );
    this.ExcelOrgDate = lastDate + ' - ' + lastDateTime + ' EST';
    this.getOrganisationType();
    this.getStockCategoryList();
  }
  logout() {
    this.service.saveData('', 0);
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['Login']);
  }
  loadGrid() {
    this.webappservice.updateOrganisationName('', false);
    this.router.navigate(['entity-screen']);
  }

  done() {
    localStorage.clear();
    this.router.navigate(['entity-screen']);
  }

  addEntity() {
    this.router.navigate(['create-entity']);
  }
  getItemsDescriptions(StockCategoryId) {
    this.webappservice.GetMasterItemsList().then((resp) => {
      this.stockItemList = resp;
    });
  }
  getOrganisationType() {
    this.templateId = parseInt(this.encrDecrSevice.get(sessionStorage.getItem('organizationTemplate'))); // this.orgDetils.templateId;
    switch (this.templateId.toString()) {
      case '1': {
        this.OrgType = 'Health Sector Items List';
        break;
      }
      case '2': {
        this.OrgType = 'Non-Health Sector Items List';
        break;
      }
      case '3': {
        this.OrgType = 'Health Supply Entity Items List';
        break;
      }
      case '4': {
        this.OrgType = 'Non-Health Supply Entity Items List';
        break;
      }
      default:
        this.OrgType = 'Health Sector Items List';
    }
  }
  getStockColDef(stockTypeName) {
    const templateId1 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhand') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerConsumed') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerExpected') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyNextOrder',
        header:
          this.translocoService.translate('Grid.headerNextOrder') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedDeliveryDateStr',
        header: this.translocoService.translate('Grid.headerNextOrderDate') + this.translocoService.translate('Grid.mmddyyyy'),
      },
      {
        field: 'totalQtyInBackOrder',
        header:
          this.translocoService.translate('Grid.headerBackOrder') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedBackOrderDeliveryDateStr',
        header: this.translocoService.translate('Grid.headerBackOrderDate') + this.translocoService.translate('Grid.mmddyyyy'),
      },
    ];
    const templateId2 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhand') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerNHConsumed') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerNHExpected') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyNextOrder',
        header:
          this.translocoService.translate('Grid.headerNextOrder') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedDeliveryDateStr',
        header: this.translocoService.translate('Grid.headerNextOrderDate') + this.translocoService.translate('Grid.mmddyyyy'),
      },
      {
        field: 'totalQtyInBackOrder',
        header:
          this.translocoService.translate('Grid.headerBackOrder') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedBackOrderDeliveryDateStr',
        header: this.translocoService.translate('Grid.headerBackOrderDate') + this.translocoService.translate('Grid.mmddyyyy'),
      },
    ];
    const templateId3 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhandSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerConsumedSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerExpectedSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyNextOrder',
        header:
          this.translocoService.translate('Grid.headerNextOrderSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedDeliveryDateStr',
        header: this.translocoService.translate('Grid.headerNextOrderDate') + this.translocoService.translate('Grid.mmddyyyy'),
      },
      {
        field: 'totalQtyInBackOrder',
        header:
          this.translocoService.translate('Grid.headerBackOrderSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedBackOrderDeliveryDateStr',
        header: this.translocoService.translate('Grid.headerBackOrderDate') + this.translocoService.translate('Grid.mmddyyyy'),
      },
    ];
    const templateId4 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhandSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerConsumedNHSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerExpected') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyNextOrder',
        header:
          this.translocoService.translate('Grid.headerNextOrderSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedDeliveryDateStr',
        header: this.translocoService.translate('Grid.headerNextOrderDate') + this.translocoService.translate('Grid.mmddyyyy'),
      },
      {
        field: 'totalQtyInBackOrder',
        header:
          this.translocoService.translate('Grid.headerBackOrderSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedBackOrderDeliveryDateStr',
        header: this.translocoService.translate('Grid.headerBackOrderDate') + this.translocoService.translate('Grid.mmddyyyy'),
      },
    ];
    const expiredInventoryTemplate1 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhand') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerConsumed') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerExpected') +
          this.translocoService.translate('Grid.eaches'),
      },
    ];
    const expiredInventoryTemplate2 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhand') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerNHConsumed') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerNHExpected') +
          this.translocoService.translate('Grid.eaches'),
      },
    ];
    const expiredInventoryTemplate3 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhandSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerConsumedSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerExpectedSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
    ];
    const expiredInventoryTemplate4 = [
      {
        field: 'itemsId',
        header: this.translocoService.translate('Grid.headerType'),
      },
      {
        field: 'description',
        header: this.translocoService.translate('Grid.headerDesc'),
      },
      {
        field: 'onHandQty',
        header:
          this.translocoService.translate('Grid.headerOnhandSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'qtyConsumed',
        header:
          this.translocoService.translate('Grid.headerConsumedNHSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
      {
        field: 'expectedQtyConsumed',
        header:
          this.translocoService.translate('Grid.headerExpectedNHSupplies') +
          this.translocoService.translate('Grid.eaches'),
      },
    ];
    
    let cols = [];
    if (this.currentTabDetails) {
      if (
        this.currentTabDetails.stockTypeName.toLowerCase().includes('expir')
      ) {
        switch (this.templateId.toString()) {
          case '1':
            cols = expiredInventoryTemplate1;
            break;
          case '2':
            cols = expiredInventoryTemplate2;
            break;
          case '3':
            cols = expiredInventoryTemplate3;
            break;
          case '4':
            cols = expiredInventoryTemplate3;
            break;
          default:
            cols = expiredInventoryTemplate1;
        }
      } else {
        switch (this.templateId.toString()) {
          case '1':
            cols = templateId1;
            break;
          case '2':
            cols = templateId2;
            break;
          case '3':
            cols = templateId3;
            break;
          case '4':
            cols = templateId4;
            break;
          default:
            cols = templateId1;
        }
      }
    }
    return cols;
  }

  getStockCategoryList() {
    this.webappservice.GetStockCategoryList(this.templateId).then((resp) => {
      this.currentTabDetails = resp.stockTypes[0];
      this.stockCategory = [];
      resp.stockTypes.forEach((element) => {
        const stockTypeObj = {
          stockTypeId: element.stockTypeId,
          stockTypeName: this.getCategoryName(element.stockTypeName),
          itemsList: this.stockItemList,
        };
        this.stockCategory.push(stockTypeObj);
      });
    });
  }
  getCategoryName(stockTypeName) {
    let stockNameLang: any;
    if (stockTypeName.toLowerCase().includes('regular')) {
      stockNameLang = this.translocoService.translate('Grid.regular');
    } else if (stockTypeName.toLowerCase().includes('expir')) {
      stockNameLang = this.translocoService.translate('Grid.expired');
    } else if (stockTypeName.toLowerCase().includes('pandemic')) {
      stockNameLang = this.translocoService.translate('Grid.pandemic');
    } else if (stockTypeName.toLowerCase().includes('aged')) {
      stockNameLang = this.translocoService.translate('Grid.aged');
    } else if (stockTypeName.toLowerCase().includes('donated')) {
      stockNameLang = this.translocoService.translate('Grid.donated');
    }else  {
      stockNameLang = '';
    }
    return stockNameLang;
  }
  async getLastSurveyDataById(surveyTrackId) {
    this.webappservice.GetSubmissionLogData(surveyTrackId).then((resp) => {
      this.stockCategoryData = [];
      this.stockCategory.forEach(async (element) => {
        const stockcol = this.getStockColDef(element.stockTypeName);
        const StockData = resp.lastSurveyData.filter(
          (e) => e.stockCategoryId === element.stockTypeId
        );
        this.webappservice.GetMasterItemsList().then((resp) => {
          this.stockItemList = resp;

          StockData.forEach((element) => {
            const stockFilteredItem = this.stockItemList.filter(
              (e) => e.itemId === element.itemsId
            );
            element.itemsId = stockFilteredItem[0].itemType;
            element.description = stockFilteredItem[0].itemName;

            element.onHandQty = element.onHandQty === 0 ? 0 : element.onHandQty;
            element.qtyConsumed =
              element.qtyConsumed === 0 ? 0 : element.qtyConsumed;
            element.expectedQtyConsumed =
              element.expectedQtyConsumed === 0
                ? 0
                : element.expectedQtyConsumed;
            element.expectedQtyNextOrder =
              element.expectedQtyNextOrder === 0
                ? 0
                : element.expectedQtyNextOrder;
            element.totalQtyInBackOrder =
              element.totalQtyInBackOrder === 0
                ? 0
                : element.totalQtyInBackOrder;
            element.expectedBackOrderDeliveryDate = element.expectedBackOrderDeliveryDate
              ? new Date(element.expectedBackOrderDeliveryDate)
              : null;
            element.expectedBackOrderDeliveryDateStr = element.expectedBackOrderDeliveryDate
              ? this.datePipe.transform(
                  element.expectedBackOrderDeliveryDate,
                  'MM/dd/yyyy'
                )
              : '';
            element.expectedDeliveryDate = element.expectedDeliveryDate
              ? new Date(element.expectedDeliveryDate)
              : null;
            element.expectedDeliveryDateStr = element.expectedDeliveryDate
              ? this.datePipe.transform(
                  element.expectedDeliveryDate,
                  'MM/dd/yyyy'
                )
              : null;
          });
        });
        var stockTypeObj = {
          stockTypeId: element.stockTypeId,
          stockTypeName: element.stockTypeName,
          stockData: StockData,
          stockColExcelDef: stockcol.map((col) => ({
            header: col.header,
            key: col.field,
          })),
          stockColDef: stockcol.map((col) => ({
            title: col.header,
            dataKey: col.field,
          })),
          stockCol: stockcol,
        };
        this.stockCategoryData.push(stockTypeObj);
      });
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName);
  }
  async DownloadInventoryPDF(surveyTrackId) {
    this.ExcelOrgName = sessionStorage.getItem('organizationName'); // this.orgDetils.organizationName;
    //this.ExcelOrgDate = this.submittedOn;
    //this.FilenameDate =  this.submittedOn;
    this.FilenameDate = new DatePipe('en-US').transform(
      this.submittedOn,
      'MM_dd_yyyy'
    );
    await this.getLastSurveyDataById(surveyTrackId);
    const doc = new jsPDF('l', 'mm', [700, 1000]);
    let xCord = 15;
    let yCord = 10;
    let startYCord = 45;

    setTimeout(() => {
      this.stockCategoryData.forEach((element, index) => {
        doc.setFontType('bold');
        doc.setFontSize(15);
        doc.setTextColor(0, 0, 0);
        if (this.imageURLB64) {
          if (this.imageURLB64) { doc.addImage(this.imageURLB64, 'JPEG', xCord, 7, 26, 12); }
        }
        doc.text(
          xCord + 30,
          15,
          this.translocoService.translate('HOME.HomeHeader') +
            ' - ' +
            this.OrgType
        );
        doc.setLineWidth(0.3);
        doc.setDrawColor(187, 191, 188);
        doc.line(
          xCord,
          yCord + 10,
          doc.internal.pageSize.width - xCord,
          yCord + 10
        );
        doc.setFontSize(10);
        var OrgNameWidth =
          (doc.getStringUnitWidth(this.ExcelOrgName) *
            doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - OrgNameWidth) / 2;
        doc.text(textOffset, yCord + 17, this.ExcelOrgName);
        doc.line(
          xCord,
          yCord + 20,
          doc.internal.pageSize.width - xCord,
          yCord + 20
        );
        var OrgDateWidth =
          (doc.getStringUnitWidth(this.ExcelOrgDate) *
            doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - OrgDateWidth) / 2;
        doc.text(textOffset, yCord + 27, this.ExcelOrgDate);
        doc.line(
          xCord,
          yCord + 30,
          doc.internal.pageSize.width - xCord,
          yCord + 30
        );
        doc.setTextColor(0, 0, 0);

        doc.setFontSize(14);
        doc.text(xCord, startYCord + 5, element.stockTypeName);
        doc.setFontSize(9);
        element.stockData.sort((a: any, b: any) => {
          if (!a.itemsId.toString().localeCompare(b.itemsId)) {
            return a.description.toString().localeCompare(b.description);
          }
          return a.itemsId.toString().localeCompare(b.itemsId);
        });
        doc.autoTable(  {
          columns: element.stockColDef,
          body: element.stockData,
          startY: startYCord + yCord,
          margin: { horizontal: 15 },
          theme: 'plain',
          styles: {
            cellPadding: 3,
            fontSize: 10,
            lineColor: [249, 249, 249],
            lineWidth: 0.5,
            tableWidth: 'auto',
            halign: 'right',
          },
          columnStyles: { 0: { halign: 'left' }, 1: { halign: 'left' } },
          headStyles: {
            halign: 'center',
            valign: 'middle',
            textColor: [93, 109, 164],
            fillColor: [255, 255, 255],
            lineWidth: 0,
          },
          alternateRowStyles: { fillColor: [249, 249, 249] },
        });
        if (element.stockData.length === 0) {
          var noDataMsg = this.translocoService.translate('Grid.NoSurvey');
          doc.setFontSize(10);
          doc.setFontType('normal');
          var noDataMsgWidth =
            (doc.getStringUnitWidth(noDataMsg) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.width - noDataMsgWidth) / 2;
          doc.text(textOffset, doc.autoTable.previous.finalY + 10, noDataMsg);
        }
        //startYCord = doc.autoTable.previous.finalY + 10;
        if (index != Number(this.stockCategoryData.length) - 1) {
          doc.addPage();
        }
      });
      doc.save(
        sessionStorage.getItem('organizationName') + '_' + this.FilenameDate + '.pdf'
      );
    }, 500);
  }
  async DownloadInventoryExcel(surveyTrackId) {
    this.ExcelOrgName = sessionStorage.getItem('organizationName'); // this.orgDetils.organizationName;
    // this.ExcelOrgDate = this.submittedOn;
    // this.FilenameDate = this.submittedOn;
    this.FilenameDate = new DatePipe('en-US').transform(
      this.submittedOn,
      'MM_dd_yyyy'
    );
    await this.getLastSurveyDataById(surveyTrackId);
    const workbook = new Excel.Workbook();
    setTimeout(() => {
      const headerbarHeight = 33;
      const imageHeight = 32;
      const subheaderheight = 28;
      this.stockCategoryData.forEach((element) => {
        element.stockData.sort((a: any, b: any) => {
          if (!a.itemsId.toString().localeCompare(b.itemsId)) {
            return a.description.toString().localeCompare(b.description);
          }
          return a.itemsId.toString().localeCompare(b.itemsId);
        });

        //element.stockData.sort((a, b) => a.description.localeCompare(b.description))
        //var worksheet = workbook.addWorksheet(element.stockTypeName + ' Inventory', {properties : {views:[{ state: 'visible', ySplit: 5, showGridLines:false }]}});
        var worksheet = workbook.addWorksheet(
          element.stockTypeName + ' Inventory',
          {
            properties: {},
            views: [
              {
                state: 'visible',
                ySplit: 5,
                activeCell: 'A1',
                showGridLines: false,
              },
            ],
          }
        );
        worksheet.getColumn('A').width = 18;
        worksheet.getColumn('B').width = 21;
        worksheet.getColumn('C').width = 18;
        worksheet.getColumn('D').width = 18;
        worksheet.getColumn('E').width = 18;
        worksheet.getColumn('F').width = 18;
        worksheet.getColumn('G').width = 18;
        worksheet.getColumn('H').width = 18;
        worksheet.getColumn('I').width = 18;
        worksheet.getColumn('J').width = 18;
        worksheet.getColumn('K').width = 18;
        worksheet.getColumn('L').width = 18;

        const logo = workbook.addImage({
          base64: this.imageURLB64,
          extension: 'png',
        });

        worksheet.addImage(logo, {
          tl: { col: 0, row: 0 },
          ext: { width: 92, height: 40 }
        });
        worksheet.mergeCells('B1', 'I1');
        worksheet.mergeCells('A2', 'I2');
        worksheet.mergeCells('A3', 'I3');
        worksheet.getCell('B1').value =
          this.translocoService.translate('HOME.HomeHeader') +
          ' - ' +
          this.OrgType;

        const headerRow = [];
        element.stockColExcelDef.forEach((h) => {
          headerRow.push(h.header);
        });
        const data = [];
        element.stockData.forEach((tableRow) => {
          const rowData = [];
          element.stockColExcelDef.forEach((h) => {
            Object.keys(tableRow).map((key) => {
              if (key === h.key) {
                if(tableRow[key] === null) {
                  tableRow[key] = '';
              }
                rowData.push(tableRow[key]);
              }
            });
          });
          data.push(rowData);
        });
        worksheet.addRow(headerRow);
        
          data.forEach((tRow) => {
            worksheet.addRow(tRow);
          });
          let rowIndex = 1;
          for (rowIndex; rowIndex <= worksheet.rowCount; rowIndex++) {
            worksheet.getRow(rowIndex).alignment = {
              vertical: 'middle',
              wrapText: true,
            };
            worksheet.getRow(rowIndex).font = {
              name: 'Arial',
              family: 2,
              size: 8,
            };
            if (rowIndex > 4) {
              worksheet.getRow(rowIndex).alignment = {
                indent: 1,
                vertical: 'middle',
                horizontal: 'right',
              };
              worksheet.getRow(rowIndex).height = 25;
              worksheet.getRow(rowIndex).eachCell((cell) => {
                Object.assign(cell, {
                  border: {
                    right: { style: 'dotted', color: { argb: 'A5A2A2' } },
                  },
                });
              });
              if (rowIndex % 2 != 0) {
                worksheet.getRow(rowIndex).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'F9F9F9' },
                };
              }
            }
          }
        worksheet.getColumn('A').alignment = { horizontal: 'left' };
        worksheet.getColumn('B').alignment = { horizontal: 'left' };
        worksheet.getCell('B1').font = {
          name: 'Arial',
          family: 2,
          size: 13,
          bold: true,
        };
        worksheet.getRow(1).height = headerbarHeight;
        worksheet.getCell('A2').value = this.ExcelOrgName;
        worksheet.getCell('A3').value = this.ExcelOrgDate;
        worksheet.getRow(2).height = subheaderheight;
        worksheet.getRow(3).height = subheaderheight;
        worksheet.getCell('A1').alignment = { vertical: 'bottom', horizontal: 'middle' };
        worksheet.getCell('B1').alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getCell('A2').alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getCell('A3').alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(2).font = {
          name: 'Arial',
          family: 2,
          size: 9,
          bold: true,
        };
        worksheet.getRow(3).font = {
          name: 'Arial',
          family: 2,
          size: 8,
          bold: true,
        };

        worksheet.getRow(1).eachCell((cell) =>
          Object.assign(cell, {
            border: {
              top: { style: 'thin' },
              //left: { style: 'thin' },
              bottom: { style: 'thin' },
              //right: { style: 'thin' }
            },
          })
        );
        worksheet.getRow(2).eachCell((cell) =>
          Object.assign(cell, {
            border: {
              top: { style: 'thin' },
              //left: { style: 'thin' },
              //bottom: { style: 'thin' },
              //right: { style: 'thin' }
            },
          })
        );
        worksheet.getRow(3).eachCell((cell) =>
          Object.assign(cell, {
            border: {
              top: { style: 'thin' },
              //left: { style: 'thin' },
              bottom: { style: 'thin' },
              //right: { style: 'thin' }
            },
          })
        );
        // worksheet.getRow(4).eachCell(cell => Object.assign(cell, {border: {
        //     //top: { style: 'thin' },
        //     //left: { style: 'thin' },
        //     bottom: { style: 'thin' },
        //     //right: { style: 'thin' }
        // }}));
        worksheet.getRow(4).font = {
          name: 'Arial',
          family: 2,
          size: 8,
          color: { argb: '545484' },
          bold: true,
        };
        worksheet.getRow(4).alignment = {
          wrapText: true,
          vertical: 'middle',
          indent: 1,
          horizontal: 'center',
        };
        worksheet.getRow(4).height = 55;
        if(data.length === 0) {
            worksheet.mergeCells('A5', 'I5');
            worksheet.getRow(5).height = 45;
            worksheet.getCell('A5').alignment = { vertical: ';middle', horizontal: 'center' };
            worksheet.getCell('A5').value =this.translocoService.translate('Grid.NoSurvey');
            worksheet.getRow(5).font = {
                name: 'Arial',
                family: 2,
                size: 8
            };
        }
      });
      //Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(
          blob,
          this.ExcelOrgName + '_' + this.FilenameDate + '.xlsx'
        );
      });
    }, 500);
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }
}
