<p-messages [(value)]="msgs" class="inventory-msg" tabindex="0"></p-messages>
<div class="container-fluid a-p-20 desktop-grid">
    <div class="mb-2">
        <h1 tabindex="0" (click)="openInstructionDialog()" class="cursorPointer">
            <span>{{ 'HOME.instructionText' | transloco }}</span> <span class="pl-2"><i class="fa fa-plus-circle"
                    aria-hidden="true"></i></span>
            </h1>
    </div>
    <div tabindex="0" class="mb-2 autoSave" *ngIf="autoSaveDate">
        {{ 'Grid.autoSave' | transloco }} {{autoSaveDate}} {{ 'Grid.at' | transloco }} {{autoSaveTime}}
    </div>
    <p-tabView (onChange)="handleTabChange($event)">
        <p-tabPanel [header]="stock.stockTypeName" *ngFor="let stock of stockTypes; let i = index" [selected]="i == 0">

            <div class="d-none d-sm-block prime-grid">
                <p-table #pTable [value]="stock.stockData" (onEditInit)="onEditInit($event, stock)" [scrollable]="false"
                  styleClass="gridClass" scrollHeight="{{height}}" [columns]="cols" [globalFilterFields]="[
                    'type',
                    'description',
                    'onHandQty',
                    'qtyConsumed',
                    'qtyReceived',
                    'expectedQtyConsumed',
                    'expectedDeliveryDate',
                    'expectedQtyNextOrder',
                    'expectedBackOrderDeliveryDateStr',
                    'totalQtyInBackOrderStr'
                ]" editMode="row">
                    <ng-template pTemplate="emptymessage">
                        <div tabindex="0" class="mt-3 emptymessage">
                            {{ 'Grid.NoSurvey' | transloco }}
                        </div>
                    </ng-template>
                    <ng-template pTemplate="caption">
                        <div class="d-inline-block search-box a-mb-10 a-mt-20 a-mr-10 float-left">
                            <i class="pi pi-search"></i>
                            <input tabindex="0" type="text" #globalSearch (keyup)="onKeyup($event,i)" pInputText size="20"
                                placeholder=" {{ 'Grid.search' | transloco }}" (input)="filterGlobal($event,i)"
                                style="width:auto" title="Please type to filter the data">
                            <i tabindex="0" class="pi pi-times closeIcon" (click)="clearGlobalFilter($event.target.value,i)" title=" {{ 'Grid.searchClear' | transloco }}" 
                                *ngIf="xVisible"></i>
                        </div>
                        <div class="d-inline-block a-mb-10 a-mt-20">
                            <button tabindex="0" pButton type="button" class="add-btn" (click)="add(stock)">
                                <span>{{'Grid.newItem' | transloco}}</span>
                            </button>
                        </div>
                        <div class="d-inline-block search-box a-mb-10 a-mt-20 a-ml-10">
                            <button tabindex="0" pButton type="button" class="add-btn" (click)="itemDownload()">
                                <span> {{ 'Grid.DownloadItemsList' | transloco }}</span>
                            </button>
                        </div>
                    </ng-template>
                    <ng-template styleClass="" pTemplate="header" let-columns>
                        <tr>                          
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                              [width]="col.width"
                                ><!-- pTooltip="{{col.tooltip}}" tooltipPosition="bottom"> -->
                                <!-- Using title in the span below for the header instead of pTooltip above in the th for accessibility/speech reader -->
                                <span tabindex="0" [innerHTML]='col.header' title="{{col.tooltip}}"></span>
                                <p-sortIcon *ngIf="col.header != ''" [field]="col.field" tabindex="0" aria-label="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                            </th>   
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                        <tr pEditableRow [ngClass]="{'row-colors': deleteColumnVisible, 'new-row': isNewRow(rowData)}">
                            <td >
                                <div> <!-- Added for p-table editMode="row": Edit, Save/Cancel buttons -->
                                <button tabindex="0" *ngIf="!editing" pButton type="button" title="{{'Grid.editRowData' | transloco}}" pInitEditableRow (click)="onRowEditInit(rowData)" class="remove-btn">
                                    <span>{{'Grid.editRowData' | transloco}}</span> <!-- Edit -->
                                </button>
                                <button tabindex="0" *ngIf="editing" pButton type="button" title="{{'Grid.saveRowData' | transloco}}" pSaveEditableRow (click)="onRowEditSave(rowData)" class="remove-btn">
                                    <span>{{'Grid.saveRowData' | transloco}}</span> <!-- Save -->
                                </button>   
                                
                                </div>
                            </td>
                            <td [ngClass]="{'border-red':checkEdited(rowData,'type',ri)}" pEditableColumn>
                                <p-cellEditor *ngIf="!rowData.inventoryItemId">
                                    <ng-template pTemplate="input">
                                        <p-dropdown tabindex="0" appendTo="body" [options]="types"
                                            (onFocus)="onTypeClick(rowData,$event)"
                                            (onClick)="onTypeClick(rowData,$event)"
                                            (onChange)="onTypeChange(rowData,$event)" [(ngModel)]="rowData.type"
                                            filter="true">
                                            <ng-template let-item pTemplate="selectedItem">
                                                <span tabindex="0" style="vertical-align:middle;">{{item.label}}</span>
                                            </ng-template>
                                            <ng-template let-type pTemplate="item">
                                                <div class="ui-helper-clearfix">
                                                    <div tabindex="0" style="font-size:14px;margin-top:4px">
                                                        {{type.label}}
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="col-content">
                                            <span tabindex="0" pTooltip="{{rowData.type}}" tooltipPosition="top"
                                                tooltipStyleClass="grid-tooltip">
                                                {{rowData.type}}
                                            </span>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                                <div tabindex="0" *ngIf="rowData.inventoryItemId">
                                    {{rowData.type}}
                                </div>
                            </td>

                            <td [ngClass]="{'border-red':checkEdited(rowData,'description',ri)}" pEditableColumn>
                                <p-cellEditor *ngIf="!rowData.inventoryItemId">
                                    <ng-template pTemplate="input">
                                        <p-dropdown tabindex="0" appendTo="body"
                                            (onClick)="fetchDescription(rowData.description,rowData,stock.stockData)"
                                            (onFocus)="onDropdownClick($event,rowData)"
                                            (onChange)="onDropdownClick($event,rowData)"
                                            [options]="descriptions"
                                            [(ngModel)]="rowData.description" filter="true">
                                            <ng-template let-item pTemplate="selectedItem">
                                                <span tabindex="0" style="vertical-align:middle;">{{item.label}}</span>
                                            </ng-template>
                                            <ng-template let-desc pTemplate="item">
                                                <div class="ui-helper-clearfix"
                                                    style="position: relative;height: 25px;">
                                                    <div tabindex="0" style="font-size:14px;margin-top:4px">
                                                        {{desc.label}}
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="col-content">

                                            <span tabindex="0" pTooltip="{{rowData.description}}" tooltipPosition="top"
                                                tooltipStyleClass="grid-tooltip">
                                                {{rowData.description}}
                                            </span>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                                <div tabindex="0" *ngIf="rowData.inventoryItemId">
                                    {{rowData.description}}
                                </div>
                            </td>

                            <td *ngIf="checkVisibleTabBased('onHandQty')"
                                [ngClass]="{'border-red':checkEdited(rowData,'onHandQty',ri)}" pEditableColumn>
                                <p-cellEditor *ngIf="validateRow(rowData,stock.stockData)">
                                    <ng-template pTemplate="input">
                                        <input tabindex="0" (focus)="$event.target.select()" pInputText appAllowPattern allow-pattern="^[0-9]*$" type="text"
                                            [(ngModel)]="rowData.onHandQty"
                                            (ngModelChange)="checkMaxValues(rowData,'onHandQty')" maxlength="10">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="col-content" [ngClass]="{'gridBorderClass':!rowData.onHandQty}">
                                            <span pTooltip="{{rowData.onHandQty}}" tooltipPosition="top"
                                                tooltipStyleClass="grid-tooltip" tabindex="0">
                                                {{rowData.onHandQty | number}}
                                            </span>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>


                            </td>
                            <td *ngIf="checkVisibleTabBased('qtyConsumed')"
                                [ngClass]="{'border-red':checkEdited(rowData,'qtyConsumed',ri)}" pEditableColumn>
                                <p-cellEditor *ngIf="validateRow(rowData,stock.stockData)">
                                    <ng-template pTemplate="input">
                                        <input tabindex="0" (focus)="$event.target.select()" pInputText appAllowPattern allow-pattern="^[0-9]*$" type="text"
                                            [(ngModel)]="rowData.qtyConsumed"
                                            (ngModelChange)="checkMaxValues(rowData,'qtyConsumed')" maxlength="10">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="col-content" [ngClass]="{'gridBorderClass':!rowData.qtyConsumed}">
                                            <span pTooltip="{{rowData.qtyConsumed}}" tooltipPosition="top"
                                                tooltipStyleClass="grid-tooltip" tabindex="0">
                                                {{rowData.qtyConsumed| number}}
                                            </span>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>

                            </td>

                            <td *ngIf="checkVisibleTabBased('expectedQtyConsumed')"
                                [ngClass]="{'border-red':checkEdited(rowData,'expectedQtyConsumed',ri)}"
                                pEditableColumn>
                                <p-cellEditor *ngIf="validateRow(rowData,stock.stockData)">
                                    <ng-template pTemplate="input">
                                        <input tabindex="0" (focus)="$event.target.select()" pInputText type="text" appAllowPattern allow-pattern="^[0-9]*$"
                                            [(ngModel)]="rowData.expectedQtyConsumed"
                                            (ngModelChange)="checkMaxValues(rowData,'expectedQtyConsumed')"
                                            maxlength="10">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="col-content"
                                            [ngClass]="{'gridBorderClass':!rowData.expectedQtyConsumed}">
                                            <span pTooltip="{{rowData.expectedQtyConsumed}}" tooltipPosition="top"
                                                tooltipStyleClass="grid-tooltip" tabindex="0">
                                                {{rowData.expectedQtyConsumed| number}}
                                            </span>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>

                            </td>



                            <td *ngIf="checkVisibleTabBased('expectedQtyNextOrder')"
                                [ngClass]="{'border-red':checkEdited(rowData,'expectedQtyNextOrder',ri)}"
                                pEditableColumn>
                                <p-cellEditor *ngIf="validateDescripiton(rowData,stock.stockData)">
                                    <ng-template pTemplate="input">
                                        <input (focus)="$event.target.select()" pInputText type="text" appAllowPattern allow-pattern="^[0-9]*$"
                                            [(ngModel)]="rowData.expectedQtyNextOrder"
                                            (ngModelChange)="checkMaxValues(rowData,'expectedQtyNextOrder')"
                                            maxlength="10" tabindex="0">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="col-content"
                                            [ngClass]="{'gridBorderClass':!rowData.expectedQtyNextOrder}">
                                            <span pTooltip="{{rowData.expectedQtyNextOrder}}" tooltipPosition="top"
                                                tooltipStyleClass="grid-tooltip" tabindex="0">
                                                {{rowData.expectedQtyNextOrder | number}}
                                            </span>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td *ngIf="checkVisibleTabBased('expectedDeliveryDate')"
                                [ngClass]="{'border-red':checkEdited(rowData,'expectedDeliveryDate',ri)}"
                               pEditableColumn>
                                <p-cellEditor *ngIf="validateDescripiton(rowData,stock.stockData)">
                                    <ng-template pTemplate="input">
                                        <p-calendar tabindex="0" showButtonBar="true" [(ngModel)]="rowData.expectedDeliveryDate"
                                            appendTo="body" [minDate]="todayDate" navigator="true" mode="popup" showOn="button">
                                        </p-calendar>
                                    </ng-template>
                                    <ng-template pTemplate="output">

                                        <div class="col-content"
                                            [ngClass]="{'gridBorderClass':!rowData.expectedDeliveryDate}">
                                            <span pTooltip="{{rowData.expectedDeliveryDate|date:'MM/dd/yyyy'}}"
                                                tooltipPosition="top" tooltipStyleClass="grid-tooltip"
                                                [ngClass]="{'pastDueDate':checkOverDue(rowData.expectedDeliveryDate)}">
                                                <span tabindex="0"> {{rowData.expectedDeliveryDate |date:"MM/dd/yyyy"}} </span>
                                            </span>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>

                            <td *ngIf="checkVisibleTabBased('totalQtyInBackOrder')"
                                [ngClass]="{'border-red':checkEdited(rowData,'totalQtyInBackOrder',ri)}"
                                 pEditableColumn tabindex="0">
                                <p-cellEditor *ngIf="validateDescripiton(rowData,stock.stockData)">
                                    <ng-template pTemplate="input">
                                        <input (focus)="$event.target.select()" pInputText type="text" appAllowPattern allow-pattern="^[0-9]*$"
                                            [(ngModel)]="rowData.totalQtyInBackOrder"
                                            (ngModelChange)="checkMaxValues(rowData,'totalQtyInBackOrder')"
                                            maxlength="10" tabindex="0">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="col-content"
                                            [ngClass]="{'gridBorderClass':!rowData.totalQtyInBackOrder}">
                                            <span pTooltip="{{rowData.totalQtyInBackOrder}}" tooltipPosition="top"
                                                tooltipStyleClass="grid-tooltip" tabindex="0">
                                                {{rowData.totalQtyInBackOrder | number}}
                                            </span>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td *ngIf="checkVisibleTabBased('expectedBackOrderDeliveryDate')"
                                [ngClass]="{'border-red':checkEdited(rowData,'expectedBackOrderDeliveryDate',ri)}"
                                pEditableColumn>
                                <p-cellEditor *ngIf="validateDescripiton(rowData,stock.stockData)">
                                    <ng-template pTemplate="input">
                                        <p-calendar tabindex="0" showButtonBar="true"
                                            [(ngModel)]="rowData.expectedBackOrderDeliveryDate" appendTo="body"
                                            [minDate]="todayDate" navigator="true" mode="popup" showOn="button">
                                        </p-calendar>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="col-content"
                                            [ngClass]="{'gridBorderClass':!rowData.expectedBackOrderDeliveryDate}">
                                            <span tabindex="0" 
                                                pTooltip=" {{rowData.expectedBackOrderDeliveryDate|date:'MM/dd/yyyy'}}"
                                                tooltipPosition="top" tooltipStyleClass="grid-tooltip"
                                                [ngClass]="{'pastDueDate':checkOverDue(rowData.expectedBackOrderDeliveryDate)}">
                                                {{rowData.expectedBackOrderDeliveryDate|date:"MM/dd/yyyy"}}
                                            </span>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>                           
                            <td style="text-align:center;">
                                <button tabindex="0" *ngIf="isNewRow(rowData)" pButton type="button" class="remove-btn"
                                    (click)="onRowEditCancel(stock, rowData)">
                                    <span class="appkiticon icon-delete-fill" tabindex="0"></span>
                                </button>
                            </td>
                        </tr>
                    </ng-template>                   
                </p-table>
            </div>
        </p-tabPanel>

    </p-tabView>

 <div class="a-mt-20 a-mb-10 text-right">
        <button pButton type="button" class="a-btn a-btn-transparent a-btn-lg float-left add-btn mr-2"
            (click)="clearBtn()" tabindex="0">{{ 'HOME.Clear' | transloco }}</button>
           
        <button tabindex="0" class="a-btn a-btn-transparent a-btn-lg save-btn float-right" [disabled]="submitDisabled"
            (click)="submitClicked()">{{ 'HOME.Submit' | transloco }}</button>
          <span class="a-mt-20"></span> <button class="a-btn a-btn-transparent a-btn-lg add-btn mr-2 save-btn saveAsDraft float-right" [disabled]="submitDisabled"
            (click)=" autoSaveData()" tabindex="0">{{ 'HOME.saveAsDraft' | transloco }}</button>

    </div>    
</div>
<p-dialog 
    [(visible)]="display" 
    class="DownloadItemsClass" 
    [modal]="true" 
    [draggable]="false" 
    [resizable]="false"
    [style]="{width: '45%'}" 
    [baseZIndex]="10000"
    [focusTrap]="true">
    <p-header>
        <span class="downloadHeader" tabindex="0">{{ 'Grid.downloadlist' | transloco }} </span>
    </p-header>
    <div>
        <div class="text-center" tabindex="0">{{ 'Grid.downloadlistMsg' | transloco }}</div>
        <div class="text-center" tabindex="0">
            <b> {{ 'Grid.downloadAsPdf' | transloco }} </b> {{ 'Grid.adobe' | transloco }} {{ 'Grid.or' | transloco }}
            <b> {{ 'Grid.downloadCsv' | transloco }}</b>
            {{ 'Grid.MSExcel' | transloco }}
        </div>
        <div class="row mb-3 a-mb-20 a-mt-20 a-mr-5 a-ml-5">
            <div class="col-sm-6" (click)="DownloadPdf()">
                <p-card class="healthCards">
                    <p-header style="font-size:16px">
                        <div class="row m-0">
                            <div class="col-1 p-0 pl-3 mt-0">
                                <i class="fa fa-file-pdf-o"></i>
                            </div>
                            <div class="col-11 pl-3" tabindex="0">
                                <button>
                                    <b>{{'Grid.downloadAsPdf' | transloco }}</b>
                                </button>
                            </div>
                        </div>
                    </p-header>
                    <p style="font-size:13px;" tabindex="0">{{'Grid.pdfMsg' | transloco }}</p>
                </p-card>
            </div>
            <div class="col-sm-6" (click)="DownloadCsv()">
                <p-card class="healthCards">
                    <p-header style="font-size:16px">
                        <div class="row m-0">
                            <div class="col-1 p-0 pl-3 mt-0">
                                <i class="fa fa-file-excel-o"></i>
                            </div>
                            <div class="col-11 pl-3" tabindex="0">
                                <button>
                                    <b>{{'Grid.downloadCsv' | transloco }}</b>
                                </button>
                            </div>
                        </div>
                    </p-header>
                    <p style="font-size:13px;" tabindex="0">{{'Grid.csvMsg' | transloco }}</p>
                </p-card>
            </div>
        </div>
    </div>
    <p-footer>
        <button 
          class="a-btn a-btn-transparent a-btn-lg submit-btn home-popup-startbtn"
          (click)="cancelPopup()"
          tabindex="0">
          {{ "Grid.DONE" | transloco }}
        </button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showIndigenousPopup" class="indige-popup" [modal]="true" [draggable]="false" [resizable]="false"
    [style]="{width: '40%'}" [baseZIndex]="10000">
    <div>
        <div class="a-form-label">
            <h4>
                <span tabindex="0">{{ 'Grid.indigenousTitleOne' | transloco }}
                {{ selectedorgName }}
                {{ 'Grid.indigenousTitleTwo' | transloco }} </span>
                <!-- <span pTooltip="{{ 'Grid.indigenousTooltip' | transloco }}" tooltipPosition="right" 
                    tooltipStyleClass="tooltip-style" tooltipZIndex="10000"> -->
                    <i class="fa fa-info-circle" tabindex="0" title="{{ 'Grid.indigenousTooltip' | transloco }}"></i>
                <!-- </span> -->
            </h4>
            <b tabindex="0" [ngClass]="{'error-label': indigenousValueError}">
                {{ 'Grid.indigenousLabel' | transloco }}
            </b>
        </div>
        <div class="ui-g radio-list">
            <span>
                <p-radioButton tabindex="0" name="group1" value="Yes" label="{{ 'Grid.indigenousYes' | transloco }}"
                    [(ngModel)]="indigenousValue" inputId="opt1">
                </p-radioButton>
            </span>
            <span>
                <p-radioButton tabindex="0" name="group1" value="No" label="{{ 'Grid.indigenousNo' | transloco }}"
                    [(ngModel)]="indigenousValue" inputId="opt2">
                </p-radioButton>
            </span>
            <span>
                <p-radioButton tabindex="0" name="group1" value="Decline" label="{{ 'Grid.indigenousDecline' | transloco }}"
                    [(ngModel)]="indigenousValue" inputId="opt3">
                </p-radioButton>
            </span>
        </div>
    </div>
    <p-footer>
        <button tabindex="0" class="a-btn a-btn-transparent a-btn-lg cancel-btn" (click)="cancelIndigenous()">
            {{ 'Grid.indigenousSkipBtn' | transloco }}
        </button>
        <button class="a-btn a-btn-transparent a-btn-lg submit-btn" (click)="saveIndigenous()" tabindex="0">
            {{ 'Grid.indigenousContinueBtn' | transloco }}
        </button>
    </p-footer>
</p-dialog>