<div class="register-block">
    <div class="container-fluid">
        <div class="col-md-6 offset-md-3">
            <div class="login-logo text-center">
                <img src="./assets/images/logo.png" alt="Government of Ontario" />
            </div>
            <div class="application-name text-center">
                <div>{{ 'EntityScreen.covid19Header1' | transloco }}</div>
            </div>
        </div>

        <div class="col-md-6 offset-md-3 text-left">
            <h1 class="process-name text-center">
                <strong>
                    {{ 'EntityScreen.EntityReport' | transloco }}
                </strong>
            </h1>
            <div class="application-notes a-mb-20 a-mt-10 text-center">
                {{ 'EntityScreen.EntityFillDetails' | transloco }}                
            </div>
            <div class="register-input-group image-control a-mb-20 a-mt-20">
                <div class="a-form-label" [ngClass]="{'error-label': selectedEntityExistsError}">
                    <b>
                        {{ 'EntityScreen.EntityName' | transloco }}  
                    </b>
                    <p style="color:#363636" class="py-1"> {{ 'EntityScreen.EntityNameDesc' | transloco }} </p>
                </div>
                <div class="dropdownclass">
                    <p-dropdown #ddlEntity tabindex="0" [options]="entityList" [(ngModel)]="selectedEntityExists" (keyup.enter)="submit()" 
                        placeholder="{{'REGISTER.pleaseSelect' | transloco }}" [filter]="true" [showClear]="true"
                        [disabled]="selectedEntityDoesnotExists" [virtualScroll]="true" [itemSize]="31" 
                        [ngClass]="{'error-box': selectedEntityExistsError}" (ngModelChange)="onSelectType(ddlEntity)"></p-dropdown>
                </div>                              
            </div>
            <div class="ui-inputgroup check-style a-mb-20">
                <p-checkbox tabindex="0" [(ngModel)]="selectedEntityDoesnotExists" [binary]="true"
                    class="register-chckbox" (click)="checkEntityBlock($event)"
                    label="{{ 'EntityScreen.EntityCheckboxContent' | transloco }}" title="Please check box if you don't see your Entity in the list above">
                </p-checkbox>
            </div>
            <div *ngIf="accessMsg" class="notify-msg">
                {{ 'EntityScreen.UserAccessMsg' | transloco }}
            </div>
            <div *ngIf="rejectMsg" class="notify-msg">
                {{ 'EntityScreen.UserDisabledMsg' | transloco }}
            </div>
            <div *ngIf="submitMsg" class="notify-msg">
                {{ 'EntityScreen.UserSubmitMsg' | transloco }}
            </div>
            <div *ngIf="newOrgErrMsg" class="notify-msg">
                {{ 'EntityScreen.UserSubmissionFailed' | transloco }}
            </div>
            
            <div class="extra-controls" *ngIf="selectedEntityDoesnotExists" (keyup.enter)="submit()">            
                <div class="register-input-group a-mb-20">
                    <div class="a-form-label" [ngClass]="{'error-label': entityNameError}">
                        <b>{{ 'EntityScreen.EntityName' | transloco }}</b>
                    </div>
                    <input tabindex="0" class="a-text-input" id="" [(ngModel)]="entityName"
                        [ngClass]="{'error-box': entityNameError}" title="Please enter the name of the entity">
                </div>                        
                <div class="register-input-group a-mb-20 image-control">
                    <div class="a-form-label" [ngClass]="{'error-label': entityTypeError}">
                        <b>{{ 'EntityScreen.EntityType' | transloco }}</b>
                    </div>
                    <div class="dropdownclass">
                        <p-dropdown tabindex="0" [options]="entityTypeList" [(ngModel)]="entityType"
                        placeholder="{{'REGISTER.pleaseSelect' | transloco }}" optionLabel="orgType"
                        [showClear]="true" [ngClass]="{'error-box': entityTypeError}" [filter]="true"></p-dropdown>
                    </div>
                </div>
                <div class="register-input-group image-control a-mb-20" *ngIf="sectorId === 1">
                    <div class="a-form-label" [ngClass]="{'error-label': selectedLhinError}">
                        <b>
                            {{ 'EntityScreen.EntityLHIN' | transloco }} 
                            <a href="http://www.lhins.on.ca/" target="blank">{{'REGISTER.lhinHelpText1' | transloco }}</a>)
                        </b>
                    </div>
                    <p-multiSelect tabindex="0" class="" [options]="LHINOptions"
                        [ngClass]="{'error-box': selectedLhinError}" appendTo="body" [(ngModel)]="selectedLhin"
                        name="selectedLHIN" defaultLabel="{{'REGISTER.pleaseSelect' | transloco }}"
                        maxSelectedLabels="10">
                    </p-multiSelect>
                </div>                

                <div class="register-input-group" *ngIf="sectorId != 0">
                    <div class="a-form-label" [ngClass]="{'error-label': selectedOrgTemplateErr}">
                        <b>{{'REGISTER.entityType' | transloco }}</b>
                    </div>
                    <div class="row a-mb-20" *ngIf="sectorId == 1">
                        <div class="col-sm-6" (click)="selectEntity(1)">
                            <p-card tabindex="0" class="healthCards" [ngClass]="{'active-bg': orghealthTab}">
                                <p-header style=" font-size:16px">
                                    <div class="row m-0">
                                        <div class="col-1 p-0 pl-3 mt-0"> <i *ngIf="selectedEntity == 1"
                                                class="fa fa-check-circle" style="color:green;padding-right:5px;"></i>
                                        </div>
                                        <div
                                            [ngClass]="{'col-11 pl-2':selectedEntity == 1,'col-12 pl-3':selectedEntity != 1}">
                                            <b>{{'REGISTER.health' | transloco }}</b></div>
                                    </div>

                                </p-header>
                                <p style="font-size:13px; color: #363636; width: 250px;">
                                    {{'REGISTER.forExample' | transloco }}</p>
                                <p class="mb-3">{{'REGISTER.healthExample' | transloco }}</p>
                            </p-card>
                        </div>
                        <div class="col-sm-6" (click)="selectEntity(3)">
                            <p-card tabindex="0" class="healthCards" [ngClass]="{'active-bg': orghealthSupplyTab}">
                                <p-header style=" font-size:16px">
                                    <div class="row m-0">
                                        <div class="col-1 p-0 pl-3 mt-0"> <i *ngIf="selectedEntity == 3"
                                                class="fa fa-check-circle" style="color:green;padding-right:5px;"></i>
                                        </div>
                                        <div
                                            [ngClass]="{'col-11 pl-2':selectedEntity == 3,'col-12 pl-3':selectedEntity !== 3}">
                                            <b>{{'REGISTER.healthSupplies' | transloco }}</b></div>
                                    </div>
                                </p-header>
                                <!-- <p style="font-size:13px; color: #999999; width: 250px;">
                                    {{'REGISTER.forExample' | transloco }}</p>
                                <p class="mb-3">{{'REGISTER.healthExamplec' | transloco }}</p> -->
                            </p-card>
                        </div>
                    </div>

                    <div class="row a-mb-20" *ngIf="sectorId == 2">
                        <div class="col-sm-6" (click)="selectEntity(2)">
                            <p-card tabindex="0" class="healthCards" [ngClass]="{'active-bg': orghealthTab}">
                                <p-header style="font-size:16px">
                                    <div class="row m-0">
                                        <div class="col-1 p-0 pl-3 mt-0"> <i *ngIf="selectedEntity == 2"
                                                class="fa fa-check-circle" style="color:green;padding-right:5px;"></i>
                                        </div>
                                        <div
                                            [ngClass]="{'col-11 pl-2':selectedEntity == 2,'col-12 pl-3':selectedEntity !== 2}">
                                            <b>{{'REGISTER.nonHealth' | transloco }}</b></div>
                                    </div>
                                </p-header>
                                <p style="font-size:13px; color: #363636;">
                                    {{'REGISTER.forExample' | transloco }}</p>
                                <p class="mb-3">{{'REGISTER.nonHealthExample' | transloco }}</p>
                            </p-card>
                        </div>
                        <div class="col-sm-6" (click)="selectEntity(4)">
                            <p-card tabindex="0" class="healthCards" [ngClass]="{'active-bg': orghealthSupplyTab}">
                                <p-header style="font-size:16px">
                                    <div class="row m-0">
                                        <div class="col-1 p-0 pl-3 mt-0"> <i *ngIf="selectedEntity == 4" class="fa fa-check-circle" style="color:green;padding-right:5px;"></i>
                                        </div>
                                        <div
                                            [ngClass]="{'col-11 pl-2':selectedEntity == 4,'col-12 pl-3':selectedEntity !== 4}">
                                            <b>{{'REGISTER.nonHealthSupplies' | transloco }}</b></div>
                                    </div>
                                </p-header>
                            </p-card>
                        </div>
                    </div>
                </div>

                <div class="register-input-group a-mb-20">
                    <div class="a-form-label" [ngClass]="{'error-label': entityAddrError}">
                        <b>
                            {{ 'EntityScreen.EntityAddress' | transloco }} 
                        </b>
                    </div>
                    <p-autoComplete #autoComplete tabindex="0" [(ngModel)]="entityAddr" [suggestions]="addressSuggestion"
                        styleClass="addressAutoComplete" (completeMethod)="searchAddr($event)" title="Please enter the address of the Entity"
                        (onSelect)="onSelectedAddress($event)" field="Text" [forceSelection]="true" [ngClass]="{'error-box': entityAddrError}">
                        <ng-template let-brand pTemplate="item"> 
                            <div class="ui-helper-clearfix">
                                <div>{{brand.Text}}
                                    <span class="ml-2">{{brand.Description}}</span>
                                </div>
                            </div>
                        </ng-template>
                    </p-autoComplete>                    
                </div>
                <div class="row a-mb-20">
                    <div class="col-md-6">
                        <div class="register-input-group a-mb-20 disableInput">
                            <div class="a-form-label" [ngClass]="{'error-label': entityCityError}">
                                <b>
                                    {{ 'EntityScreen.EntityCity' | transloco }} 
                                </b>
                            </div>
                            <input class="a-text-input" id="" disabled [(ngModel)]="entityCity" title="Please enter the city your Entity resides in"
                                [ngClass]="{'error-box': entityCityError}"> 
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="register-input-group a-mb-20 disableInput">
                            <div class="a-form-label" [ngClass]="{'error-label': entityPostalError}">
                                <b>
                                    {{ 'EntityScreen.EntityPostalCode' | transloco }} 
                                </b>
                            </div>
                            <input class="a-text-input" id="" [(ngModel)]="entityPostal" disabled [ngClass]="{'error-box': entityPostalError}" title="Please enter your Entity's postal code"
                            (blur)="validateRegExp('entityPostal')" appAllowPattern allow-pattern="^[a-zA-Z 0-9]*$"
                            maxlength="7">
                        </div>
                    </div>
                </div>  
                <div class="row a-mb-20">
                    <div class="col-md-12">
                        <div class="a-form-label" [ngClass]="{'error-label': IndigenousError}">
                            <b>{{'REGISTER.IndigenousQuestion' | transloco }}</b>
                            <span pTooltip="{{ 'Grid.indigenousTooltip' | transloco }}" tooltipPosition="right" tooltipZIndex="10000">
                                <i class="fa fa-info-circle a-ml-5" aria-hidden="true"></i>
                            </span>  
                        </div>
                        <div class="ui-g radio-list">
                            <span>
                                <p-radioButton tabindex="0" name="group1" value="Yes" label="{{ 'Grid.indigenousYes' | transloco }}" [(ngModel)]="indigenousValue" inputId="opt1">                                    
                                </p-radioButton>
                            </span>
                            <span>
                                <p-radioButton tabindex="0" name="group1" value="No" label="{{ 'Grid.indigenousNo' | transloco }}" [(ngModel)]="indigenousValue" inputId="opt2">                                    
                                </p-radioButton>
                            </span>
                            <span>
                                <p-radioButton tabindex="0" name="group1" value="Decline" label="{{ 'Grid.indigenousDecline' | transloco }}" [(ngModel)]="indigenousValue" inputId="opt3">                                    
                                </p-radioButton>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="a-textarea-footer a-mt-30 a-mb-20"> 
                <div class="forgot-pwd-link text-center d-flex align-items-center justify-content-end">
                    <a tabindex="0" (click)="BacktoEntity()" (keyup.enter)="BacktoEntity()">{{ 'HOME.cancel' | transloco }}</a>              
                    <button class="a-btn a-btn-primary a-btn-xl submit-btn" (click)="submit()" (keyup.enter)="submit()"
                    tabindex="0">{{ 'HOME.Submit' | transloco }}</button>
                </div>                
            </div>            
        </div>
    </div>
</div>