import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebAppService } from 'src/app/web-app.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageComponent } from '../message/message.component';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { SharedService } from '../sharedservice/sharedservice';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EncrDecrService } from '../sharedservice/enc-dec.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    changepassworderror: boolean;
    confirmpaswrderror: boolean;
    newpassworderror: boolean;
    passwordMismatchError: boolean;
    passwordLengthError: boolean;
    newPassword: string;
    confirmPassword: string;
    newCredentials;
    alreadySubmitted = false;
  orgSelected: any;
  sectorType: any;
    private ngSubject: Subject<any> = new Subject<any>();
    constructor(
        private webappService: WebAppService,
        private dialogService: DialogService,
        private router: Router,
        private translocoService: TranslocoService,
        private sharedService: SharedService,
        private encrDecrService: EncrDecrService
    ) {
        this.webappService.getDownTime().pipe(takeUntil(this.ngSubject)).subscribe((status) => {
            if (!status) {
                this.router.navigate(['Login']);
            }
        });
    }
   
  ngOnInit(): void {
    this.sectorType = parseInt(this.encrDecrService.get(sessionStorage.getItem('userSectorType')));
        this.clearFields();
        this.clearErr();
    }

    clearFields() {
        this.newPassword = '';
        this.confirmPassword = '';
        this.newCredentials = {};
    }
    clearErr() {
        this.changepassworderror = false;
        this.confirmpaswrderror = false;
        this.newpassworderror = false;
        this.passwordMismatchError = false;
        this.passwordLengthError = false;
    }

    /**
     * Validating the password ,
     *
     * @memberof ResetPasswordComponent
     */
    ChangePassword() {
        if (!this.alreadySubmitted) {
            this.clearErr();
            let allowApiCall = true;
            if (this.newPassword === '' || this.newPassword === undefined || this.newPassword === null) {
                this.newpassworderror = true;
                allowApiCall = false;
            } else if (this.newPassword.trim() === '') {
                this.newpassworderror = true;
                allowApiCall = false;
            } else {
                this.newpassworderror = false;
            }

            if (this.confirmPassword === '' || this.confirmPassword === undefined || this.confirmPassword === null) {
                this.confirmpaswrderror = true;
                allowApiCall = false;
            } else if (this.confirmPassword.trim() === '') {
                this.confirmpaswrderror = true;
                allowApiCall = false;
            } else {
                this.confirmpaswrderror = false;
            }
            if (this.newpassworderror && this.confirmpaswrderror) {
                this.changepassworderror = true;
            } else {
                this.changepassworderror = false;
            }
            if (!this.newpassworderror && !this.confirmpaswrderror) {
                if (this.newPassword.length >= 8 && this.confirmPassword.length >= 8) {
                    this.passwordLengthError = false;
                    if (this.newPassword === this.confirmPassword) {
                        this.passwordMismatchError = false;
                    } else {
                        this.passwordMismatchError = true;
                        allowApiCall = false;
                    }
                } else {
                    this.passwordLengthError = true;
                    allowApiCall = false;
                }
            }
            if (allowApiCall) {
                this.newCredentials = {
                    newPassword: this.newPassword,
                    confirmPassword: this.confirmPassword
                };
                this.ChangePasswordCred(this.newCredentials);
            }
        }
    }
    /**
     * Passing to api to update the password
     *
     * @param {*} newCredentials
     * @memberof ResetPasswordComponent
     */
    ChangePasswordCred(newCredentials) {
        this.webappService.ChangePassword(newCredentials).then(async (resp) => {
            if (resp.status.toString() === 'true') {
                this.alreadySubmitted = true;
                const popup = this.dialogService.open(MessageComponent, {
                    showHeader: false,
                    closable: false,
                    width: '30%',
                    data: {
                        header: this.translocoService.translate('HOME.PaswrdChangeHeader'),
                        message: this.translocoService.translate('HOME.PaswrdChangeSuccess')
                    }
                });
                popup.onClose.subscribe((data) => {
                    this.orgSelected = sessionStorage.getItem('selectedOrg');
                    sessionStorage.setItem('selectedOrg', resp.isOrganizationSelected);
                    if (this.orgSelected.toLowerCase() !== 'no') {
                      if (this.sectorType === 1 || this.sectorType === "1") { this.router.navigate(['home']); }
                      else { this.router.navigate(['non-health-home']); }
                    }
                    else {
                        this.webappService.getUserOrgDetails().subscribe(async (resp) => {
                            if (resp !== undefined) {
                                if (resp.organizationList.length === 1) {
                                    this.UpdateValidOrg(resp.organizationList[0].organizationId);
                                }
                                else {
                                    this.router.navigate(['entity-selected']);
                                }
                            }
                        });
                    }
                });
            }
        });
    }
    ngOnDestroy() {
        this.ngSubject.next();
        this.ngSubject.complete();
    }
    UpdateValidOrg(orgId) {
        this.webappService.UpdateValidOrg(orgId).then(async (resp) => {
            this.sharedService.currentUser.isOrganizationSelected = 'Yes';
          if (this.sectorType === 1 || this.sectorType === "1") { this.router.navigate(['home']); }
          else { this.router.navigate(['non-health-home']); }
        });
    }
}
