import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveyFormComponent } from './components/survey-form/survey-form.component';
import { LoginComponent } from './components/login/login.component';
import { DataViewComponent } from './components/data-view/data-view.component';
import { GridComponent } from './components/grid/grid.component';
import { HomeComponent } from './components/home/home.component';
import { EntityScreenComponent } from './components/entity-screen/entity-screen.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NavigationGuard } from './service/navigation-guard.service';
import { EntitySelectedComponent } from './components/entity-selected/entity-selected.component';
import { LogComponent } from './components/log/log.component';
import { FaqComponent } from './components/faq/faq.component';
import { MainComponent } from './components/main/main.component';
import { ConfrimSubmitComponent } from './components/confrim-submit/confrim-submit.component';
import { RegisterComponent } from './components/register/register.component';
import { RegistrationMessageComponent } from './components/registration-message/registration-message.component';
import { CreateEntityComponent } from './components/create-entity/create-entity.component';
import { NonHealthHomeComponent } from './components/non-health-home/non-health-home.component';
import { NonHealthFAQComponent } from './components/non-health-faq/non-health-faq.component';


const routes: Routes = [
    { path: '', redirectTo: '/Login', pathMatch: 'full' },
    {
        path: '',
        component: MainComponent,
        children: [
            { path: 'entity-screen', component: EntityScreenComponent },
            { path: 'inventory', component: GridComponent },
            { path: 'home', component: HomeComponent },
            { path: 'survey-form', component: SurveyFormComponent },
            { path: 'log', component: LogComponent },
            { path: 'faq', component: FaqComponent },
          { path: 'confirm-submit', component: ConfrimSubmitComponent },
          { path: 'non-health-home', component: NonHealthHomeComponent },
          { path: 'non-health-faq', component: NonHealthFAQComponent },
        ],
        canActivateChild: [ NavigationGuard ]
    },
    
    { path: 'entity-selected', component: EntitySelectedComponent ,canActivate: [ NavigationGuard ]},
    { path: 'reset-password', component: ResetPasswordComponent },    
    { path: 'Login', component: LoginComponent },
    { path: 'Dataview', component: DataViewComponent },

    { path: 'Register', component: RegisterComponent },
    { path: 'registration-message', component: RegistrationMessageComponent },
    { path: 'forget-password', component: ForgotPasswordComponent },
    { path: 'reset-success', component: ConfrimSubmitComponent },
    { path: 'set-new-password', component: ForgotPasswordComponent },
    { path: 'create-entity', component: CreateEntityComponent },
    { path: 'success', component: ConfrimSubmitComponent }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}
