import { Injectable, ErrorHandler } from '@angular/core';
import { ApplicationInsightService } from './application-insight.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
    constructor(private applicationService: ApplicationInsightService) {}
    handleError(e: Error) {
        //
        console.error(e);
        this.applicationService.trackException(e);
    }
}
