import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { WebAppService } from 'src/app/web-app.service';
import { TranslocoService } from '@ngneat/transloco';
import { SharedService } from '../sharedservice/sharedservice';
import { BrowserDetectService } from '../../service/browser-detect.service';
import { take } from 'rxjs/operators';
import { timer } from 'rxjs';
import { EncrDecrService } from '../sharedservice/enc-dec.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: [ './main.component.css' ]
})
export class MainComponent implements OnInit {
    visibleSidebar: boolean;
    selectedorgName: string;
    showChangeOrg: any;
    userName;
    currentPath: string;
    sectorType: any;
    constructor(
        private router: Router,
        private confirmationService: ConfirmationService,
        public webAppService: WebAppService,
        private translocoService: TranslocoService,
        private sharedService: SharedService,
        private browserService: BrowserDetectService,
        private encrDecrService: EncrDecrService
    ) {
        this.webAppService.organisationName.subscribe((name) => {
            setTimeout(() =>{
                this.selectedorgName = name;
                if (name !== '') {
                    this.showChangeOrg = true;
                } else {
                    this.showChangeOrg = false;
                }

            });
        });

        this.sharedService.currentPath.subscribe((path) => {
            this.currentPath = path;
        });
    }

    ngOnInit(): void {
        this.sectorType = (this.encrDecrService.get(sessionStorage.getItem('userSectorType')));

        this.userName = sessionStorage.getItem('userName');
        const bInfo = this.browserService.getBrowser();

        const browserInfo = {
            browserName: bInfo.browser.name,
            browserVersion: bInfo.browser.version.toString(),
            operatingSystem: bInfo.os,
            screenZoomedPercentage: bInfo.browserZoom.toString(),
            screenSize: bInfo.screen_resolution
        };
        this.webAppService.browserInfo(browserInfo).pipe(take(1)).subscribe((data) => {});
    }
    naviagateTo(path) {
        // if (path === 'home') {
        //     this.selectedorgName = '';
        //     this.showChangeOrg = false;
        // }
        this.visibleSidebar = false;
        var elements= document.getElementsByClassName('menuHeader');
    
        for (var i = 0, len = elements.length; i < len; i++) {
        elements[i].setAttribute("tabindex", "-1");   
      }

        this.router.navigate([ path ]);
        
        

    }

    menutoggle(){  
        
         var elements= document.getElementsByClassName('menuHeader');
    
            for (var i = 0, len = elements.length; i < len; i++) {
            elements[i].setAttribute("tabindex", "0");   
          }
        }

        menutoggle1()
        {
            
            var elements= document.getElementsByClassName('menuHeader');
    
            for (var i = 0, len = elements.length; i < len; i++) {
            elements[i].setAttribute("tabindex", "-1");   
          }
        }
        
     


    logout() {
        this.confirmationService.confirm({
            header: this.translocoService.translate('Grid.Confirmation'),
            message: this.translocoService.translate('Grid.ConfirmLogout'),
            acceptLabel: this.translocoService.translate('Grid.YES'),
            rejectLabel: this.translocoService.translate('Grid.NO'),
            acceptIcon: '',
            rejectIcon: '',
            accept: () => {
                this.sharedService.updateAutoSave(Math.round(Math.random() * 1000000));
                timer(1000).pipe(take(1)).subscribe((data) => {
                sessionStorage.clear();
                window.location.reload();
                this.router.navigate([ 'Login' ]);
                });
            }
        });
    }
    changeOrg() {
        if (this.selectedorgName !== '') {
            this.confirmationService.confirm({
                header: this.translocoService.translate('Grid.Confirmation'),
                message:
                    '<div class="change-msg"><h4>' +
                    this.translocoService.translate('Grid.changeOrgDesc') +
                    '</h4></div>',
                acceptLabel: this.translocoService.translate('Grid.YES'),
                rejectLabel: this.translocoService.translate('Grid.NO'),
                acceptIcon: '',
                rejectIcon: '',
                accept: () => {
                    this.sharedService.updateInventoryEdited(false);
                    this.webAppService.updateOrganisationName('', false);
                    if (this.sectorType === '1') {
                        this.router.navigate([ 'home' ]);
                    } else {
                        this.router.navigate([ 'non-health-home' ]);
                    }
                }
            });
        } else {
            if (this.sectorType === '1') {
                this.router.navigate([ 'home' ]);
            } else {
                this.router.navigate([ 'non-health-home' ]);
            }
        }
    }
}