<div class="success-block" *ngIf="componentType == 1">
    <div class="container-fluid text-center">
        <i class="fa fa-check-circle"></i>
        <h4>{{ 'Grid.resetThanks' | transloco }}</h4>
        <h5>
            {{msgDetails}}
        </h5>
        <button (click)="logout()" tabindex="0"  (keyup.enter)="logout()" class="a-btn a-btn-xl login-btn moh-btn">{{ 'Grid.loginBack' | transloco }}</button>
        <!-- <div class="a-mt-40 text-center">
            <app-login-footer></app-login-footer>
        </div> -->
    </div>      
</div>

<div class="success-block submit-success" *ngIf="componentType == 2">
    <div class="container-fluid text-center">
    <i class="fa fa-check-circle"></i>
    <h4>
      {{ 'Grid.ThankYouMsg' | transloco }}
    </h4>
    <div class="application-text newlogin-width" style="color: #999999;">
      <div class="application-notes">
        {{ 'Grid.anotherEntityMsg' | transloco }}
      </div>
      <div class="application-notes">
        {{ 'Grid.downloadLog' | transloco }}
      </div>
      <div class="application-notes">
        {{ 'Grid.hereAs' | transloco }}
        <u><span class="cursorPointer" (click)="DownloadInventoryPDF(surveyTrackId)"> {{ 'Grid.pdf' | transloco }}</span> </u>{{ 'Grid.or' | transloco }} <u> <span class="cursorPointer" (click)="DownloadInventoryExcel(surveyTrackId)">{{ 'Grid.csv' | transloco }}</span></u>
      </div>
    </div>

    <!-- <h5>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit
  </h5> -->
    <button (click)="logout()" tabindex="0" style="min-width: 18rem !important;" class="a-btn a-btn-xl login-btn moh-btn">{{ 'Grid.LogmeOut' | transloco }}</button>
    <!--<div class="">
      <button (click)="loadGrid()" class="a-btn a-btn-xl login-btn moh-btn" style="margin-top:15px !important">{{ 'Grid.submitAnotherEntity' | transloco }}</button>
    </div>-->
    <div class="search-box a-mb-10 a-mt-20" *ngIf="showAddAnotherEntity">
      <button pButton type="button" tabindex="0" class="add-btn" (click)="loadGrid()"  style="min-width: 18rem !important;padding: 0.8em 1.5em !important;height: 43px;margin: 0 auto;">
        <span> {{ 'Grid.submitAnotherEntity' | transloco }}</span>
      </button>
    </div>

    <!--<div class="a-mt-20">
    <a (click)="loadGrid()" tabindex="0" (keyup.enter)="loadGrid()">
      {{ 'Grid.anotherEntity' | transloco }}
    </a>
  </div>-->
  </div>
</div>


<div class="success-block" *ngIf="componentType == 3">
    <div class="container-fluid text-center">
        <i class="fa fa-check-circle"></i>
        <h4> {{ 'HOME.ThankYouTempPwdMsg' | transloco }} </h4>
        <h5>
            {{msgDetails}}
        </h5>
        <button (click)="logout()" tabindex="0" class="a-btn a-btn-xl login-btn moh-btn">{{ 'Grid.loginBack' | transloco }}</button>
        <!-- <div class="a-mt-40 text-center">
            <app-login-footer></app-login-footer>
        </div> -->
    </div>      
</div>


<div class="success-block" *ngIf="componentType == 4">
    <div class="col-md-8 col-offset-2 text-center">
        <i class="fa fa-check-circle"></i>
        <h4>
            {{ 'EntityScreen.EntityCreateMsg' | transloco }}
        </h4>
        <h5>
            {{ 'EntityScreen.EntityHealthMsg' | transloco }} 
            <b>{{ 'EntityScreen.EntityHealthProcessMsg' | transloco }}</b> 
            {{ 'EntityScreen.EntityHealthAppreciateMsg' | transloco }}             
        </h5>
        <h5>                
            {{ 'EntityScreen.EntityHealthMsg2' | transloco }}
        </h5>
        <div>
            <button tabindex="0" (click)="done()" class="a-btn a-btn-xl login-btn moh-btn pr-btn">
                {{ 'EntityScreen.Done' | transloco }}
            </button>
        </div>
        <div>
            <button tabindex="0" (click)="addEntity()" class="a-btn a-btn-xl login-btn moh-btn sec-btn">
                {{ 'EntityScreen.AddEntity' | transloco }}
            </button>
        </div>
    </div>      
</div>

<div class="success-block health-block" *ngIf="componentType == 5">
    <div class="col-md-6 col-offset-2 text-center">
        <i class="fa fa-check-circle"></i>
        <h4>
            {{ 'EntityScreen.EntityNonHealthMsg1' | transloco }}            
            {{msgDetails}}
            {{ 'EntityScreen.EntityNonHealthMsg2' | transloco }}
        </h4>
        <div>
            <button tabindex="0" (click)="done()" class="a-btn a-btn-xl login-btn moh-btn pr-btn">
                {{ 'EntityScreen.Done' | transloco }}
            </button>
        </div>
        <div>
            <button tabindex="0" (click)="addEntity()" class="a-btn a-btn-xl login-btn moh-btn sec-btn">
                {{ 'EntityScreen.AddEntity' | transloco }}
            </button>
        </div>
    </div>      
</div>


<div class="success-block" *ngIf="componentType == 6">
    <div class="col-md-8 col-offset-2 text-center">
        <i class="fa fa-check-circle"></i>
        <h4>        
            {{ 'EntityScreen.NewEntityTitle' | transloco }}         
        </h4>
        <h5>            
            {{ 'EntityScreen.NewEntityMsgFirst' | transloco }}
        </h5>
        <h5>
            {{ 'EntityScreen.NewEntityMsgSecond' | transloco }} 
            <b>
                {{ 'EntityScreen.NewEntityProcessMsg' | transloco }} 
            </b>                      
        </h5> 
        <h5>
            {{ 'EntityScreen.NewEntityMsgThird' | transloco }}            
        </h5>
        <div>
            <button tabindex="0" (click)="done()" class="a-btn a-btn-xl login-btn moh-btn pr-btn">
                {{ 'EntityScreen.Done' | transloco }}
            </button>
        </div>
        <div>
            <button tabindex="0" (click)="addEntity()" class="a-btn a-btn-xl login-btn moh-btn sec-btn">
                {{ 'EntityScreen.AddEntity' | transloco }}
            </button>
        </div>
    </div>      
</div>


<div class="success-block" *ngIf="componentType == 7">
    <div class="col-md-8 col-offset-2 text-center">
        <i class="fa fa-check-circle"></i>
        <h4>        
            {{ 'EntityScreen.submittedMsg' | transloco }}             
        </h4>
        <h5>            
            {{ 'EntityScreen.firstPersonMsg' | transloco }}             
        </h5>
        <div>
            <button tabindex="0" (click)="done()" class="a-btn a-btn-xl login-btn moh-btn pr-btn">
                {{ 'EntityScreen.Done' | transloco }}
            </button>
        </div>
        <div>
            <button tabindex="0" (click)="addEntity()" class="a-btn a-btn-xl login-btn moh-btn sec-btn">
                {{ 'EntityScreen.AddEntity' | transloco }}
            </button>
        </div>
    </div>  
</div>
