import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { WebAppService } from 'src/app/web-app.service';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageComponent } from '../message/message.component';
import { TranslocoService } from '@ngneat/transloco';

import { take, filter } from 'rxjs/operators';
import { SharedService } from '../sharedservice/sharedservice';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { EncrDecrService } from '../sharedservice/enc-dec.service';

@Component({
    selector: 'app-entity-selected',
    templateUrl: './entity-selected.component.html',
    styleUrls: [ './entity-selected.component.css' ]
})
export class EntitySelectedComponent implements OnInit {
    oranizationTypes1 = [];
    cards;
    selectOrganizationType = [];
    cols = [];
    selectedOrganizationType;
  entitycols = [];
  sectorType: any;
    constructor(
        private webappService: WebAppService,
        private router: Router,
        private dialogService: DialogService,
        private translocoService: TranslocoService,
        private sharedService: SharedService,
        private confirmationService: ConfirmationService,
        private encrDecrService: EncrDecrService
    ) {}
    @ViewChild('globalSearch') userInput: ElementRef;
    @ViewChild('OrgTable') pTableComponent: Table;
    xVisible = false;
  ngOnInit(): void {
    this.sectorType = parseInt(this.encrDecrService.get(sessionStorage.getItem('userSectorType')));
        this.getUserOrgDetails();
        if (!this.sharedService.currentUser) {
            sessionStorage.clear();
            this.router.navigate([ 'Login' ]);
        } else if (this.sharedService.currentUser.isOrganizationSelected.toLowerCase() !== 'no') {
          if (this.sectorType === 1) {  this.router.navigate(['home']);   }
          else {   this.router.navigate(['non-health-home']);  }
        }
    }
    getUserOrgDetails() {
        this.oranizationTypes1 = [];
        this.webappService.getUserOrgDetails().pipe(take(1)).subscribe(async (resp) => {
            let UserOrgDetails;
            this.oranizationTypes1 = [];
            resp.organizationList.forEach((element) => {
                const lastDate = new DatePipe('en-US').transform(element.lastModified, 'MM/dd/yyyy');
                const lastDateTime = new DatePipe('en-US').transform(element.lastModified, 'hh:mm:ss a');
                let lastMod = lastDate + ' at ' + lastDateTime;
                let timeInMs = 0;
                if (element.lastModified.includes('0001')) {
                    lastMod = '';
                } else {
                    timeInMs = new Date(element.lastModified).getTime();
                }
                UserOrgDetails = {
                    organizationId: element.organizationId,
                    organizationName: element.organizationName,
                    organizationAddress: element.organizationAddress,
                    organizationType: element.organizationType,
                    postalCode: element.postalCode,
                    lastModified: lastMod,
                    timeInMs: timeInMs
                };
                this.oranizationTypes1.push(UserOrgDetails);
            });
            this.columnHeaders();
            this.translocoService.events$
                .pipe(filter((e: any) => e.type === 'translationLoadSuccess'))
                .subscribe(() => {
                    this.columnHeaders();
                });
            this.translocoService.langChanges$.subscribe(() => {
                this.columnHeaders();
            });
        });
    }
    columnHeaders() {
        this.cols = [
            {
                field: 'organizationName',
                header: this.translocoService.translate('EntityScreen.OrgName'),
                width: '20%',
                sortField: 'organizationName'
            },
            {
                field: 'organizationType',
                header: this.translocoService.translate('EntityScreen.OrganizationType'),
                width: '12vw',
                contentWidth: '12vw',
                sortField: 'organizationType'
            },
            {
                field: 'organizationAddress',
                header: this.translocoService.translate('EntityScreen.Address'),
                width: '20%',
                sortField: 'organizationAddress'
            },

            {
                field: 'postalCode',
                header: this.translocoService.translate('EntityScreen.postalCode'),
                width: '20%',
                sortField: 'postalCode'
            },
            {
                field: 'lastModified',
                header: this.translocoService.translate('EntityScreen.LastSubmitted'),
                width: '20%',
                sortField: 'timeInMs'
            }
        ];
    }
    Next() {
        if (this.selectOrganizationType.length === 0) {
            const popup = this.dialogService.open(MessageComponent, {
                showHeader: false,
                closable: false,
                width: '40%',
                data: {
                    header: this.translocoService.translate('EntityScreen.popupHeader'),
                    message: this.translocoService.translate('EntityScreen.popupInfo')
                }
            });
        } else {
            const popup = this.confirmationService.confirm({
                header: this.translocoService.translate('EntityScreen.verifyPopUpHeader'),
                message: this.translocoService.translate('EntityScreen.verifyPopUpMessage'),
                acceptLabel: this.translocoService.translate('Grid.YES'),
                rejectLabel: this.translocoService.translate('Grid.NO'),
                accept: () => {
                    this.UpdateValidOrg({ organizationId: this.selectOrganizationType.map((x) => x.organizationId) });
                }
            });
        }
    }

    UpdateValidOrg(orgId) {
        this.webappService.UpdateValidOrg(orgId).then(async (resp) => {
            this.sharedService.currentUser.isOrganizationSelected = 'Yes';
          if (this.sectorType === 1) { this.router.navigate(['home']); }
          else { this.router.navigate(['non-health-home']); }
        });
    }

    // Global Search Functionalities Start
    onKeyup(event) {
        if (this.userInput.nativeElement.value) {
            this.xVisible = true;
        } else {
            this.xVisible = false;
        }
    }
    clearGlobalFilter(a) {
        this.pTableComponent.reset();
        this.userInput.nativeElement.value = '';
        this.xVisible = false;
    }
    // Global Search Functionalities End
}
