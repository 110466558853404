import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { WebAppService } from 'src/app/web-app.service';
import { Router } from '@angular/router';
import { MessageComponent } from '../message/message.component';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, timer } from 'rxjs';
import { take, takeUntil, elementAt } from 'rxjs/operators';
import { SharedService } from '../sharedservice/sharedservice';
import { AutoComplete } from 'primeng/autocomplete';
import { UtilityService } from 'src/app/service/utility-service.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: [ './register.component.css' ]
})
export class RegisterComponent implements OnInit, OnDestroy {
    showRegistrationForm = false;
    FirstNameErr: boolean;
    FirstName: any;
    LastName: any;
    LastNameErr: boolean;
    RespondentTitle: any;
    RespondentTitleErr: boolean;
    addressSuggestion = [];
    EmailId: any;
    EmailErr: boolean;
    selectedOrganization: any;
    selectedOrganizationErr: boolean;
    OrgName: any;
    OrgNameErr: boolean;
    validationSucces: boolean;
    OrganizationAddress: any;
    OrganizationAddressErr: boolean;
    OrganizationCity: any;
    OrganizationCityErr: boolean;
    OrganizationPostal: any;
    OrganizationPostalErr: boolean;
    selectedLHIN: any;
    selectedLHINErr: boolean;
    selectedorganizationType: any;
    selectedorganizationTypeErr: boolean;
    blocked: boolean;
    healthTabsOpen: boolean;
    NonHealthTabsOpen: boolean;
    healthSupplyOpen: boolean;
    selectedorgName: any;
    LHINOptions: { label: string; value: number }[];
    oranizationTypes: { name: string; value: number }[];
    oranizationList: any[];
    organizationDoesnotExists = false;
    sectorId = 0;
    oranizationTypeList: any[];
    selectedTemplate: any;
    showRedirection = false;
    @ViewChild('autoComplete', { static: false })
    autoComplete: AutoComplete;
    isNewUserRegistration = true;
    orghealthTab: boolean;
    orghealthSupplyTab: boolean;
    selectedEntity = 0;
    sectorErr: boolean;
    selectedOrgTemplateErr: boolean;
    indigenousValue: string;
    IndigenousError = false;

    private ngSubject: Subject<any> = new Subject<any>();
    constructor(
        private webappService: WebAppService,
        private router: Router,
        private dialogService: DialogService,
        private confirmationService: ConfirmationService,
        private translocoService: TranslocoService,
        private sharedService: SharedService,
        private utilService: UtilityService
    ) {
        this.webappService.isNewRegistartion.pipe(takeUntil(this.ngSubject)).subscribe((status) => {
            this.isNewUserRegistration = status;
        });
        this.webappService.getDownTime().pipe(takeUntil(this.ngSubject)).subscribe((status) => {
            if (!status) {
                this.router.navigate([ 'Login' ]);
            }
        });
    }

    ngOnInit(): void {
        // this.selectedorgName = sessionStorage.getItem('organizationName');
        this.getOrganizationTypes();
        this.getOrganizationsListBySector();
        this.oranizationTypes = [
            { name: 'PWC', value: 1 },
            { name: 'Infosys', value: 2 },
            { name: 'Test', value: 3 }
        ];
        this.getLHIN();
    }
    dropDownKeyUp() {
        console.log('true')
        document.getElementById('dropdown-test').click();
    }
    /**
   *
   *
   * @memberof RegisterComponent
   */
    getLHIN() {
        this.webappService.getLHIN().pipe(take(1)).subscribe((lhinData) => {
            this.LHINOptions = lhinData.locationData.map((e) => {
                return {
                    label: e.locationData,
                    value: e.lhinId
                };
            });
        });
    }
    /**
   * Retrieve organisation types from API
   *
   * @param {*} organization type
   * @memberof RegisterComponent
   */

    getOrganizationTypes() {
        this.oranizationTypeList = [];
        this.webappService.getOrgTypes().then(async (resp) => {
            this.oranizationTypeList = resp.organizationTypeList.sort((a, b) => (a.orgType > b.orgType ? 1 : -1));
        });
    }
    /**
   * Retrieve organisation types from API
   *
   * @param {*} organization type
   * @memberof RegisterComponent
   */

    getOrganizationsListBySector(organizationType = 1) {
        this.oranizationList = [];
        const oranizationListTemp = [];
        this.webappService.getOrdListBySectors(this.sectorId).then(async (resp) => {
            resp.forEach((element) => {
                const orgDetails = {
                    label: element.organizationName + (element.orgType !== null ? ' ( ' + element.orgType + ')' : ''),
                    value: element.organizationId,
                    stripAccent: ''
                };
                orgDetails.stripAccent = this.utilService.stripAccent(orgDetails.label);
                oranizationListTemp.push(orgDetails);
            });
            this.oranizationList = oranizationListTemp.sort(
                (a, b) => (a.stripAccent.toLowerCase() > b.stripAccent.toLowerCase() ? 1 : -1)
            );
        });
    }
    /**
   * Show confirmation pop up on registration form submission
   *
   * @param {*} submit object
   * @memberof RegisterComponent
   */
    showConfirmationPopup(registerObj) {
        this.confirmationService.confirm({
            message: this.translocoService.translate('REGISTER.confirmRegistration'),
            acceptLabel: this.translocoService.translate('Grid.YES'),
            rejectLabel: this.translocoService.translate('Grid.NO'),
            accept: () => {
                this.submitRegistration(registerObj);
            }
        });
    }
    checkEmail() {
        if (this.ValidateEmail(this.EmailId)) {
            this.EmailErr = true;
        } else {
            this.EmailErr = false;
            this.webappService.VerifyEmail(this.EmailId).then((resp) => {
                if (resp.allowAccess === false) {
                    this.showRedirection = true;
                } else {
                    this.showRedirection = false;
                    this.showRegistrationForm = true;
                }
            });
        }
    }
    /**
   * Passing the data to api to register user
   *
   * @param {*} submit object
   * @memberof RegisterComponent
   */
    async submitRegistration(registerObj) {
        if (!this.organizationDoesnotExists) {
            this.webappService.userRegistration(registerObj).then((resp) => {
                if (resp.status === true) {
                    if (resp.message === 'Success-Notified to Organization') {
                        if (this.healthTabsOpen) {
                            this.webappService.updateIsNewRegistration(true);
                        } else {
                            this.webappService.updateIsNewRegistration(false);
                            const selectedOrgName = this.oranizationList.find(
                                (e) => e.value === this.selectedOrganization
                            );
                            const sOrgName = selectedOrgName.label;
                            this.webappService.updateNonHealthOrgName(sOrgName);
                        }
                    }
                    if (resp.message === 'Success-Direct Access Provided') {
                        this.webappService.updateIsFirstRegistration();
                    }

                    this.router.navigate([ 'registration-message' ]);
                } else {
                    const popup = this.dialogService.open(MessageComponent, {
                        showHeader: false,
                        closable: false,
                        width: '40%',
                        data: {
                            header: this.translocoService.translate('REGISTER.registrationFailedHeader'),
                            message: this.translocoService.translate('REGISTER.registrationFailedMessage')
                        }
                    });
                }
            });
        } else {
            this.webappService.userRegistrationWithNewOrg(registerObj).then((resp) => {
                if (resp.status === true) {
                    this.webappService.updateIsNewOrgRegistration();
                    this.router.navigate([ 'registration-message' ]);
                } else {
                    const popup = this.dialogService.open(MessageComponent, {
                        showHeader: false,
                        closable: false,
                        width: '40%',
                        data: {
                            header: this.translocoService.translate('REGISTER.registrationFailedHeader'),
                            message: this.translocoService.translate('REGISTER.registrationFailedMessage')
                        }
                    });
                }
            });
        }
    }
    /**
   * Validating all the registration fields
   *
   * @memberof RegisterComponent
   */

    validateForm() {
        this.FirstNameErr = false;
        this.LastNameErr = false;
        this.RespondentTitleErr = false;
        this.EmailErr = false;
        this.selectedOrganizationErr = false;
        this.sectorErr = false;

        this.FirstNameErr = this.emptyUndefinedNullCheck(this.FirstName);
        this.LastNameErr = this.emptyUndefinedNullCheck(this.LastName);
        this.RespondentTitleErr = this.emptyUndefinedNullCheck(this.RespondentTitle);
        this.sectorErr = this.sectorId === 0 ? true : false;
        this.EmailErr = this.ValidateEmail(this.EmailId);

        const orgId = [];
        if (this.organizationDoesnotExists === false) {
            if (this.selectedOrganization === undefined || this.selectedOrganization === null) {
                this.selectedOrganizationErr = true;
            } else {
                orgId.push(this.selectedOrganization);
            }

            if (
                !this.FirstNameErr &&
                !this.LastNameErr &&
                !this.RespondentTitleErr &&
                !this.selectedOrganizationErr &&
                !this.EmailErr &&
                !this.sectorErr
            ) {
                const submitObj = {
                    title: this.RespondentTitle,
                    firstName: this.FirstName,
                    middleName: '',
                    lastName: this.LastName,
                    emailId: this.EmailId,
                    sectorId: this.sectorId,
                    organizationId: orgId
                };
                this.showConfirmationPopup(submitObj);
            }
        } else {
            this.selectedLHINErr = false;
            this.OrganizationAddressErr = false;
            this.OrganizationCityErr = false;
            this.OrganizationPostalErr = false;
            this.selectedOrgTemplateErr = false;
            this.selectedorganizationTypeErr = false;
            this.OrgNameErr = false;
            this.IndigenousError = false;

            this.OrgNameErr = this.emptyUndefinedNullCheck(this.OrgName);
            //  this.selectedorganizationTypeErr = (this.selectedorganizationType.length==0 ? true: false);
            if (this.OrganizationAddress) {
                this.OrganizationAddressErr = this.emptyUndefinedNullCheck(this.OrganizationAddress.Text);
            } else {
                this.OrganizationAddressErr = true;
            }

            this.OrganizationCityErr = this.emptyUndefinedNullCheck(this.OrganizationCity);
            this.OrganizationPostalErr = this.emptyUndefinedNullCheck(this.OrganizationPostal);
            this.selectedOrgTemplateErr = this.selectedEntity === 0 ? true : false;
            this.IndigenousError = this.emptyUndefinedNullCheck(this.indigenousValue);

            if (
                (this.NonHealthTabsOpen === undefined || this.healthTabsOpen === undefined) &&
                this.selectedLHIN === undefined
            ) {
                this.selectedLHINErr = true;
            } else if (this.selectedLHIN !== undefined && this.NonHealthTabsOpen === false) {
                if (this.selectedLHIN.length > 0) {
                    this.selectedLHINErr = false;
                } else {
                    this.selectedLHINErr = true;
                }
            }

            if (this.selectedorganizationType === undefined || this.selectedorganizationType === null) {
                this.selectedorganizationTypeErr = true;
            }

            if (
                !this.FirstNameErr &&
                !this.LastNameErr &&
                !this.RespondentTitleErr &&
                !this.EmailErr &&
                !this.OrgNameErr &&
                !this.selectedorganizationTypeErr &&
                !this.selectedLHINErr &&
                !this.OrganizationAddressErr &&
                !this.OrganizationCityErr &&
                !this.OrganizationPostalErr &&
                !this.selectedOrgTemplateErr &&
                !this.IndigenousError
            ) {
                const submitObj = {
                    title: this.RespondentTitle,
                    firstName: this.FirstName,
                    middleName: '',
                    lastName: this.LastName,
                    emailId: this.EmailId,
                    sectorId: this.sectorId,
                    organizationName: this.OrgName,
                    organizationAddress: this.OrganizationAddress.Text,
                    organizationCity: this.OrganizationCity,
                    organizationPostalCode: this.OrganizationPostal,
                    organizationTypeId: this.selectedorganizationType.orgId,
                    lhinID: this.selectedLHIN,
                    templateId: this.selectedEntity,
                    indigenousCompany: this.indigenousValue
                };
                this.showConfirmationPopup(submitObj);
            }
        }
    }

    selectEntity(id) {
        if (id === 1 || id === 2) {
            this.orghealthTab = true;
            this.orghealthSupplyTab = false;
        } else if (id === 3 || id === 4) {
            this.orghealthTab = false;
            this.orghealthSupplyTab = true;
        }
        this.selectedEntity = id;
    }
    /**
   * Card Click Event
   *
   * @param {*} orgId
   * @memberof RegisterComponent
   */
    newchangeOrganization(sectorId) {
        const notApplicable = this.LHINOptions.find((e) => {
            return e.label.toLowerCase() === 'not applicable';
        });

        this.orghealthSupplyTab = false;
        this.orghealthTab = false;
        this.selectedEntity = 0;
        this.sectorId = sectorId;
        if (sectorId === 1) {
            this.NonHealthTabsOpen = false;
            this.healthTabsOpen = true;
            this.selectedLHIN = [];
        } else if (sectorId === 2) {
            this.NonHealthTabsOpen = true;
            this.healthTabsOpen = false;
            if (notApplicable) {
                this.selectedLHIN = [ notApplicable.value ];
            }
        }
        this.selectedOrganization = null;
        this.getOrganizationsListBySector(sectorId);
    }

    selectSector(val) {}

    /**
   * Validate null/empty/undefined
   *
   * @param {*} conntrol
   * @memberof RegisterComponent
   */

    emptyUndefinedNullCheck(control) {
        try {
            if (control === '' || control === undefined || control === null) {
                return true;
            } else if (control.trim() === '') {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }
    /**
   * Validate Email patter
   *
   * @param {*} email string
   * @memberof RegisterComponent
   */

    ValidateEmail(mail) {
        if (!this.emptyUndefinedNullCheck(this.EmailId)) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
    /**
   * On click of cancel navigate to login
   *
   * @memberof ForgotPasswordComponent
   */
    BacktoLogin() {
        this.router.navigate([ 'Login' ]);
    }
    showForgotPassword() {
        this.router.navigate([ 'forget-password' ]);
    }

    checkOrgExist(event) {
        if (this.organizationDoesnotExists === true) {
            this.selectedOrganization = null;
            this.selectedOrganizationErr = false;
        } else {
            this.selectedLHINErr = false;
            this.OrganizationAddressErr = false;
            this.OrganizationCityErr = false;
            this.OrganizationPostalErr = false;
            this.selectedOrgTemplateErr = false;
            this.selectedorganizationTypeErr = false;
            this.OrgNameErr = false;
            this.IndigenousError = false;
        }
    }
    /**
   * To validate the postal api
   *
   * @param {*} value
   * @memberof RegisterComponent
   */
    validateRegExp(value) {
        const regExp = new RegExp(
            '[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]',
            'i'
        );
        const validate = regExp.test(this.OrganizationPostal);
        if (validate === false) {
            this.OrganizationPostal = '';
        } else {
            this.OrganizationPostal = this.OrganizationPostal.toUpperCase();
        }
    }
    /**
   * To auto complete the api
   *
   * @param {*} $event
   * @memberof RegisterComponent
   */
    searchAddr($event, Id = '') {
        this.webappService
            .getCanadaPostalAddress($event.query, Id)
            .pipe(takeUntil(this.ngSubject))
            .subscribe((data: { Items: [] }) => {
                this.OrganizationCity = '';
                this.OrganizationPostal = '';
                this.addressSuggestion = data.Items;
                if (Id) {
                    this.autoComplete.overlayVisible = true;
                    timer(400).pipe(take(1)).subscribe((w) => {
                        this.autoComplete._suggestions = data.Items;
                    });
                }
            });
    }

    onSelectedAddress($event) {
        //  this.OrganizationAddress = $event.query;
        if ($event.Next === 'Retrieve') {
            this.webappService
                .selectedCanadaPostalAddress($event.Id)
                .pipe(takeUntil(this.ngSubject))
                .subscribe((data: any) => {
                    this.OrganizationCity = data.Items[0].City;
                    this.OrganizationPostal = data.Items[0].PostalCode;
                });
        } else {
            this.searchAddr({ query: $event.Text }, $event.Id);
        }
    }

    ngOnDestroy() {
        this.ngSubject.next();
        this.ngSubject.complete();
    }
}
