import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { filter, take } from 'rxjs/operators';
import { WebAppService } from 'src/app/web-app.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-non-health-faq',
  templateUrl: './non-health-faq.component.html',
  styleUrls: ['./non-health-faq.component.css']
})

export class NonHealthFAQComponent implements OnInit {
  healthDoc: string;
  healthSupplyDoc: string;
  nonHealthEnDoc: string;
  nonHealthFrDoc: string;
  nonHealthSupplyEnDoc: string;
  nonHealthSupplyFrDoc: string;
  nonHealthRegDocFr: string;
  nonHealthRegDocEn: string;

  constructor(private translocoService: TranslocoService, private webAppService: WebAppService) {
    if (environment.environments.local.baseUrl === window.location.origin) {
      this.healthDoc = environment.environments.local.documentLink.help.health;
      this.healthSupplyDoc = environment.environments.local.documentLink.help.healthSupply;
      this.nonHealthEnDoc = environment.environments.local.documentLink.help.nonHealthEn;
      this.nonHealthFrDoc = environment.environments.local.documentLink.help.nonHealthFr;
      this.nonHealthSupplyEnDoc = environment.environments.local.documentLink.help.nonHealthSupplyEn;
      this.nonHealthSupplyFrDoc = environment.environments.local.documentLink.help.nonHealthSupplyFr;
      this.nonHealthRegDocFr = environment.environments.local.documentLink.help.nonHealthRegDocFr;
      this.nonHealthRegDocEn = environment.environments.local.documentLink.help.nonHealthRegDocEn;
    }
    if (environment.environments.dev.baseUrl === window.location.origin) {
      this.healthDoc = environment.environments.dev.documentLink.help.health;
      this.healthSupplyDoc = environment.environments.dev.documentLink.help.healthSupply;
      this.nonHealthEnDoc = environment.environments.dev.documentLink.help.nonHealthEn;
      this.nonHealthFrDoc = environment.environments.dev.documentLink.help.nonHealthFr;
      this.nonHealthSupplyEnDoc = environment.environments.dev.documentLink.help.nonHealthSupplyEn;
      this.nonHealthSupplyFrDoc = environment.environments.dev.documentLink.help.nonHealthSupplyFr;
      this.nonHealthRegDocFr = environment.environments.dev.documentLink.help.nonHealthRegDocFr;
      this.nonHealthRegDocEn = environment.environments.dev.documentLink.help.nonHealthRegDocEn;
    }

    if (environment.environments.qa.baseUrl === window.location.origin) {
      this.healthDoc = environment.environments.qa.documentLink.help.health;
      this.healthSupplyDoc = environment.environments.qa.documentLink.help.healthSupply;
      this.nonHealthEnDoc = environment.environments.qa.documentLink.help.nonHealthEn;
      this.nonHealthSupplyEnDoc = environment.environments.qa.documentLink.help.nonHealthSupplyEn;
      this.nonHealthFrDoc = environment.environments.qa.documentLink.help.nonHealthFr;
      this.nonHealthSupplyFrDoc = environment.environments.qa.documentLink.help.nonHealthSupplyFr;
      this.nonHealthRegDocFr = environment.environments.qa.documentLink.help.nonHealthRegDocFr;
      this.nonHealthRegDocEn = environment.environments.qa.documentLink.help.nonHealthRegDocEn;
    }
    if (environment.environments.prod.baseUrl === window.location.origin) {
      this.healthDoc = environment.environments.prod.documentLink.help.health;
      this.healthSupplyDoc = environment.environments.prod.documentLink.help.healthSupply;
      this.nonHealthEnDoc = environment.environments.prod.documentLink.help.nonHealthEn;
      this.nonHealthFrDoc = environment.environments.prod.documentLink.help.nonHealthFr;
      this.nonHealthSupplyEnDoc = environment.environments.prod.documentLink.help.nonHealthSupplyEn;
      this.nonHealthSupplyFrDoc = environment.environments.prod.documentLink.help.nonHealthSupplyFr;
      this.nonHealthRegDocFr = environment.environments.prod.documentLink.help.nonHealthRegDocFr;
      this.nonHealthRegDocEn = environment.environments.prod.documentLink.help.nonHealthRegDocEn;
    }

    if (environment.environments.stage.baseUrl === window.location.origin) {
      this.healthDoc = environment.environments.stage.documentLink.help.health;
      this.healthSupplyDoc = environment.environments.stage.documentLink.help.healthSupply;
      this.nonHealthEnDoc = environment.environments.stage.documentLink.help.nonHealthEn;
      this.nonHealthFrDoc = environment.environments.stage.documentLink.help.nonHealthFr;
      this.nonHealthSupplyEnDoc = environment.environments.stage.documentLink.help.nonHealthSupplyEn;
      this.nonHealthSupplyFrDoc = environment.environments.stage.documentLink.help.nonHealthSupplyFr;
      this.nonHealthRegDocFr = environment.environments.stage.documentLink.help.nonHealthRegDocFr;
      this.nonHealthRegDocEn = environment.environments.stage.documentLink.help.nonHealthRegDocEn;
    }
    if (environment.environments.live.baseUrl === window.location.origin) {
      this.healthDoc = environment.environments.live.documentLink.help.health;
      this.healthSupplyDoc = environment.environments.live.documentLink.help.healthSupply;
      this.nonHealthEnDoc = environment.environments.live.documentLink.help.nonHealthEn;
      this.nonHealthFrDoc = environment.environments.live.documentLink.help.nonHealthFr;
      this.nonHealthSupplyEnDoc = environment.environments.live.documentLink.help.nonHealthSupplyEn;
      this.nonHealthSupplyFrDoc = environment.environments.live.documentLink.help.nonHealthSupplyFr;
      this.nonHealthRegDocFr = environment.environments.live.documentLink.help.nonHealthRegDocFr;
      this.nonHealthRegDocEn = environment.environments.live.documentLink.help.nonHealthRegDocEn;
    }
  }
  trainingSessionValues: any[];
  trainingNHSessionValuesOld: any[];
  trainingNHSessionValuesNew: any[];
  cols: any[];
  environment;
  ngOnInit(): void {
    this.environment = environment;
    this.columnHeaders();
    this.translocoService.events$.pipe(filter((e: any) => e.type === 'translationLoadSuccess')).subscribe(() => {
      this.columnHeaders();
    });
    this.translocoService.langChanges$.subscribe(() => {
      this.columnHeaders();
    });
    if (localStorage.getItem('Lang') === 'en') {
      this.webAppService.getSessionTime().pipe(take(1)).subscribe((data) => {
        this.trainingSessionValues = data.healthSessions;
        this.trainingNHSessionValuesNew = data.nonHealthNew;
        this.trainingNHSessionValuesOld = data.nonHealthOld;
      });
    } else if (localStorage.getItem('Lang') === 'fr') {
      this.webAppService.getSessionTimeFrench().pipe(take(1)).subscribe((data) => {
        this.trainingSessionValues = data.frenchHealthSessions;
        this.trainingNHSessionValuesNew = data.frenchNonHealthNew;
        this.trainingNHSessionValuesOld = data.frenchNonHealthOld;
      });
    } else {
      this.webAppService.getSessionTime().pipe(take(1)).subscribe((data) => {
        this.trainingSessionValues = data.healthSessions;
        this.trainingNHSessionValuesNew = data.nonHealthNew;
        this.trainingNHSessionValuesOld = data.nonHealthOld;
      });
    }
  }
  columnHeaders() {
    this.cols = [
      {
        field: 'sessionDate',
        header: this.translocoService.translate('FAQ.Date'),
        width: '20%'
      },
      {
        field: 'sessionTime',
        header: this.translocoService.translate('FAQ.Time'),
        width: '20%'
      },
      {
        field: 'sessionLink',
        header: this.translocoService.translate('FAQ.LinktoJoin'),
        width: '20%'
      }
    ];
  }
}
