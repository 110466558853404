<div class="container-fluid entity-block">
    <div class="col-md-10 offset-md-1 org-grid" id="loginAsOrgTYpe" >
        <!-- <img src="./assets/images/logo.png" alt="Logo" /> -->
        <h1 class="application-name a-mb-10" style="font-size: 24px !important;font-weight: bold;text-align: center;" tabindex="0">
            {{ 'EntityScreen.EntityHeader' | transloco }}
        </h1>
        <div style="margin-bottom:30px; font-size: 18px; font-weight: 500; line-height: 1.58;text-align: center;" tabindex="0">
            {{ 'EntityScreen.EntitySubHeader' | transloco }}
        </div>
        <div style="margin-bottom:30px; font-size: 20px; font-weight: 500; line-height: 1.58;text-align: left;" tabindex="0">
            <div class="org-dropdown" id="dropdownField">
              <p-table #OrgTable2 [columns]="entitycols" [value]="orgTypeEntityValues" 
                       [(selection)]="selectedOrganizationType" [scrollable]="true" scrollHeight="calc(100vh - 450px)"
                       loading="true" [showLoader]="loader">

                <ng-template pTemplate="emptymessage">
                  <div class="mt-3 emptymessage">
                    {{ 'EntityScreen.NoEntities' | transloco }}
                  </div>
                </ng-template>
                <ng-template pTemplate="caption">
                  <div class="search-box a-mb-10 a-mt-20 a-mr-10 text-right">
                    <i class="pi pi-search"></i>
                    <input type="text" tabindex="0" #globalSearch pInputText size="20" placeholder=" {{ 'EntityScreen.searchEntity' | transloco }}" (keyup)="onKeyup($event)" (input)="OrgTable2.filterGlobal($event.target.value, 'contains')" style="width:auto" title="Please type to filter entities">
                      <i class="pi pi-times closeIcon" (click)="clearGlobalFilter($event.target.value)"
                       *ngIf="xVisible" title=" {{ 'EntityScreen.searchClear' | transloco }}" tabindex=0></i>
                  </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width: 3.25em; height:30px">

                    </th>
                    <th *ngFor="let col of columns" style="height:30px" [style.width]="col.width"
                        [pSortableColumn]="col.sortField">
                      <span tabindex="0">{{col.header}}</span>
                      <p-sortIcon *ngIf="col.header != ''" [field]="col.sortField"
                                  ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                      </p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                    <td style="width:3.25em" tabindex="0">
                      <p-tableRadioButton [value]="rowData" label="Please select if this is your entity"></p-tableRadioButton>
                    </td>
                    <td *ngFor="let col of columns" [style.width]="col.contentWidth" tabindex="0">
                      {{rowData[col.field]}}
                    </td>
                  </tr>
                </ng-template>

              </p-table>
            </div>
        </div>
        <div class="text-center a-textarea-footer forgot-password-footer a-mt-20">
            <div class="a-textarea-footer " >
                <button tabindex="0" class="a-btn a-btn-primary a-btn-xl login-btn moh-btn" (click)="Continue()" tabindex="0"
                    style="width:20% !important;background-color:#386152 !important; position:relative; font-weight: bold;">
                    {{ 'EntityScreen.Continue' | transloco }}
                    <i class="fa fa-long-arrow-right" style="position: absolute;right: 15px;"></i>
                </button>
            </div>
        </div>
        <div class="text-center a-mt-30 a-mb-20 new-link">            
           <span tabindex="0"> {{ 'EntityScreen.NoEntityText' | transloco }}</span>
            <b>
                <span tabindex="0">{{ 'EntityScreen.NewEntityText' | transloco }}</span>
                <span tabindex="0"><a (click)="newEntity()">                    
                    {{ 'EntityScreen.EntityClick' | transloco }}
                </a></span>.
            </b>
        </div>
    </div>