<!-- <div class="infoMessage">
  <span class="pr-2"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
  <b>{{ 'HOME.holidayHeader' | transloco }} </b>
  <span>{{ 'HOME.holidayMessage' | transloco }}</span>
</div> -->

<div class="login-wrapper" *ngIf="downTime">
  <div class="login-wrapper-in">
    <div class="login" id="login">
      <div class="login-logo">
        <img src="./assets/images/logo.png" style="width: 54%;" alt="Government of Ontario" />
      </div>
      <h1 style="font-size: 15px !important;font-weight: bold;"><div class="application-name a-mb-10" style="font-size: 15px !important;font-weight: bold;">
        <div>{{ 'EntityScreen.covid19Header1' | transloco }}</div>
      </div>
      </h1>
      <div class="process-name a-mb-20"><b>{{ 'HOME.plsSignIn' | transloco }}</b></div>
      <div class="cache">
        {{ 'HOME.cache' | transloco }}
      </div>
      <div class="user-fields">
        <div class="a-d-flex a-flex-column a-textarea-box">
        <div class="a-form-label" [ngClass]="{'error-label': userError || entercredentials || validEmail}">
            {{ 'HOME.EmailAddress' | transloco }}</div>
       
          
          <!---->
          <div class="ImageClass"><i class="fa fa-envelope iconClass"></i>
            <input type="email" class="a-text-input" name="username" [(ngModel)]="username" (keyup.enter)="login()"
              [ngClass]="{'error-box': userError || entercredentials || validEmail}" autofocus title="Please enter your email address"/></div>
          <div class="row">
            <div class="a-form-label col-sm-7"
              [ngClass]="{'error-label': passwordError || entercredentials || validEmail || passwordLengthError}">
              {{ 'HOME.password' | transloco }}</div>
            <div class="a-form-label col-sm-5 pwd-show text-right">
              <a (mousedown)='showHidePassword(true)' (mouseup)='showHidePassword(false)' title="Click to see the password">
                <i style="padding:1px;" class="fa fa-eye"></i>{{ 'HOME.Show' | transloco }}
              </a>
            </div>
          </div>

          <div class="ImageClass">

            <i class="fa fa-lock iconClass"></i>
            <!-- <input *ngIf="!showPassword" class="a-text-input" name="password" type="password" (keyup.enter)="login()" [(ngModel)]="password" [ngClass]="{'error-box': passwordError}" /> -->
            <input class="a-text-input" name="password" [type]="showPasswordContent ? 'text':'password'"
              [(ngModel)]="password" (keyup.enter)="login()"
              [ngClass]="{'error-box': passwordError || entercredentials || validEmail || passwordLengthError}" title="Please enter your password"/>
          </div>
          <div class="error-message" *ngIf="entercredentials">{{ 'HOME.validEmail' | transloco }}</div>
          <div class="error-message" *ngIf="userError && !entercredentials">{{ 'HOME.EmptyEmailAddress' | transloco }}
          </div>
          <div class="error-message" *ngIf="passwordError && !entercredentials">{{ 'HOME.EmptyPassword' | transloco }}
          </div>

          <div class="error-message" *ngIf="passwordLengthError && !passwordError && !userError">
            {{ 'HOME.ValidPswd' | transloco }}</div>

          <div class="error-message" *ngIf="validEmail">{{ 'HOME.validEmail' | transloco }}</div>
          <div class="error-message" *ngIf="userDisabledMsg">{{ 'HOME.userDisabledMsg' | transloco }}</div>
          <div class="error-message" *ngIf="userRejectedMsg">{{ 'HOME.userRejectedMsg' | transloco }}</div>
          <div class="error-message" *ngIf="userOnHoldMsg">{{ 'HOME.userOnHoldMsg' | transloco }}</div>
          
          
          <!--<div class="error-message hidden" id="error-password">Please enter a password.</div>
  <div class="error-message hidden" id="error-guid-password">Please provide a user name and password.</div>-->


          <div class="a-textarea-footer">
            <button id="signInButton" class="a-btn a-btn-primary a-btn-xl login-btn" tabindex="0"
              (click)="login()" title="Select this button to login">{{ 'HOME.SignIn' | transloco }}</button>
          </div>

          <div class="login-operation a-mt-10 text-center">
            <div class="forgot-pwd-link">
              <a class="a-link" (click)="showForgotPassword()" (keyup.enter)="showForgotPassword()" tabindex="0">
                {{ 'HOME.forgetPass' | transloco }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="title">
      <h4>
        {{ 'HOME.NewTool' | transloco }}
      </h4>
    </div>
    <div class="col-md-6 a-mb-30 offset-md-3 a-mt-20">
      <div class="row">
        <div class="col-md-6">
          <div class="log-card cursorPointer" (click)="showResetPassword()">
            <h4>
              {{ 'HOME.iHaveForPrev' | transloco }} <u> {{ 'HOME.previously' | transloco }} </u>
              {{ 'HOME.FilledSurvey' | transloco }}
            </h4>
            <a class="a-link" (keyup.enter)="showResetPassword()" tabindex="0">
              {{ 'HOME.setPassword' | transloco }}
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="log-card cursorPointer" (click)="showRegister()">
            <h4>
              {{ 'HOME.iHaveNever' | transloco }} <u> {{ 'HOME.never' | transloco }}</u>
              {{ 'HOME.FilledSurvey' | transloco }}
            </h4>
            <a class="a-link" (keyup.enter)="showRegister()" tabindex="0">
              {{ 'HOME.clickRegister' | transloco }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="login-wrapper" *ngIf="!downTime">
  <div class="login-wrapper-in">
    <div class="login" id="login" style="width:40vw">
      <div class="login-logo">
        <img src="./assets/images/logo.png" style="width: 54%;" alt="Logo" />
      </div>
      <div class="application-name a-mb-10" style="font-size: 15px !important;font-weight: bold;">
        <div>{{ 'EntityScreen.covid19Header1' | transloco }}</div>
      </div>
      <div class="center-align">
        <div>
          <span><i class="fa fa-clock-o fa-3x" aria-hidden="true"></i></span>
          <div class="downtimeHeader">
            {{ 'HOME.CSEOpen' | transloco }}
          </div>
          <div class="subtitle pt-3">
            {{ 'HOME.accessHours' | transloco }}
          </div>
          <div class="subtitle">
            {{ 'HOME.appreciateSupport' | transloco }}
          </div>
        </div>
      </div>
    </div>
  </div>