import { HttpClient } from '@angular/common/http';
import {
    TRANSLOCO_LOADER,
    Translation,
    TranslocoLoader,
    TRANSLOCO_CONFIG,
    translocoConfig,
    TranslocoModule
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    baseUrl: any;
    constructor(private http: HttpClient) {
        if (environment.environments.local.baseUrl === window.location.origin) {
            this.baseUrl = environment.environments.local.baseUrl;
        }
        if (environment.environments.dev.baseUrl === window.location.origin) {
            this.baseUrl = environment.environments.dev.baseUrl;
        }

        if (environment.environments.qa.baseUrl === window.location.origin) {
            this.baseUrl = environment.environments.qa.baseUrl;
        }
        if (environment.environments.stage.baseUrl === window.location.origin) {
            this.baseUrl = environment.environments.stage.baseUrl;
        }

        if (environment.environments.prod.baseUrl === window.location.origin) {
            this.baseUrl = environment.environments.prod.baseUrl;
        }
        if (environment.environments.live.baseUrl === window.location.origin) {
            this.baseUrl = environment.environments.live.baseUrl;
        }
    }

    getTranslation(lang: string) {
        return this.http.get<Translation>(`${this.baseUrl}/assets/i18n/${lang}.json?` + new Date().getTime());
    }
}

@NgModule({
    exports: [ TranslocoModule ],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: [ 'en', 'es', 'fr' ],
                defaultLang: 'en',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: environment.production
            })
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
    ]
})
export class TranslocoRootModule {}
