<div class="login-wrapper entity-selected">
    <div class="login-wrapper-in">
        <div class="login org-grid" id="loginAsOrgTYpe" style="width: 80vw;padding-top: 0;">
            <div class="login-logo">
                <img src="./assets/images/logo.png" alt="Logo" />
            </div>
            <div class="application-name a-mb-10" style="font-size: 15px !important;font-weight: bold;">

                <div>{{ 'EntityScreen.covid19Header1' | transloco }}</div>
            </div>
            <div style="font-size: 20px; font-weight: bold;" class="mt-4">
                {{ 'EntityScreen.SelectedEntityHeader' | transloco }} </div>
            <div class="py-2" style="color: #999999; ">
                <span> {{'EntityScreen.SelectedEntitySubHeader'|transloco}} </span>
                <span><b><u>{{'EntityScreen.One'|transloco}}</u></b></span>
                <span> {{'EntityScreen.SelectedEntitySubHeader1'|transloco}}</span>
            </div>
            <p-table #OrgTable [columns]="cols" [value]="oranizationTypes1" [(selection)]="selectOrganizationType"
                     [scrollable]="true" scrollHeight="calc(100vh - 450px)">
              <ng-template pTemplate="emptymessage">
                <div class="mt-3 emptymessage">
                  {{ 'EntityScreen.NoEntities' | transloco }}
                </div>
              </ng-template>
              <ng-template pTemplate="caption" styleClass="pull-left">
                <div class="search-box a-mb-10 a-mt-20 a-mr-10 text-right">
                  <i class="pi pi-search"></i>


                  <input type="text" #globalSearch (keyup)="onKeyup($event)" pInputText size="20" placeholder=" {{ 'EntityScreen.searchEntity' | transloco }}" (input)="OrgTable.filterGlobal($event.target.value, 'contains')" style="width:auto">

                  <i class="pi pi-times closeIcon" (click)="clearGlobalFilter($event.target.value)"
                     *ngIf="xVisible" title=" {{ 'EntityScreen.searchClear' | transloco }}" tabindex=0></i>
                </div>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th style="width: 2.25em; height:30px">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    <!--<p-tableRadioButton></p-tableRadioButton>-->
                  </th>
                  <th *ngFor="let col of columns" style="height:30px" [pSortableColumn]="col.sortField">
                    {{col.header}}
                    <p-sortIcon *ngIf="col.header != ''" [field]="col.sortField" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order">
                    </p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-oranizationTypes1 let-columns="columns">
                <tr>
                  <td style="width: 2.25em; height:30px">
                    <p-tableCheckbox [value]="oranizationTypes1"></p-tableCheckbox>

                  </td>
                  <td *ngFor="let col of columns">
                    {{oranizationTypes1[col.field]}}
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <div *ngIf="oranizationTypes1.length==0" style="padding-top:40px;">
                {{ 'EntityScreen.NoOrgData' | transloco }}
            </div>
            <div class="py-2" style="color: #999999; ">
                {{'EntityScreen.SelectedEntityFooterHeader'|transloco}}
            </div>
            <div class="text-center a-textarea-footer forgot-password-footer">
                <div class="a-textarea-footer">
                    <button id="Nextbtn" class="a-btn a-btn-primary a-btn-xl login-btn  moh-btn" (click)="Next()"
                        tabindex="0"
                        style="width:20% !important;background-color:#7B9C90 !important; position:relative; font-weight: bold;">
                        {{ 'EntityScreen.NextBtn' | transloco }}
                        <i class="fa fa-long-arrow-right" style="position: absolute;right: 15px;"></i>
                    </button>
                </div>
            </div>
            <!-- <div class="ent-footer">
                {{ 'EntityScreen.OrgFooter' | transloco }}

                <div>{{'EntityScreen.ContactSupport' | transloco }}<b>M - F, 8:30 - 4:30 EST.</b></div>
                <ul class="list-unstyled list-inline">
                    <li>
                        <i class="fa fa-phone"></i> 1 (800) 888-8888
                    </li>
                    <li>
                        <i class="fa fa-envelope"></i> example@ontario.ca
                    </li>
                </ul>
            </div> -->
        </div>
    </div>
</div>

<p-confirmDialog header="Confirmation" icon="" styleClass="dialog-popup" [closable]="false"></p-confirmDialog>
