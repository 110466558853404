<div>
    <nav class="navbar navbar-expand-lg navbar-light bg-transparent main-header">
        <a class="navbar-brand">
            <img src="../assets/images/logo-sm.png" alt="Government of Ontario" class="img-fluid" />
            <div class="headerText">
                <!-- {{ 'EntityScreen.covid19HomeHeader' | transloco }} -->
                {{ 'EntityScreen.covid19HomeNonHealthHeader' | transloco }}
            </div>
        </a>

        <button class="navbar-toggler" type="button" tabindex="0" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent"  >
            <ul class="navbar-nav ml-auto navbar-right right-menu">
                <li class="nav-item org-name" [attr.tabIndex]="selectedorgName=='' ? -1 :0">
                    <a class="nav-link">{{selectedorgName}}</a>
                </li>
                <li class="nav-item dropdown text-center"  tabindex="0" >
                    <a class="nav-link" (click)="menutoggle(); visibleSidebar =true" id="navbarDropdown" role="button" tabindex="0"
                        (keyup.enter)="visibleSidebar =true" style="padding-bottom:0px !important">
                        <i class="fa fa-bars a-font-24"></i>
                    </a>
                    <span class="Menustyle">
                      {{ 'HOME.Menu' | transloco }}
                    </span>
                </li>
                <li class="nav-item d-flex align-items-center"  tabindex="0" aria-label="logout button">
                    <a (click)="logout()" (keyup.enter)="logout()"
                        style="padding-top: 5px; cursor: pointer; display:block; margin-left:15px" >
                        <i class="fa fa-power-off" style="font-size: 20px;"></i>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
    <div onblur="menutoggle1()" onfocus="menutoggle()"> 
    <p-sidebar [(visible)]="visibleSidebar" [baseZIndex]="10000" position="right" styleClass="sidebar-menu" tabindex="0" >
      <div onblur="menutoggle1()" onfocus="menutoggle()" > 
      <div class="py-2 menuHeader userName" tabindex="-1">
        <div class="moh-username">
          {{userName}}
        </div>
        <div *ngIf="showChangeOrg" class="moh-orgname">
          {{selectedorgName}}
        </div>
      </div>
      <div *ngIf="sectorType === '1'" tabindex="-1" class="py-2 menuHeader" style="padding-top:2.5rem !important"
           [ngClass]="{'routerActive':currentPath=='home'}" (click)="naviagateTo('home')">
        {{ 'HOME.Home' | transloco }}
      </div>
      <div *ngIf="sectorType === '2'" tabindex="-1" class="py-2 menuHeader" style="padding-top:2.5rem !important"
           [ngClass]="{'routerActive':currentPath=='home'}" (click)="naviagateTo('non-health-home')">
        {{ 'HOME.Home' | transloco }}
      </div>
      <div *ngIf="showChangeOrg" tabindex="-1" class="py-2 menuHeader" [ngClass]="{'routerActive':currentPath=='inventory'}"
           (click)="naviagateTo('inventory')">
        {{ 'HOME.Inventory' | transloco }}
      </div>
      <div *ngIf="showChangeOrg" tabindex="-1" class="py-2 menuHeader" [ngClass]="{'routerActive':currentPath=='log'}"
           (click)="naviagateTo('log')">
        {{ 'HOME.SubmissionLog' | transloco }}
      </div>
      <div *ngIf="showChangeOrg" tabindex="-1" class="py-2 menuHeader" [ngClass]="{'routerActive':currentPath=='entity-screen'}"
           (click)="naviagateTo('entity-screen')">
        {{ 'HOME.ManageEntities' | transloco }}
      </div>
      <div *ngIf="sectorType === '1'" tabindex="-1" class="py-2 menuHeader" [ngClass]="{'routerActive':currentPath=='faq'}" (click)="naviagateTo('faq')">
        {{ 'HOME.HelpFAQ' | transloco }}
      </div>
      <div *ngIf="sectorType === '2'" tabindex="-1" class="py-2 menuHeader" [ngClass]="{'routerActive':currentPath=='non-health-faq'}" (click)="naviagateTo('non-health-faq')">
        {{ 'HOME.HelpFAQ' | transloco }}
      </div>
      <div class="py-2 menuHeader logout-menu" tabindex="-1" (click)="logout()">
        {{ 'HOME.LogoutText' | transloco }}
      </div>
    </div>
    </p-sidebar>
  </div>
</div>
<router-outlet></router-outlet>

<p-confirmDialog header="Confirmation" icon="" styleClass="dialog-popup" [closable]="false"></p-confirmDialog>