<div class="container-fluid footer moh-footer">
    <div class="row">
      <div class="col-md-8 col-sm-8">
        <div class="f-title row">
          <div class="col-md-3 footer-cell-maxwidth" [ngClass]="{'login-page-footertxt': !userName}">
            <b>{{ 'HOME.NeedSupport' | transloco }} </b>
          </div>
          <div class="col-md-9">
            {{ 'HOME.HelpDesk' | transloco }} ({{'HOME.FooterTime' | transloco }})
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 d-flex align-items-center justify-content-start footer-cell-maxwidth"  [ngClass]="{'login-page-footertxt': !userName}">
            <div *ngIf="userName">             
              <span [routerLink]="['/faq']" style="cursor:pointer;" *ngIf="sectorType === '1'">
                <i class="fa fa-question-circle"></i> {{ 'HOME.HelpFAQPage' | transloco }}
              </span>
              <span [routerLink]="['/non-health-faq']" style="cursor:pointer;" *ngIf="sectorType === '2'">
                <i class="fa fa-question-circle"></i> {{ 'HOME.HelpFAQPage' | transloco }}
              </span> 
            </div>            
          </div>
          <div class="col-md-9">
            <ul class="list-unstyled list-inline">

              <li>
                <i class="fa fa-phone"></i> {{ 'HOME.HelpDeskNumber' | transloco }}
              </li>
              <li>
                <i class="fa fa-envelope"></i> {{ 'HOME.HelpDeskEmail' | transloco }}
              </li>
            </ul>
          </div>

        </div>
      </div>
        <div class="col-md-4 col-sm-4 d-flex align-items-center justify-content-end">
            <app-login-footer></app-login-footer>
        </div>
    </div>
</div>
