import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { WebAppService } from 'src/app/web-app.service';
import { Router } from '@angular/router';
import { MessageComponent } from '../message/message.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Output, EventEmitter } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { SharedService } from '../sharedservice/sharedservice';
import { filter } from 'rxjs/operators';
import { Table } from 'primeng/table';
import { EncrDecrService } from '../sharedservice/enc-dec.service';

@Component({
  selector: 'app-entity-screen',
  templateUrl: './entity-screen.component.html',
  styleUrls: ['./entity-screen.component.css'],
})
export class EntityScreenComponent implements OnInit {
  @Output() public getOrgName = new EventEmitter<string>();

  constructor(
    private webappService: WebAppService,
    private router: Router,
    private dialogService: DialogService,
    private translocoService: TranslocoService,
    private sharedService: SharedService,
    private encrDecrService: EncrDecrService
  ) {}
  @ViewChild('globalSearch') userInput: ElementRef;
  @ViewChild('OrgTable2') pTableComponent: Table;
  orgTypeEntityValues = [];
  oranizationTypes1 = [];
  cards;
  cols = [];
  selectedOrganizationType;
  entitycols = [];
  loader = false;
  xVisible = false;
  ngOnInit(): void {
    this.getUserOrgDetails();
  }
  /**
   * Fetch the organisation details
   *
   * @memberof EntityScreenComponent
   */
  getUserOrgDetails() {
    this.orgTypeEntityValues = [];
    this.webappService.getUserOrgDetails().subscribe(async (resp) => {
      this.orgTypeEntityValues = [];
      let UserOrgDetails;
      this.loader = true;
      for (const el of resp.organizationList) {
        const lastDate = new DatePipe('en-US').transform(
          el.lastModified,
          'MM/dd/yyyy'
        );
        const lastDateTime = new DatePipe('en-US').transform(
          el.lastModified,
          'hh:mm:ss a'
        );
        let lastMod = lastDate + ' at ' + lastDateTime;
        let timeInMs = 0;
        if (el.lastModified.includes('0001')) {
          lastMod = '';
        } else {
          timeInMs = new Date(el.lastModified).getTime();
        }
        UserOrgDetails = {
          organizationId: el.organizationId,
          organizationName: el.organizationName,
          organizationAddress: el.organizationAddress,
          organizationType: el.organizationType,
          postalCode: el.postalCode,
          lastModified: lastMod,
          timeInMs: timeInMs,
          templateId: el.templateId,
          submittedBy: el.submittedBy,
        };

        this.orgTypeEntityValues.push(UserOrgDetails);
      }
      this.loader = false;
      this.columnHeaders();
      this.translocoService.events$
        .pipe(filter((e: any) => e.type === 'translationLoadSuccess'))
        .subscribe(() => {
          this.columnHeaders();
        });
      this.translocoService.langChanges$.subscribe(() => {
        this.columnHeaders();
      });
    });
  }
  columnHeaders() {
    this.entitycols = [
      {
        field: 'organizationName',
        header: this.translocoService.translate('EntityScreen.OrgName'),
        width: '12vw',
        contentWidth: '12vw',
        sortField: 'organizationName',
      },
      {
        field: 'organizationType',
        header: this.translocoService.translate(
          'EntityScreen.OrganizationType'
        ),
        width: '12vw',
        contentWidth: '12vw',
        sortField: 'organizationType',
      },
      {
        field: 'organizationAddress',
        header: this.translocoService.translate('EntityScreen.Address'),
        width: '12vw',
        contentWidth: '12vw',
        sortField: 'organizationAddress',
      },

      {
        field: 'postalCode',
        header: this.translocoService.translate('EntityScreen.postalCode'),
        width: '10vw',
        contentWidth: '10vw',
        sortField: 'postalCode',
      },
      {
        field: 'lastModified',
        header: this.translocoService.translate('EntityScreen.LastSubmitted'),
        width: '14vw',
        contentWidth: '14vw',
        sortField: 'timeInMs',
      },
      {
        field: 'submittedBy',
        header: this.translocoService.translate('EntityScreen.SubmittedBy'),
        width: '12vw',
        contentWidth: 'calc(12vw - 17px)',
        sortField: 'submittedBy',
      },
    ];
  }
  Continue() {
    if (
      this.selectedOrganizationType === undefined ||
      this.selectedOrganizationType === null ||
      this.selectedOrganizationType.length === 0
    ) {
      const popup = this.dialogService.open(MessageComponent, {
        showHeader: true,
        closable: true,
        width: '40%',
        data: {
          header: this.translocoService.translate('EntityScreen.popupHeader'),
          message: this.translocoService.translate('EntityScreen.popupInfo'),
        },
      });
    } else {
      this.getOrgName.emit(this.selectedOrganizationType.organizationName);
      // sessionStorage.setItem('OrgainisationDetails', JSON.stringify(this.selectedOrganizationType));
      this.webappService.updateOrganisationName(
        this.selectedOrganizationType.organizationName,
        true
      );
      sessionStorage.setItem(
        'organizationName',
        this.selectedOrganizationType.organizationName
      );
      sessionStorage.setItem(
        'organizationTemplate',
        this.encrDecrService.set(this.selectedOrganizationType.templateId)
      );
      this.GetOrgAccessToken(this.selectedOrganizationType.organizationId);
    }
  }
  /**
   * Get access token for the selected organisation
   *
   * @param {*} organizationId
   * @memberof EntityScreenComponent
   */
  GetOrgAccessToken(organizationId) {
    this.webappService.GetOrgAccessToken(organizationId).then(async (resp) => {
      if ( resp !== undefined)
      {
      if (resp.userStatus === 'Active' && resp.orgStatus === 'Active' && resp.accessStatus === 'Active' ) {
          sessionStorage.setItem('access_token', resp.orgToken);
          if (this.selectedOrganizationType) {
            this.router.navigate(['inventory']);
          }
      } else if (resp.accessStatus !== 'Active')
      {
        const popup = this.dialogService.open(MessageComponent, {
          showHeader: false,
          closable: false,
          width: '40%',
          data: {
            header: this.translocoService.translate('Grid.Warning'),
            message: this.translocoService.translate('HOME.accessDisableMsg'),
          },
        });
        popup.onClose.subscribe((data) => {
          this.getUserOrgDetails();
        });
      } else if (resp.userStatus !== 'Active')
      {
        const popup = this.dialogService.open(MessageComponent, {
          showHeader: false,
          closable: false,
          width: '40%',
          data: {
            header: this.translocoService.translate('Grid.Warning'),
            message: this.translocoService.translate('HOME.userDisabledMsg'),
          },
        });
        popup.onClose.subscribe((data) => {
          sessionStorage.clear();
          this.router.navigate(['Login']);
        });
      } else if (resp.orgStatus !== 'Active')
      {
        const popup = this.dialogService.open(MessageComponent, {
          showHeader: false,
          closable: false,
          width: '40%',
          data: {
            header: this.translocoService.translate('Grid.Warning'),
            message: this.translocoService.translate('HOME.orgDisableMsg'),
          },
        });
        popup.onClose.subscribe((data) => {
          this.getUserOrgDetails();
        });
      }
    }
  });
  }

  async GetTemplatesList() {
    await this.webappService.GetTemplatesList().then(async (resp) => {
      this.cards = resp.templateItems;
    });
  }

  newEntity() {
    this.router.navigate(['create-entity']);
  }

  // Global Search Functionalities Start
  onKeyup(event) {
    if (this.userInput.nativeElement.value != '') {
      this.xVisible = true;
    } else {
      this.xVisible = false;
    }
  }
  clearGlobalFilter(a) {
    this.pTableComponent.reset();
    this.userInput.nativeElement.value = '';
    this.xVisible = false;
  }
  // Global Search Functionalities End
}
