<div class="login org-grid log-grid" id="loginAsOrgTYpe" style="width: 98vw;">

    <h1 class="submissionLogHeader" tabindex="0">
        {{ 'HOME.SubmissionLog' | transloco }}
    </h1>
    <div style="margin-bottom:30px; font-size: 20px; font-weight: 500; line-height: 1.58;text-align: left;" tabindex="0">
        <div class="col-md-12  org-dropdown">
            <div class="d-none d-sm-block prime-grid">
                <p-table [scrollable]="true" scrollHeight="calc(100vh - 300px)" [columns]="entitycols" [value]="orgTypeEntityValues">
                    <ng-template pTemplate="emptymessage">
                        <div class="mt-3 emptymessage">
                            {{ 'Grid.NoSurvey' | transloco }}
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr tabindex="0">
                            <th *ngFor="let col of columns" [pSortableColumn]="col.sortField" style="height:30px" [style.width]="col.width" tabindex="0">
                                <span tabindex="0"> {{col.header}}</span>
                                <p-sortIcon [field]="col.sortField" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <td *ngFor="let col of columns;let i=index" [style.width]="col.contentWidth"
                              [attr.tabIndex]="i==5 ? -1 :0">
                                {{rowData[col.field]}}
                                <div *ngIf="col.field.toUpperCase() == 'DOWNLOADFILE'" class="download-inventory"
                                  tabindex="-1">
                                    <span tabindex="{{col}}" title="pdf" aria-label=" pdf" class="fa fa-file-pdf-o" (click)="DownloadInventoryPDF(rowData)"></span>
                                    <span tabindex="{{col}}" title="excel" aria-label="excel" class=" fa fa-file-excel-o" (click)="DownloadInventoryExcel(rowData)"></span>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>