import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { SharedService } from '../sharedservice/sharedservice';
import { BrowserModule, Title } from '@angular/platform-browser';
import { filter, skip } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';


@Component({
    selector: 'app-login-footer',
    templateUrl: './login-footer.component.html',
    styleUrls: [ './login-footer.component.css' ]
})
export class LoginFooterComponent implements OnInit {
    locales = [ { label: '🇺🇸 English (US)', value: 'en' }, { label: '🇫🇷 Français', value: 'fr' } ];
    locale = this.locales[0].value;
    langStatus = false;
    lang;
    webAppService: any;
    constructor(
        private translocoService: TranslocoService,
        private sharedService: SharedService,
        private titleService: Title,
        private confirmationService: ConfirmationService,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (localStorage.getItem('Lang') === 'fr') {
            this.langStatus = true;
            this.updateLocale('fr');
        } else {
            this.langStatus = false;
            this.updateLocale('en');
        }
        this.columnHeaders();
        this.translocoService.events$.pipe(filter((e: any) => e.type === 'translationLoadSuccess')).subscribe(() => {
            this.columnHeaders();
        });
        this.translocoService.langChanges$.subscribe(() => {
            this.columnHeaders();
        });
    }
    columnHeaders() {
        const bTitle = this.translocoService.translate('HOME.browserTitle');
        this.titleService.setTitle(bTitle);
    }
    updateLocale(locale) {
        if (this.locales.some((l) => l.value === locale)) {
            this.locale = locale;
        }
        const lang = locale.substring(0, 2);

        this.translocoService.setActiveLang(lang);
    }

    statusChange(event) {
        if (sessionStorage.getItem('pageUrl') === '/faq') {
            setTimeout(() => {
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.navigate(['faq']);
            }, 10);
        } else if (sessionStorage.getItem('pageUrl') === '/non-health-faq') {
            setTimeout(() => {
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.navigate(['non-health-faq']);
            }, 10);
        }
        event.preventDefault();
        this.sharedService.currentLanguage = this.langStatus;
        this.lang = this.langStatus === true ? 'Français' : 'English';
        localStorage.setItem('Lang', this.langStatus === true ? 'fr' : 'en');
        this.updateLocale(this.langStatus === true ? 'fr' : 'en');
        const bTitle = this.translocoService.translate('HOME.browserTitle');
        this.titleService.setTitle(bTitle);
        // if (window.location.pathname.toLowerCase().includes('inventory')) {
        //     this.confirmationService.confirm({
        //         header: this.translocoService.translate('Grid.Confirmation'),
        //         message:
        //             '<div class="change-msg"><h4>' +
        //             this.translocoService.translate('Grid.changeOrgDesc') +
        //             '</h4></div>',
        //         acceptLabel: this.translocoService.translate('Grid.YES'),
        //         rejectLabel: this.translocoService.translate('Grid.NO'),
        //         acceptIcon: '',
        //         rejectIcon: '',
        //         accept: () => {
        //             // this.langStatus = !this.langStatus;
        //             this.sharedService.currentLanguage = this.langStatus;
        //             this.lang = this.langStatus === true ? 'Français' : 'English';
        //             localStorage.setItem('Lang', this.langStatus === true ? 'fr' : 'en');
        //             this.updateLocale(this.langStatus === true ? 'fr' : 'en');
        //             const bTitle = this.translocoService.translate('HOME.browserTitle');
        //             this.titleService.setTitle(bTitle);
        //         },
        //         reject: () => {
        //             // this.langStatus = this.langStatus;
        //             this.cdr.detectChanges();

        //             if (localStorage.getItem('Lang') === 'fr') {
        //                 this.langStatus = true;
        //                 this.cdr.detectChanges();
        //             } else {
        //                 this.langStatus = false;
        //                 this.cdr.detectChanges();
        //             }
        //         }
        //     });
        // } else {
        //     //  this.langStatus = !this.langStatus;
        //     this.sharedService.currentLanguage = this.langStatus;
        //     this.lang = this.langStatus === true ? 'Français' : 'English';
        //     localStorage.setItem('Lang', this.langStatus === true ? 'fr' : 'en');
        //     this.updateLocale(this.langStatus === true ? 'fr' : 'en');
        //     const bTitle = this.translocoService.translate('HOME.browserTitle');
        //     this.titleService.setTitle(bTitle);
        // }
    }
}
