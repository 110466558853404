<div class="register-block">
    <div class="container-fluid">
        <div class="col-md-6 offset-md-3">
            <div class="login-logo text-center">
                <img src="./assets/images/logo.png" alt="Government of Ontario" />
            </div>
            <h1 class="application-name text-center">
                <div>{{ 'EntityScreen.covid19Header1' | transloco }}</div>
            </h1>
        </div>

        <div class="col-md-6 offset-md-3 text-left" *ngIf="!showRegistrationForm">
            <div class="process-name text-center">
                <strong>{{ 'REGISTER.enterEmail' | transloco }}</strong>
            </div>
            <div class="application-notes a-mb-20 a-mt-10 text-center">
                {{ 'REGISTER.previouslyRegisteredCaption' | transloco }}
            </div>
            <div>
                <div class="a-form-label" [ngClass]="{'error-label': EmailErr}">
                    <b>{{'REGISTER.email' | transloco }}</b>
                </div>
                <div class="row mail-block a-mb-20">
                    <div class="col-md-8">
                        <input class="a-text-input" id="" [disabled]="showRegistrationForm" [(ngModel)]="EmailId"
                            [ngClass]="{'error-box': EmailErr || showRedirection}" (keyup.enter)="checkEmail()" title="Please enter your email address">
                    </div>
                    <div style="margin-top: 50px;" class="col-md-8 error-message" *ngIf="EmailErr">{{ 'HOME.validEmail' | transloco }}</div>
                    <div class="col-md-4">
                        <button class="a-btn text-white a-btn-primary mail-btn" (click)="checkEmail()"
                            tabindex="0">{{ 'REGISTER.checkEmail' | transloco }}</button>
                    </div>
                </div>
                <div class="email-exists" style="font-size:14px" *ngIf="showRedirection">
                    <b>{{'REGISTER.emailExistsMsg' | transloco }}</b>
                    <p>{{'REGISTER.please' | transloco }} <span (click)="showForgotPassword()"
                            style="color: #000; font-weight: 500; text-decoration:underline; cursor: pointer;">{{'REGISTER.resetText' | transloco }}</span>
                        {{'REGISTER.gainAccess' | transloco }}</p>

                </div>
            </div>
        </div>

        <div class="col-md-6 offset-md-3 text-left" *ngIf="showRegistrationForm" style="font-size: 16px;"
            (keyup.enter)="validateForm()">
            <div class="text-center">
                <strong style="font-size: 20px;">{{ 'REGISTER.emailDoesnotExist' | transloco }}</strong>
            </div>
            <div class="text-center">
                <p style=" color:#000000">{{ 'REGISTER.pleaseFill' | transloco }}</p>
            </div>
            <div class="a-form-label" [ngClass]="{'error-label': EmailErr}">
                <b>{{'REGISTER.email' | transloco }}</b>
            </div>
            <div class="row mail-block a-mb-20">
                <div class="col-md-8">
                    <input class="a-text-input" disabled id="" [(ngModel)]="EmailId"
                        [ngClass]="{'error-box': EmailErr}" title="Your email address">
                </div>
                <div class="col-md-4 px-0 my-2">
                    <i class="fa fa-check fa-1x" style="color:green" aria-hidden="true"></i>
                    <span class="ml-2"> {{'REGISTER.Verified' | transloco }}</span>
                </div>
            </div>

            <div class="register-input-group a-mb-20 ">
                <div class="a-form-label" [ngClass]="{'error-label': FirstNameErr}">
                    <b>{{'REGISTER.firstName' | transloco }}</b>
                </div>
                <input tabindex="0" class="a-text-input " id="" appAllowPattern allow-pattern="^[a-zA-Z0-9]*$"
                    [(ngModel)]="FirstName" [ngClass]="{'error-box': FirstNameErr}" title="Please enter your first name">
            </div>
            <div class="register-input-group a-mb-20">
                <div class="a-form-label" [ngClass]="{'error-label': LastNameErr}">
                    <b>{{'REGISTER.lastName' | transloco }}</b>
                </div>
                <input tabindex="0" class="a-text-input" id="" appAllowPattern allow-pattern="^[a-zA-Z0-9]*$"
                    [(ngModel)]="LastName" [ngClass]="{'error-box': LastNameErr}" title="Please enter your last name">
            </div>
            <div class="register-input-group a-mb-20">
                <div class="a-form-label" [ngClass]="{'error-label': RespondentTitleErr}">
                    <b>{{'REGISTER.jobTitle' | transloco }}</b>
                </div>
                <input tabindex="0" class="a-text-input" id="" [(ngModel)]="RespondentTitle"
                    [ngClass]="{'error-box': RespondentTitleErr}" title="Please enter your job title">
            </div>
            <div class="register-input-group">
                <hr>
            </div>
            <div class="register-input-group a-mt-10">
                <div class="a-form-label" [ngClass]="{'error-label': sectorErr}">
                    <b>{{'REGISTER.sectorBest' | transloco }}</b>
                </div>
                <div class="row mb-3 a-mb-20">
                    <div class="col-sm-6" (click)="newchangeOrganization(1)" (keyup.enter)="newchangeOrganization(1)">
                        <p-card tabindex="0" class="healthCards" [ngClass]="{'active-bg': healthTabsOpen}">
                            <p-header style=" font-size:16px">
                                <div class="row m-0">
                                    <div class="col-1 p-0 pl-3 mt-0"> <i *ngIf="healthTabsOpen"
                                            class="fa fa-check-circle" style="color:green;padding-right:5px;"></i>
                                    </div>
                                    <div [ngClass]="{'col-11 pl-2':healthTabsOpen,'col-12 pl-3':!healthTabsOpen}">
                                        <b>{{'REGISTER.health' | transloco }}</b></div>
                                </div>

                            </p-header>
                            <p style="font-size:13px; color: #000000;">{{'REGISTER.forExample' | transloco }}</p>
                            <p>{{'REGISTER.healthExample' | transloco }}</p>
                        </p-card>
                    </div>
                    <div class="col-sm-6" (click)="newchangeOrganization(2)" (keyup.enter)="newchangeOrganization(2)">
                        <p-card tabindex="0" class="healthCards" [ngClass]="{'active-bg': NonHealthTabsOpen}">
                            <p-header style="font-size:16px">
                                <div class="row m-0">
                                    <div class="col-1 p-0 pl-3 mt-0"> <i *ngIf="NonHealthTabsOpen"
                                            class="fa fa-check-circle" style="color:green;padding-right:5px;"></i>
                                    </div>
                                    <div [ngClass]="{'col-11 pl-2':NonHealthTabsOpen,'col-12 pl-3':!NonHealthTabsOpen}">
                                        <b>{{'REGISTER.nonHealth' | transloco }}</b></div>
                                </div>

                            </p-header>
                            <p style="font-size:13px; color: #000000;">
                                {{'REGISTER.forExample' | transloco }}</p>
                            <p class="mb-3">{{'REGISTER.nonHealthExample' | transloco }}</p>
                        </p-card>
                    </div>
                </div>

                <div class="register-input-group image-control a-mb-20 a-mt-20">
                    <div class="a-form-label" [ngClass]="{'error-label': selectedOrganizationErr}">
                        <b>{{'REGISTER.organizationName' | transloco }}</b>
                        <p style="color:#000000" class="py-1">{{'REGISTER.selectAllOrganization' | transloco }}</p>
                    </div>

                    <!-- <p-multiSelect tabindex="5" class="" [options]="oranizationList" placeholder="Please Select"
                        appendTo="body" [(ngModel)]="selectedOrganization" name="selectedOrganization"
                        [showHeader]="true" [filter]="true" defaultLabel="Please Select" maxSelectedLabels="10"
                        [disabled]="organizationDoesnotExists"></p-multiSelect> -->
                    <div class="dropdownclass">
                        <p-dropdown id="dropdown-test" tabindex="0" [options]="oranizationList" [(ngModel)]="selectedOrganization"
                            placeholder="{{'REGISTER.pleaseSelect' | transloco }}" [filter]="true" [showClear]="true"
                            [disabled]="organizationDoesnotExists" [virtualScroll]="true" [itemSize]="31"
                            [ngClass]="{'error-box': selectedOrganizationErr}"></p-dropdown>
                    </div>
                </div>
                <div class="ui-inputgroup check-style a-mb-20">
                    <p-checkbox tabindex="0" [(ngModel)]="organizationDoesnotExists" [binary]="true"
                        class="register-chckbox" (click)="checkOrgExist($event)" (keyup.enter)="checkOrgExist($event)"
                        label="{{'REGISTER.checkBox' | transloco }}" title="Check this box if you don't see your organization name in the list above">
                    </p-checkbox>
                </div>
            </div>
            <!-- label="Check this box if you don�t see your organization name in the list above."> -->
            <div class="extra-controls" *ngIf="organizationDoesnotExists">
                <div class="register-input-group">
                    <p style="color:#000000">
                        {{'REGISTER.extraControlDesc' | transloco }}
                    </p>
                </div>
                <div class="register-input-group a-mb-20">
                    <div class="a-form-label" [ngClass]="{'error-label': OrgNameErr}">
                        <b>{{'REGISTER.organizationName' | transloco }}</b>
                    </div>
                    <input tabindex="0" class="a-text-input mr-2" id="" [(ngModel)]="OrgName"
                        [ngClass]="{'error-box': OrgNameErr}" title="Please enter your organization name">
                </div>

                <div class="register-input-group image-control dropdownclass a-mb-20">
                    <div class="a-form-label" [ngClass]="{'error-label': selectedorganizationTypeErr}">
                        <b>{{'REGISTER.orgType' | transloco }}</b>
                    </div>
                    <div class="dropdownclass">
                        <p-dropdown tabindex="0" [options]="oranizationTypeList" [(ngModel)]="selectedorganizationType"
                            placeholder="{{'REGISTER.pleaseSelect' | transloco }}" optionLabel="orgType"
                            [showClear]="true" [ngClass]="{'error-box': selectedorganizationTypeErr}" [filter]="true"></p-dropdown>

                    </div>
                </div>
                <div class="register-input-group image-control a-mb-20" *ngIf="!NonHealthTabsOpen">
                    <div class="a-form-label" [ngClass]="{'error-label': selectedLHINErr}">
                        <b>{{'REGISTER.lhin' | transloco }}</b>
                        <span class="pl-2">({{'REGISTER.lhinHelpText' | transloco }} <a style="color: #005494;" href="http://www.lhins.on.ca/"
                                target="blank">{{'REGISTER.lhinHelpText1' | transloco }}</a></span>)
                    </div>
                    <p-multiSelect tabindex="0" class="" [options]="LHINOptions"
                        [ngClass]="{'error-box': selectedLHINErr}" appendTo="body" [(ngModel)]="selectedLHIN"
                        name="selectedLHIN" defaultLabel="{{'REGISTER.pleaseSelect' | transloco }}"
                        maxSelectedLabels="10">
                    </p-multiSelect>
                </div>
                <div class="register-input-group" *ngIf="sectorId != 0">
                    <div class="a-form-label" [ngClass]="{'error-label': selectedOrgTemplateErr}">
                        <b>{{'REGISTER.entityType' | transloco }}</b>
                    </div>
                    <div class="row a-mb-20" *ngIf="sectorId == 1">
                        <div class="col-sm-6" (click)="selectEntity(1)">
                            <p-card tabindex="0" class="healthCards" [ngClass]="{'active-bg': orghealthTab}">
                                <p-header style=" font-size:16px">
                                    <div class="row m-0">
                                        <div class="col-1 p-0 pl-3 mt-0"> <i *ngIf="selectedEntity == 1"
                                                class="fa fa-check-circle" style="color:green;padding-right:5px;"></i>
                                        </div>
                                        <div
                                            [ngClass]="{'col-11 pl-2':selectedEntity == 1,'col-12 pl-3':selectedEntity != 1}">
                                            <b>{{'REGISTER.health' | transloco }}</b></div>
                                    </div>

                                </p-header>
                                <p style="font-size:13px; color: #000000; width: 250px;">
                                    {{'REGISTER.forExample' | transloco }}</p>
                                <p class="mb-3">{{'REGISTER.healthExample' | transloco }}</p>
                            </p-card>
                        </div>
                        <div class="col-sm-6" (click)="selectEntity(3)">
                            <p-card tabindex="0" class="healthCards" [ngClass]="{'active-bg': orghealthSupplyTab}">
                                <p-header style=" font-size:16px">
                                    <div class="row m-0">
                                        <div class="col-1 p-0 pl-3 mt-0"> <i *ngIf="selectedEntity == 3"
                                                class="fa fa-check-circle" style="color:green;padding-right:5px;"></i>
                                        </div>
                                        <div
                                            [ngClass]="{'col-11 pl-2':selectedEntity == 3,'col-12 pl-3':selectedEntity !== 3}">
                                            <b>{{'REGISTER.healthSupplies' | transloco }}</b></div>
                                    </div>
                                </p-header>
                                <!-- <p style="font-size:13px; color: #999999; width: 250px;">
                                    {{'REGISTER.forExample' | transloco }}</p>
                                <p class="mb-3">{{'REGISTER.healthExamplec' | transloco }}</p> -->
                            </p-card>
                        </div>
                    </div>

                    <div class="row a-mb-20" *ngIf="sectorId == 2">
                        <div class="col-sm-6" (click)="selectEntity(2)">
                            <p-card tabindex="0" class="healthCards" [ngClass]="{'active-bg': orghealthTab}">
                                <p-header style="font-size:16px">
                                    <div class="row m-0">
                                        <div class="col-1 p-0 pl-3 mt-0"> <i *ngIf="selectedEntity == 2"
                                                class="fa fa-check-circle" style="color:green;padding-right:5px;"></i>
                                        </div>
                                        <div
                                            [ngClass]="{'col-11 pl-2':selectedEntity == 2,'col-12 pl-3':selectedEntity !== 2}">
                                            <b>{{'REGISTER.nonHealth' | transloco }}</b></div>
                                    </div>


                                </p-header>
                                <p style="font-size:13px; color: #000000;">
                                    {{'REGISTER.forExample' | transloco }}</p>
                                <p class="mb-3">{{'REGISTER.nonHealthExample' | transloco }}</p>
                            </p-card>
                        </div>
                        <div class="col-sm-6" (click)="selectEntity(4)">
                            <p-card tabindex="0" class="healthCards" [ngClass]="{'active-bg': orghealthSupplyTab}">
                                <p-header style="font-size:16px">
                                    <div class="row m-0">
                                        <div class="col-1 p-0 pl-3 mt-0"> <i *ngIf="selectedEntity == 4"
                                                class="fa fa-check-circle" style="color:green;padding-right:5px;"></i>
                                        </div>
                                        <div
                                            [ngClass]="{'col-11 pl-2':selectedEntity == 4,'col-12 pl-3':selectedEntity !== 4}">
                                            <b>{{'REGISTER.nonHealthSupplies' | transloco }}</b></div>
                                    </div>
                                </p-header>

                            </p-card>
                        </div>
                    </div>
                </div>

                <div class="register-input-group a-mb-20">
                    <div class="a-form-label" [ngClass]="{'error-label': OrganizationAddressErr}">
                        <b>{{'REGISTER.orgAdr' | transloco }}</b>
                    </div>
                    <p-autoComplete #autoComplete [(ngModel)]="OrganizationAddress" [suggestions]="addressSuggestion"
                        styleClass="addressAutoComplete" (completeMethod)="searchAddr($event)"
                        (onSelect)="onSelectedAddress($event)" field="Text" [forceSelection]="true" title="Please enter your organization's address">
                        <ng-template let-brand pTemplate="item"> 
                            <div class="ui-helper-clearfix">
                                <div>{{brand.Text}}
                                    <span class="ml-2" style="color:gray">{{brand.Description}}</span>
                                </div>

                            </div>
                        </ng-template>
                    </p-autoComplete>
                </div>
                <div class="row m-0s disableInput">
                    <div class="register-input-group a-mb-20 col-6">
                        <div class="a-form-label" [ngClass]="{'error-label': OrganizationCityErr}">
                            <b>{{'REGISTER.orgCity' | transloco }}</b>
                        </div>
                        <input tabindex="0" class="a-text-input maskClass" id="" disabled [(ngModel)]="OrganizationCity"
                            [ngClass]="{'error-box': OrganizationCityErr}" title="Please enter the city your organization is in">
                    </div>
                    <div class="register-input-group a-mb-20 col-6">
                        <div class="a-form-label" [ngClass]="{'error-label': OrganizationPostalErr}">
                            <b>{{'REGISTER.orgPostal' | transloco }}</b>
                        </div>
                        <!-- <p-inputMask mask="99-9999" [(ngModel)]="OrganizationPostal" id="" class="maskClass"
                        styleClass="maskInput">
                    </p-inputMask> -->
                        <input tabindex="0" class="a-text-input maskClass" disabled [(ngModel)]="OrganizationPostal"
                            (blur)="validateRegExp('OrganizationPostal')" appAllowPattern
                            allow-pattern="^[a-zA-Z 0-9]*$" maxlength="7"
                            [ngClass]="{'error-box': OrganizationPostalErr}" title="Please enter your organization's postal code">
                    </div>
                </div>
                
                <div class="row m-0s">
                    <div class="register-input-group a-mb-20 col-12">
                        <div class="a-form-label" [ngClass]="{'error-label': IndigenousError}">
                            <b>{{'REGISTER.IndigenousQuestion' | transloco }}</b>
                            <span pTooltip="{{ 'Grid.indigenousTooltip' | transloco }}" tooltipPosition="right" tooltipZIndex="10000">
                                <i class="fa fa-info-circle a-ml-5" aria-hidden="true"></i>
                            </span>  
                        </div>
                        <div class="ui-g radio-list">
                            <span>
                                <p-radioButton tabindex="0" name="group1" value="Yes" label="{{ 'Grid.indigenousYes' | transloco }}" [(ngModel)]="indigenousValue" inputId="opt1">                                    
                                </p-radioButton>
                            </span>
                            <span>
                                <p-radioButton tabindex="0" name="group1" value="No" label="{{ 'Grid.indigenousNo' | transloco }}" [(ngModel)]="indigenousValue" inputId="opt2">                                    
                                </p-radioButton>
                            </span>
                            <span>
                                <p-radioButton tabindex="0" name="group1" value="Decline" label="{{ 'Grid.indigenousDecline' | transloco }}" [(ngModel)]="indigenousValue" inputId="opt3">                                    
                                </p-radioButton>
                            </span>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="a-textarea-footer a-mt-10">
                <p-confirmDialog [style]="{width: '30vw'}" styleClass="dialog-popup"></p-confirmDialog>
                <button class="a-btn a-btn-primary a-btn-xl login-btn" (click)="validateForm()"
                    tabindex="0">{{ 'HOME.Submit' | transloco }}</button>
            </div>
            <div class="forgot-pwd-link text-center a-mt-20">
                <a tabindex="0" (click)="BacktoLogin()"
                    (keyup.enter)="BacktoLogin()">{{ 'HOME.cancel' | transloco }}</a>
            </div>

        </div>
    </div>
    <div class="text-center a-mt-20">
        <!-- <app-login-footer></app-login-footer> -->
    </div>
</div>
