import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-instruction',
    templateUrl: './instruction.component.html',
    styleUrls: [ './instruction.component.css' ]
})
export class InstructionComponent implements OnInit {
    constructor(private ref: DynamicDialogRef) {}

    ngOnInit(): void {}
    continueClicked() {
        this.ref.close();
    }
}
